/* eslint-disable max-len */
import * as React from 'react';

const Payment = (props) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_903_37091)">
      <path
        d="M1.84067 16.3643C1.82901 16.7173 1.82275 17.0946 1.82275 17.5013C1.82275 20.1461 2.08722 21.5449 2.3831 23.1099C2.39062 23.1497 2.39816 23.1896 2.40573 23.2296C2.80087 25.3219 4.59549 27.0032 6.84828 27.3221C6.96298 27.3381 7.07709 27.3544 7.19083 27.3705C9.29514 27.6694 11.2641 27.9491 14.2073 27.9491C17.1506 27.9491 19.1196 27.6694 21.224 27.3705C21.3377 27.3544 21.4518 27.3381 21.5664 27.3221C23.8192 27.0032 25.6139 25.3219 26.009 23.2296C26.0165 23.1896 26.024 23.1497 26.0315 23.1099C26.3274 21.5449 26.5919 20.1461 26.5919 17.5013C26.5919 14.8565 26.3274 13.4577 26.0315 11.8927C26.024 11.8529 26.0165 11.8131 26.009 11.7731C25.6139 9.68068 23.8192 7.99935 21.5664 7.68066C21.5394 7.67685 21.5124 7.67304 21.4856 7.66922C21.4709 7.57046 21.4571 7.47229 21.4445 7.37462C23.7766 6.32252 23.1723 3.05912 20.9004 2.58641C19.0379 2.19889 14.7173 2.05078 12.1343 2.05078C9.68375 2.05078 8.04433 2.39327 6.29223 2.75929C6.19756 2.77907 6.10256 2.79891 6.00708 2.8188C4.13137 3.20906 2.63716 5.268 2.30814 7.8303C2.30184 7.87929 2.29556 7.92812 2.28931 7.97681C2.04295 9.89331 1.82275 11.6063 1.82275 14.8451C1.82275 15.3923 1.82904 15.8959 1.84067 16.3643Z"
        fill="#7B8795"
        stroke="#CBD2D9"
        strokeWidth={2.16624}
      />
      <path
        d="M9.40967 7.64834C10.8801 7.53805 13.3063 7.27399 15.831 7.27399C18.4446 7.27399 19.6814 7.40727 21.3962 7.64834"
        stroke="#CBD2D9"
        strokeWidth={2.16624}
        strokeLinecap="round"
      />
      <path
        d="M26.4463 20.8285H23.091C21.2787 20.8285 19.8096 19.3594 19.8096 17.5471C19.8096 15.7349 21.2787 14.2657 23.091 14.2657H26.4463C27.6298 14.2657 28.5892 15.2251 28.5892 16.4086V18.6856C28.5892 19.8691 27.6298 20.8285 26.4463 20.8285Z"
        fill="#7B8795"
        stroke="#CBD2D9"
        strokeWidth={2.16624}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_903_37091">
        <rect width={30} height={30} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Payment;
