import { get, map } from 'lodash';

import { PaymentrService } from '../redux/paymentTransfer/paymentTransfer.service';
import { convertTimestampIntoDate, numberWithCommas } from '../helpers/generalHelpers';

export const fetchPaymentTransferData = async (val) => {
  try {
    const response = await PaymentrService.fetchData(val);

    const paymentData = map(response.data.payments, (row) => ({
      id: get(row, 'payment.id', ''),
      sum: `${numberWithCommas(get(row, 'payment.profit', 0))}`,
      status: get(row, 'payment.status', ''),
      date: convertTimestampIntoDate(get(row, 'payment.created_at', '')),
      paymentDate:
        row?.payment?.completed_at === 0 ? '-' : convertTimestampIntoDate(get(row, 'payment.completed_at', '-')),
      firstname: get(row, 'user.firstname', ''),
      lastname: get(row, 'user.lastname', ''),
      username: get(row, 'user.firstname', '') + ' ' + get(row, 'user.lastname', ''),
      phone: get(row, 'user.phone', 0),
      userCreatedAt: convertTimestampIntoDate(get(row, 'user.created_at', '')),
    }));

    return {
      paymentData,
      paymentLimit: response.data.payment_threshold | 0,
      total_balance: get(response, 'data.total_balance', 0),
      total_payed: get(response, 'data.total_payed', 0),
    };
  } catch (err) {
    // console.log(err);
  }
};

export const paymentDeclined = async (paymentId) => {
  try {
    await PaymentrService.paymentDeclined(paymentId);
    return true;
  } catch (error) {
    return false;
  }
};

export const paymentSuccess = async (paymentId) => {
  try {
    await PaymentrService.paymentSuccess(paymentId);

    return true;
  } catch (error) {
    return false;
  }
};

export const paymentLimit = async (value) => {
  try {
    await PaymentrService.paymentLimit(value);

    return true;
  } catch (error) {
    return false;
  }
};

export const paymentsChargeReq = async (ids) => {
  try {
    const { data } = await PaymentrService.paymentsCharge(ids);
    return { data };
  } catch (error) {
    return { error: error.message };
  }
};
