import axios from '../axios';
import userServices from '../services/user.service';
import { TOKENS } from '../constants/general.constants';
import { isEmpty } from 'lodash';

export const loginAction = async ({ payload }) => {
  try {
    const response = await userServices.loginUser(payload);
    if (response.data.status === 1) {
      localStorage.setItem(TOKENS.REFRESH_TOKEN, response.data.tokens.refresh_token);
      localStorage.setItem(TOKENS.ACCESS_TOKEN, response.data.tokens.access_token);
      axios.defaults.headers[TOKENS.AUTORIZATION] = 'Bearer ' + response.data.tokens.access_token;
      return true;
    }
    return false;
  } catch (err) {
    // console.log(err);
    return false;
  }
};

export const fecthUserInfo = async () => {
  try {
    const response = await userServices.userInfo();
    const data = response.data;
    return data;
  } catch (err) {
    // console.log(err);
    return {};
  }
};

export const refreshToken = async (token) => {
  try {
    const { data } = await userServices.refreshToken({ token });
    if (!isEmpty(data)) {
      axios.defaults.headers[TOKENS.AUTORIZATION] = 'Bearer ' + data.access_token;
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const reportFromUser = async (from, to) => {
  try {
    const response = await userServices.reportFromUser(from, to);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const reportofPayment = async (from, to) => {
  try {
    const response = await userServices.reportofPayment(from, to);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const reportofselfPayment = async (from, to) => {
  try {
    const response = await userServices.reportofselfPayment(from, to);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const reportofUsesTxt = async (from, to) => {
  try {
    const response = await userServices.reportofUsesTxt(from, to);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const reportofLeads = async (from, to) => {
  try {
    const response = await userServices.reportofLeads(from, to);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    return false;
  }
};
