import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { filter, get, map } from 'lodash';

import { ROUTES } from '../../constants/routes.constants';
import Icon from '../icon';
import UserWithAvatarItem from '../userWithAvatarItem/UserWithAvatarItem';
import Column from './column/Column';
import { ICONS } from '../../constants/icons.constanst';
import useDashboardStore from '../../zustand/dashboard.store';
import Input from '../input/Input';

import styles from './fifthGraph.module.scss';

const FifthGraph = (props) => {
  const { getLeadData } = props;

  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = useMemo(
    () =>
      filter(
        get(dashboardData, 'popularLeads', []),
        (item) =>
          item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.subcategory.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [dashboardData, searchQuery],
  );

  const dashboard = useDashboardStore((state) => state?.dashboardData);

  const navigateLead = () => {
    navigate(ROUTES.LEAD_MANAGEMENT);
  };

  useEffect(() => {
    setDashboardData(dashboard);
  }, [dashboard]);

  return (
    <>
      <div className={styles.fifthGraph}>
        <div className={styles.rightLayer}>
          <p className={styles.populer}>לידים פופולרים</p>

          <div className={styles.userName}>
            {map(filteredData, (item, i) => {
              if (i < 3)
                return (
                  <UserWithAvatarItem
                    iconType={ICONS.USERLOGO}
                    userName={item?.company_name}
                    activeLeads={item?.subcategory}
                    itemBoxUpdate={styles.itemBoxUpdate}
                    userNameUpdate={styles.userNameUpdate}
                    styleLine2={styles.styleLine2}
                    imageSrc={item?.photo}
                    id={'basic-button'}
                    onClick={(e) => getLeadData(item.id)}
                  />
                );
            })}
          </div>
        </div>

        <div className={styles.leftLayer}>
          <div className={styles.searchContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }} onClick={navigateLead}>
              <p className={styles.leads}>ניהול לידים</p>
              <Icon type={ICONS.LEFTARROW} />
            </div>

            <Input
              className={styles.search}
              placeholder="חפש לידים"
              endIcon={
                <div>
                  <Icon type={ICONS.SEARCH} />
                </div>
              }
              value={searchQuery}
              labelColor="#ffffff"
              labelColorFocused="#f6f7fb"
              setValue={setSearchQuery}
            />
          </div>

          <div className={styles.column}>
            <Column
              title="שיתופים"
              item1={get(filteredData[0], 'shares_count', '')}
              item2={get(filteredData[1], 'shares_count', '')}
              item3={get(filteredData[2], 'shares_count', '')}
            />
            <Column
              title="לחיצות"
              item1={get(filteredData[0], 'clicks_count', '')}
              item2={get(filteredData[1], 'clicks_count', '')}
              item3={get(filteredData[2], 'clicks_count', '')}
            />

            <Column
              title="רשומים"
              item1={get(filteredData[0], 'register_count', '')}
              item2={get(filteredData[1], 'register_count', '')}
              item3={get(filteredData[2], 'register_count', '')}
            />
          </div>
        </div>
      </div>

      {/* <Menu
        style={{ marginLeft: 8 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ minWidth: 150 }}>
          <ListItem>
            <UserWithAvatarItem
              userName={get(selectedData, 'category', '')}
              activeLeads={get(selectedData, 'subcategory', '')}
            />
          </ListItem>
          <ListItem style={{ fontSize: 12 }}>רשומים: {get(selectedData, 'register_count', 0)}</ListItem>
          <ListItem style={{ fontSize: 12 }}>שיתופים: {get(selectedData, 'shares_count', 0)}</ListItem>
          <ListItem style={{ fontSize: 12 }}>לחיצות: {get(selectedData, 'clicks_count', 0)}</ListItem>
        </div>
      </Menu> */}
    </>
  );
};

export default FifthGraph;
