/* eslint-disable max-len */
import * as React from 'react';

const Man = (props) => (
  <svg width={195} height={345} viewBox="0 0 195 345" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity={0.9}>
      <path
        d="M95.4906 343.863V265.352L79.327 270.87V324.04L63.5927 338.84C61.2958 341 62.8247 344.857 65.9779 344.857H94.4959C95.0453 344.857 95.4906 344.412 95.4906 343.863Z"
        fill="#1F2933"
      />
      <path
        d="M110.412 343.862V265.352L126.576 270.869V324.04L142.31 338.84C144.607 341 143.078 344.857 139.925 344.857H111.407C110.858 344.857 110.412 344.411 110.412 343.862Z"
        fill="#1F2933"
      />
      <path
        d="M29.0386 87.6159C29.0386 69.0048 44.1259 53.9175 62.737 53.9175H143.613C162.224 53.9175 177.312 69.0048 177.312 87.6159V228.669C177.312 266.156 146.922 296.546 109.434 296.546H96.9159C59.4283 296.546 29.0386 266.156 29.0386 228.669V87.6159Z"
        fill="#1F2933"
      />
      <path
        d="M13.4795 6.4881C13.4795 43.7903 43.7189 74.0298 81.0212 74.0298L153.806 74.0298C153.806 36.7276 123.566 6.4881 86.2638 6.4881L13.4795 6.4881Z"
        fill="#E4E7EC"
      />
      <path
        d="M28.3477 224.85H177.908V288.856C177.908 300.928 168.121 310.715 156.049 310.715H50.2065C38.1342 310.715 28.3477 300.928 28.3477 288.856V224.85Z"
        fill="#7B8795"
      />
      <path
        d="M76.0947 165.03C81.3095 170.572 91.2055 174.313 102.555 174.313C113.905 174.313 123.801 170.572 129.016 165.03"
        stroke="black"
        strokeWidth={7.95746}
        strokeLinecap="round"
      />
      <path
        d="M30.2735 231.242C21.982 253.842 18.2202 274.842 19.5479 287.143"
        stroke="#7B8795"
        strokeWidth={10.3542}
        strokeLinecap="round"
      />
      <path
        d="M175.669 231.744C183.96 254.344 187.722 275.343 186.395 287.644"
        stroke="#7B8795"
        strokeWidth={10.3542}
        strokeLinecap="round"
      />
      <ellipse cx={77.8759} cy={128.543} rx={21.8588} ry={22.0464} fill="white" />
      <ellipse cx={78.1773} cy={129.086} rx={8.62847} ry={8.70254} fill="black" />
      <ellipse cx={131.948} cy={128.54} rx={21.8588} ry={22.0464} fill="white" />
      <ellipse cx={132.25} cy={129.086} rx={8.62847} ry={8.70254} fill="black" />
      <path
        d="M109.213 227.128H100.958C100.958 227.128 90.0834 253.221 90.3455 260.695C90.6075 269.349 105.151 285.215 105.151 285.215C105.151 285.215 119.695 269.349 119.957 260.695C120.088 253.353 109.213 227.128 109.213 227.128Z"
        fill="#52606D"
      />
      <path
        d="M97.1608 246.27C97.1608 246.27 99.6502 244.434 99.3882 242.861C99.2572 242.336 98.8641 241.943 98.209 241.943C97.5539 241.943 97.0298 242.467 97.0298 243.123C97.0298 242.467 96.5057 241.943 95.8506 241.943C95.5885 241.943 95.3265 242.074 95.0645 242.205C94.9334 242.467 94.8024 242.861 94.6714 243.123C94.9334 244.696 97.1608 246.27 97.1608 246.27Z"
        fill="black"
      />
      <path
        d="M103.699 234.206C103.568 233.681 103.175 233.288 102.52 233.288C101.865 233.288 101.341 233.813 101.341 234.468C101.341 233.813 100.817 233.288 100.161 233.288C99.6373 233.288 99.1132 233.681 98.9822 234.206C98.7202 235.779 101.21 237.615 101.21 237.615C101.21 237.615 103.961 235.91 103.699 234.206Z"
        fill="black"
      />
      <path
        d="M90.4782 259.382C90.4782 259.907 90.3471 260.3 90.4782 260.825C90.4782 261.087 90.4782 261.349 90.4782 261.611C90.7402 261.087 91.0022 260.694 90.8712 260.169C90.7402 259.776 90.6092 259.513 90.4782 259.382Z"
        fill="black"
      />
      <path
        d="M101.483 254.792C101.483 254.792 103.973 252.956 103.711 251.383C103.58 250.858 103.187 250.465 102.532 250.465C101.877 250.465 101.352 250.989 101.352 251.645C101.352 250.989 100.828 250.465 100.173 250.465C99.6491 250.465 99.125 250.858 98.994 251.383C98.863 253.087 101.483 254.792 101.483 254.792Z"
        fill="black"
      />
      <path
        d="M92.8356 254.792C92.8356 254.792 95.325 252.956 95.063 251.383C94.932 250.858 94.5389 250.465 93.8838 250.465C93.2287 250.465 92.7046 250.989 92.7046 251.645C92.7046 251.252 92.5736 250.989 92.3115 250.727C92.0495 251.776 91.7874 252.694 91.5254 253.612C92.1805 254.398 92.8356 254.792 92.8356 254.792Z"
        fill="black"
      />
      <path
        d="M105.67 242.991C105.67 242.335 105.146 241.811 104.491 241.811C103.967 241.811 103.443 242.204 103.312 242.729C103.05 243.909 104.491 245.22 105.146 245.744C105.408 245.876 105.539 246.007 105.539 246.007C105.539 246.007 108.029 244.171 107.767 242.598C107.635 242.073 107.242 241.68 106.587 241.68C106.194 241.942 105.67 242.466 105.67 242.991Z"
        fill="black"
      />
      <path
        d="M111.181 250.465C110.526 250.465 110.002 250.989 110.002 251.645C110.002 250.989 109.478 250.465 108.823 250.465C108.299 250.465 107.775 250.858 107.644 251.383C107.382 252.956 109.871 254.792 109.871 254.792C109.871 254.792 112.361 252.956 112.099 251.383C112.23 250.989 111.706 250.465 111.181 250.465Z"
        fill="black"
      />
      <path
        d="M94.0086 267.775C93.4845 267.775 93.0915 268.168 92.9604 268.562C93.3535 269.348 93.7466 270.135 94.2707 270.922C94.7948 270.266 95.3189 269.479 95.1878 268.824C94.9258 268.168 94.5327 267.775 94.0086 267.775Z"
        fill="black"
      />
      <path
        d="M114.32 242.992C114.32 242.336 113.796 241.812 113.14 241.812C112.616 241.812 112.092 242.205 111.961 242.729C111.699 244.303 114.189 246.139 114.189 246.139C114.189 246.139 115.106 245.483 115.761 244.696C115.499 243.778 115.106 242.86 114.844 241.943C114.582 242.205 114.32 242.598 114.32 242.992Z"
        fill="black"
      />
      <path
        d="M110.002 234.468C110.002 233.813 109.478 233.288 108.823 233.288C108.299 233.288 107.775 233.681 107.644 234.206C107.382 235.779 109.871 237.615 109.871 237.615C109.871 237.615 111.837 236.304 112.099 234.862C111.968 234.337 111.706 233.944 111.575 233.419C111.443 233.288 111.181 233.288 111.05 233.288C110.526 233.288 110.002 233.813 110.002 234.468Z"
        fill="black"
      />
      <path
        d="M118.649 254.792C118.649 254.792 118.78 254.661 118.911 254.661C118.649 253.35 118.256 252.039 117.732 250.596C117.601 250.596 117.601 250.596 117.47 250.596C116.946 250.596 116.422 250.99 116.291 251.514C116.16 253.087 118.649 254.792 118.649 254.792Z"
        fill="black"
      />
      <path
        d="M99.3897 277.344C99.2586 276.82 98.8656 276.426 98.2105 276.426C98.0794 276.426 97.9484 276.426 97.8174 276.557C98.2105 277.213 98.7345 277.737 99.1276 278.393C99.3897 277.869 99.3897 277.606 99.3897 277.344Z"
        fill="black"
      />
      <path
        d="M105.801 280.623C105.801 280.623 108.291 278.788 108.029 277.214C107.898 276.69 107.504 276.296 106.849 276.296C106.194 276.296 105.67 276.821 105.67 277.476C105.67 276.821 105.146 276.296 104.491 276.296C103.967 276.296 103.443 276.69 103.312 277.214C103.05 278.394 104.491 279.706 105.146 280.23C105.539 280.623 105.801 280.623 105.801 280.623Z"
        fill="black"
      />
      <path
        d="M117.465 267.775C116.941 267.775 116.417 268.168 116.286 268.693C116.286 269.086 116.286 269.479 116.548 269.873C116.941 269.217 117.334 268.431 117.596 267.775C117.596 267.775 117.596 267.775 117.465 267.775Z"
        fill="black"
      />
      <path
        d="M101.483 272.102C101.483 272.102 103.973 270.266 103.711 268.693C103.58 268.168 103.187 267.775 102.532 267.775C101.877 267.775 101.352 268.299 101.352 268.955C101.352 268.299 100.828 267.775 100.173 267.775C99.6491 267.775 99.125 268.168 98.994 268.693C98.863 270.266 101.483 272.102 101.483 272.102Z"
        fill="black"
      />
      <path
        d="M105.416 263.185C105.678 263.316 105.809 263.447 105.809 263.447C105.809 263.447 108.299 261.611 108.037 260.038C107.906 259.513 107.513 259.12 106.857 259.12C106.202 259.12 105.678 259.645 105.678 260.3C105.678 259.645 105.154 259.12 104.499 259.12C103.975 259.12 103.451 259.513 103.32 260.038C103.32 261.349 104.63 262.529 105.416 263.185Z"
        fill="black"
      />
      <path
        d="M99.3943 260.037C99.2633 259.513 98.8703 259.12 98.2151 259.12C97.56 259.12 97.0359 259.644 97.0359 260.3C97.0359 259.644 96.5118 259.12 95.8567 259.12C95.3326 259.12 94.8086 259.513 94.6775 260.037C94.4155 261.611 96.9049 263.447 96.9049 263.447C96.9049 263.447 99.6564 261.742 99.3943 260.037Z"
        fill="black"
      />
      <path
        d="M109.995 272.102C109.995 272.102 112.485 270.266 112.223 268.693C112.092 268.168 111.699 267.775 111.043 267.775C110.388 267.775 109.864 268.299 109.864 268.955C109.864 268.299 109.34 267.775 108.685 267.775C108.161 267.775 107.637 268.168 107.506 268.693C107.506 270.266 109.995 272.102 109.995 272.102Z"
        fill="black"
      />
      <path
        d="M114.326 263.447C114.326 263.447 116.815 261.611 116.553 260.037C116.422 259.513 116.029 259.12 115.374 259.12C114.719 259.12 114.195 259.644 114.195 260.3C114.195 259.644 113.671 259.12 113.016 259.12C112.492 259.12 111.967 259.513 111.836 260.037C111.836 261.742 114.326 263.447 114.326 263.447Z"
        fill="black"
      />
      <path
        d="M106.605 228.715C104.86 227.333 104.86 224.686 106.605 223.304L119.166 213.357C121.428 211.565 124.76 213.176 124.76 216.062L124.76 235.957C124.76 238.843 121.428 240.454 119.166 238.662L106.605 228.715Z"
        fill="black"
      />
      <path
        d="M104.373 228.715C106.117 227.333 106.117 224.686 104.373 223.304L91.812 213.357C89.5495 211.565 86.2179 213.176 86.2179 216.062L86.2179 235.957C86.2179 238.843 89.5495 240.454 91.812 238.662L104.373 228.715Z"
        fill="black"
      />
    </g>
  </svg>
);
export default Man;
