import * as React from 'react';

const Error = (props) => (
  <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={32} cy={32} r={32} fill="#FF3B79" />
    <path
      d="M24.9597 25.5996L39.0397 39.6796M24.9597 39.6796L39.0397 25.5996"
      stroke="white"
      strokeWidth={2.56}
      strokeLinecap="round"
    />
  </svg>
);
export default Error;
