/* eslint-disable max-len */
import * as React from 'react';

const Search = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.694 15.6719C19.5227 14.31 20 12.7107 20 11C20 6.02945 15.9706 2 11 2C6.02945 2 2 6.02945 2 11C2 15.9706 6.02945 20 11 20C12.7107 20 14.31 19.5227 15.6719 18.694C15.7541 18.793 15.8359 18.8918 15.9178 18.9905C16.7632 20.0105 17.5937 21.0124 18.5499 21.9991C19.1299 22.5975 19.9956 22.6841 20.615 22.1266C20.8344 21.9291 21.0912 21.6864 21.3888 21.3888C21.6864 21.0912 21.9291 20.8344 22.1266 20.615C22.6841 19.9956 22.5975 19.1299 21.9991 18.5499C21.0124 17.5937 20.0105 16.7632 18.9905 15.9178C18.8918 15.8359 18.793 15.7541 18.694 15.6719Z"
      fill="#7B8795"
    />
    <path
      d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
      fill="#CBD2D9"
    />
  </svg>
);
export default Search;
