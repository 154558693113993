import React, { useEffect, useRef } from 'react';

import cx from 'classnames';

// ts ignore all
// @ts-ignore
import Icon from '../icon';
import { ICONS } from '../../constants/icons.constanst';

import styles from './leftSideDrawerWrapper.module.scss';

interface Props {
  isVisible?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUserData?: object;
  children?: React.ReactElement;
  isNewLead?: boolean;
  dismissable?: boolean;
  heading?: string;
  // handleCancelClick?: () => void;
}

const LeftSideDrawerWrapper: React.FC<Props> = ({
  isVisible,
  setIsVisible,
  children,
  dismissable = true,
  isNewLead,
  heading,
  // handleCancelClick = () => setIsVisible(false),
}) => {
  const scrollContainerRef = useRef(null);

  const closeSideDrawer = () => {
    if (dismissable) setIsVisible(false);
  };

  useEffect(() => {
    if (!isVisible && scrollContainerRef.current) {
      setTimeout(() => {
        if (scrollContainerRef.current) scrollContainerRef.current.scrollTop = 0;
      }, 300);
    }
  }, [isVisible]);

  return (
    <>
      {isVisible && <div className={styles.leftSideDrawerWrapperContainer} onClick={closeSideDrawer}></div>}

      <div
        ref={scrollContainerRef}
        className={cx(styles.leftSideDrawerWrapper, { [styles.sideDrawerVisbile]: isVisible })}
      >
        <div className={styles.header}>
          <Icon className={styles.iconContainer} type={ICONS.RIGHTARROW} onClick={closeSideDrawer} />
          <p className={styles.heading}>{isNewLead ? 'הוספת ליד' : heading}</p>
        </div>
        {children}
      </div>
    </>
  );
};

export default LeftSideDrawerWrapper;
