import React, { useEffect, useState } from 'react';

import Input from '../input/Input';
import Button from '../button/Button';
import { get, map } from 'lodash';

import Box from './box/Box';
import { validateUserFields } from './helper';
import { ICONS } from '../../constants/icons.constanst';
import { updateUserAction } from '../../actions/userMamagement.action';
import useUserManagementStore from '../../zustand/userManagement.store';
import { convertTimestampIntoDate, numberWithCommas } from '../../helpers/generalHelpers';
import UserWithAvatarItem from '../userWithAvatarItem/UserWithAvatarItem';

import styles from './sideDrawerUserForm.module.scss';

interface Values {
  fullName?: string;
  firstname?: string;
  lastname?: string;
  id?: string;
  phone?: string;
  email?: string;
  uid?: string;
  balance?: string;
  userPaymentBalance?: string;
  amountToShare?: string;
  amountToClick?: string;
  numberOfRecords?: string;
  remainingActiveLeads?: string;
  activeLeads?: string;
  moneyInWalletWithTax?: string;
  withHoldingTaxAmount?: string;
  username?: string;
  methodsOfPayment?: string;
  created_at?: number | string;
  score?: number;
  tax?: number;
  withdraw?: number;
  identity_id?: number | string;
  shares_count?: number;
  clicks_count?: number;
  register_count?: number;
  active_leads_count?: number;
  total_leads_count?: number;
}
interface errorValues {
  id?: string;
  email?: string;
  firstname?: string;
  username?: string;
  lastname?: string;
  phone?: string;
}
interface itemValues {
  id?: number | string;
}
interface Props {
  data?: Values;
  allUserData?: [object];
  closeSideDrawer?: () => void;
  handleDeleteClick?: () => void;
  errors?: object;
}

const SideDrawerUserForm: React.FC<Props> = (props) => {
  const { data, allUserData, closeSideDrawer, handleDeleteClick } = props;
  const [values, setValues] = useState<Values>(null);
  const [errors, setErrors] = useState<errorValues>(null);

  const [isStateChanged, setIsStateChanged] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const updateUserTable = useUserManagementStore((state) => state.updateManagementDataSuccess);

  useEffect(() => {
    setValues(data);
    setErrors({});
  }, [data]);
  const date = convertTimestampIntoDate(data?.created_at);

  const handleInputChange = (id: string) => (e: string) => {
    setIsStateChanged(true);
    setValues({
      ...values,
      [id]: e,
    });
    setErrors((prev) => ({
      ...prev,
      [id]: '',
    }));
  };

  const handleSave = () => {
    const { canMove, errors } = validateUserFields(values);
    if (canMove) {
      map(allUserData, async (item: itemValues) => {
        if (item.id === data.id) {
          await updateUserAction(values);
          updateUserTable(values);
          closeSideDrawer();
          setIsStateChanged(false);
        } else {
          return;
        }
      });
    } else {
      setErrors(errors);
      return;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <UserWithAvatarItem
          date={date}
          styleLine2={styles.line2}
          iconType={ICONS.USER_LOGO_LARGE}
          level2={`דרגה ${get(data, 'degree', 1)}`}
          degree={get(data, 'degree', 1)}
        />

        <div className={styles.box}>
          <p className={styles.line1}>יתרת תשלום למשתמש</p>
          <p className={styles.amount}>₪{numberWithCommas(get(values, 'userPaymentBalance', 0))}</p>
        </div>

        <div className={styles.progressBar}>
          <div className={styles.progressBox}>
            <div
              className={styles.bar}
              style={{
                width: `${numberWithCommas(get(values, 'userPaymentBalance', 0))}/${
                  parseInt(get(data, 'balance', '0')) * 100
                }%`,
              }}
            ></div>
          </div>
          <p className={styles.amount2}>₪{numberWithCommas(get(data, 'balance', 0))}</p>
        </div>

        <div className={styles.boxContainer}>
          <Box title="כמות שיתופים" amount={numberWithCommas(get(data, 'shares_count', 0))} />
          <Box title="כמות ליחצות" amount={numberWithCommas(get(data, 'clicks_count', 0))} />
          <Box title="כמות ליחצות" amount={numberWithCommas(get(data, 'register_count', 0))} />
          <Box
            title="כמות שיתופים"
            amount={data?.active_leads_count}
            amount2={data?.total_leads_count}
            title2="לידים פעילים"
            ifTwoLines
          />
        </div>

        <div className={styles.textBox1}>
          <p className={styles.text}>כסף בארנק + מס</p>
          <p className={styles.text1}>₪{numberWithCommas(get(data, 'balance', 0))}</p>
        </div>
        <div className={styles.textBox1}>
          <p className={styles.text}>סכום ניכוי מס במקור</p>
          <p className={styles.text1}>
            ₪{numberWithCommas((parseFloat(get(data, 'balance', '0')) * get(data, 'tax', 1)) / 100)}
          </p>
        </div>

        <div className={styles.lineBreak}></div>

        <div className={styles.inputContainer}>
          <p className={styles.text3}>פרטים אישיים</p>
          <div className={styles.inputBox}>
            <Input
              className={styles.input}
              value={values?.username || ''}
              // setValue={handleInputChange('username')}
              placeholder="שם משתמש"
              preFilled
              error={errors?.username}
            />
            <Input
              className={styles.input}
              value={values?.firstname || ''}
              setValue={handleInputChange('firstname')}
              placeholder="שם פרטי"
              preFilled
              error={errors?.firstname}
            />
          </div>
          <div className={styles.inputBox}>
            <Input
              preFilled
              className={styles.input}
              value={values?.lastname || ''}
              setValue={handleInputChange('lastname')}
              placeholder="שם משפחה"
              error={errors?.lastname}
            />
            <Input
              preFilled
              className={styles.input}
              value={values?.phone || ''}
              setValue={handleInputChange('phone')}
              placeholder="טלפון"
              error={errors?.phone}
            />
          </div>
          <div className={styles.inputBox}>
            <Input
              preFilled
              className={styles.input}
              value={values?.email || ''}
              // setValue={handleInputChange('email')}
              placeholder="אימייל"
              error={errors?.email}
            />
            <Input
              preFilled
              className={styles.input}
              value={values?.identity_id || ''}
              // setValue={handleInputChange('id')}
              placeholder="ת.ז"
              error={errors?.id}
            />
          </div>
          <div className={styles.inputBox}>
            {/* <Input
              preFilled
              className={styles.input}
              value={values?.methodsOfPayment || ''}
              // setValue={handleInputChange('methodsOfPayment')}
              placeholder="אמצעי תשלום"
            /> */}
          </div>
        </div>

        <div className={styles.btn}>
          <Button className={styles.button} disabled={!isStateChanged} onClick={handleSave}>
            עדכן
          </Button>
        </div>

        <div className={styles.btn2}>
          <Button className={styles.deleteSection} startIcon={ICONS.DELETE} onClick={handleDeleteClick}>
            <p className={styles.deleteText}>מחק משתמש</p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default SideDrawerUserForm;
