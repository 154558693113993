import React from 'react';

import styles from './column.module.scss';

interface Props {
  title?: string;
  item1?: number;
  item2?: number;
  item3?: number;
}

const Column: React.FC<Props> = ({ title, item1, item2, item3 }) => (
  <div className={styles.userColumn}>
    <p className={styles.heading}>{title}</p>
    <p className={styles.number}>{item1}</p>
    <p className={styles.number}>{item2}</p>
    <p className={styles.number}>{item3}</p>
  </div>
);

export default Column;
