/* eslint-disable max-len */
import * as React from 'react';

const Delete = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_817_9355)">
      <path d="M1.71436 6H22.2858" stroke="#52606D" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.28564 6H19.7142V21.4286C19.7142 21.8832 19.5336 22.3193 19.2121 22.6408C18.8906 22.9622 18.4546 23.1429 17.9999 23.1429H5.99993C5.54527 23.1429 5.10924 22.9622 4.78775 22.6408C4.46626 22.3193 4.28564 21.8832 4.28564 21.4286V6Z"
        stroke="#52606D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71436 6.00028V5.14314C7.71436 4.00649 8.16588 2.91641 8.96961 2.11268C9.77334 1.30895 10.8634 0.857422 12.0001 0.857422C13.1367 0.857422 14.2268 1.30895 15.0305 2.11268C15.8343 2.91641 16.2858 4.00649 16.2858 5.14314V6.00028"
        stroke="#52606D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.42871 9.42871V18.8573"
        stroke="#52606D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5713 9.42871V18.8573"
        stroke="#52606D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_817_9355">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Delete;
