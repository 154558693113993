import axios from '../../axios';

// axios.defaults.params = {}
// axios.defaults.params['api-key'] = secret;

// const access_token = localStorage.getItem('access-token');

// const config = {
//   headers: {
//     Authorization: 'Bearer ' + access_token,
//   },
// };

const getUserManagementData = () => axios.get('/v1/admin/users');

const getUserManagementDataAccordingToSeacrh = (searchKey) => axios.get(`/v1/admin/users?searchkey=${searchKey}`);

const updateUserManagementData = async (payload) => {
  await axios.post(`/v1/admin/user/${payload.id}`, payload);
};

const deleteUserManagementData = async (payload) => await axios.delete(`/v1/admin/user/${payload}`);

export const userMangementServices = {
  getUserManagementData,
  updateUserManagementData,
  deleteUserManagementData,
  getUserManagementDataAccordingToSeacrh,
};
