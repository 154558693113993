import { isEmpty } from 'lodash';
import { EMPTY_LEAD } from '../constants/types.constant';
import { getImageExtension } from '../helpers/generalHelpers';
import { getAddOrUpdateLeadPayload } from '../helpers/leads.helpers';
import { leadsService } from '../redux/leads/leads.service';

export const fetchLeadsData = async () => {
  try {
    const response = await leadsService.fetchLeadData();

    return response;
  } catch (err) {
    // console.log(err);
  }
};

export const fetchUserDataAccordingToSearch = async (searchKey) => {
  try {
    const response = await leadsService.fetchLeadsDataAccordingToSeacrh(searchKey);

    return response;
    // yield put(addManagementData(response.data.users));
  } catch (err) {
    // console.log(err);
  }
};

export const deleteLead = async (id) => {
  try {
    await leadsService.deleteLead(id);
    return true;
  } catch (err) {
    return false;
  }
};

export const addOrUpdateLead = async (leadData = {}) => {
  try {
    // add or update lead
    const payload = getAddOrUpdateLeadPayload(leadData);
    const { data } = await leadsService.addOrUpdateLead(payload);

    // fetch lead id
    // for add lead we'll have id in repsonse
    // for update lead we'll have id in leadData
    let leadId = null;
    if (isEmpty(leadData?.id)) leadId = data?.id;
    else leadId = leadData?.id;

    // add lead photo
    let photo = leadData.photo;
    if (!isEmpty(leadData.visibleLeadAvatar)) {
      const { leadAvatar } = leadData;
      const formData = new FormData();
      photo = leadId + '.' + getImageExtension(leadAvatar);
      formData.append('file', leadAvatar);
      await leadsService.addLeadPhoto(leadId, formData);
    }

    return {
      ...EMPTY_LEAD,
      ...leadData,
      id: leadId,
      photo,
    };
  } catch (err) {
    // console.log(err);
    return {};
  }
};

export const toggleLeadStatus = async (id, status) => {
  try {
    const dataToSend = {
      enabled: status,
    };
    await leadsService.toggleLeadStatus(id, dataToSend);
    return true;
  } catch (error) {
    return false;
  }
};
