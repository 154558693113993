import { call, put, takeLatest } from 'redux-saga/effects';
import { PaymentrService } from './paymentTransfer.service';
import { paymentDataFetchSuccess } from './paymentTransfer.slice';

export function* paymentTransferDataRequestStart({ payload }) {
  try {
    const response = yield call(PaymentrService.fetchData);

    yield put(paymentDataFetchSuccess(response.data));
  } catch (err) {
    // console.log(err);
  }
}

const paymentTransferSaga = [takeLatest('paymentTansfer/paymentDataFetchStart', paymentTransferDataRequestStart)];

export default paymentTransferSaga;
