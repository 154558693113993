import { create } from 'zustand';

interface NEW_LEADS {
  isSideDrawerOpen: boolean;
  setIsSideDrawerOpen: (newVal: boolean) => void;
}

const newLeadStore = create<NEW_LEADS>((set) => ({
  isSideDrawerOpen: false,
  setIsSideDrawerOpen: (newVal) => {
    set((state) => ({ ...state, isSideDrawerOpen: newVal }));
  },
}));

export default newLeadStore;
