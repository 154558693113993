import React, { useEffect, useState } from 'react';

import { get, isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';

import { Button, Input } from '../../components';
import Icon from '../../components/icon';
import { ICONS } from '../../constants/icons.constanst';
import { getFormattedTime } from '../../helpers/generalHelpers';
import { validateMobileNumber } from '../../helpers/generalHelpers';
import { fetchToken, fetchData, otpValidation } from '../../actions/phoneVerification.action';
import { BASE_URL } from '../../axios';
import hotPic from '../../assests/hotPic.png';

import styles from './phoneVerification.module.scss';
import Loader from '../../components/loader/Loader';

interface Props {}

const height = window.innerHeight;

const PhoneVerification: React.FC<Props> = () => {
  const [mobileNumber, setMobileNumber] = useState(null);
  const [enterOtp, setEnterOtp] = useState(false);
  const [error, setError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(true);
  const [otp, setOtp] = useState('');

  const [response, setResponse] = useState({});
  const [token, setToken] = useState('');

  const [time, setTime] = useState(270000);

  const [searchParams] = useSearchParams();

  const lastFourDigit = mobileNumber % 10000;

  useEffect(() => {
    (async () => {
      const linkID = searchParams.get('linkId');

      if (isEmpty(linkID)) {
        setGeneralError('משהו השתבש');
        setIsLoading1(false);
        return;
      }

      const { data, error } = await fetchData(linkID);
      setIsLoading1(false);

      if (error) {
        setGeneralError(error);
        return;
      }

      setResponse(data);
    })();
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setTime((v) => Math.max(v - 1000, 0));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [otp]);

  const handleSetValues = (value: string) => {
    setError('');
    setMobileNumber(value);
  };

  const handleButtonClick = async () => {
    const err = validateMobileNumber(mobileNumber);

    if (!isEmpty(err)) {
      setError(err);
      return;
    }

    const updatedMobileNumber = `+972${mobileNumber}`;
    setIsLoading(true);
    const { token, error } = await fetchToken(updatedMobileNumber, get(response, 'session_id', ''));
    setIsLoading(false);

    if (!isEmpty(error)) {
      setError(error);
      return;
    }
    setEnterOtp(true);
    setToken(token);

    return;
  };

  const handleOtpClick = async () => {
    setIsLoading(true);
    const { link, error } = await otpValidation(token, get(response, 'session_id', ''), otp);
    setIsLoading(false);

    if (!isEmpty(error)) {
      setGeneralError(error);
      return;
    }

    window.open(`http://${link}`, '_blank');
    // window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className={styles.phoneVerificationContainer} style={{ scale: height <= 900 && '75%' }}>
        <div className={styles.phoneVerification}>
          <div className={styles.mainIcon}>
            <Icon type={ICONS.CELEBRATIONS} />
          </div>
          <p className={styles.title}>{'עוד פרט נוסף וממשיכים '}</p>

          <div className={styles.box}>
            <div className={styles.userData}>
              {get(response, 'photo', '') ? (
                <div className={styles.image}>
                  <img
                    src={`${BASE_URL}/v1/photos/leads/${get(response, 'photo', '')}`}
                    alt="is Loading"
                    crossOrigin="anonymous"
                  />
                </div>
              ) : (
                <img src={hotPic} alt="notFound" />
              )}
              <img src={get(response, 'photo', '')} alt=""></img>
              <div className={styles.dataContainer}>
                <div className={styles.data}>
                  <p className={styles.companyName}>{get(response, 'companyName', '')} </p>
                  <p className={styles.line}></p>
                  <p className={styles.catagory}>{get(response, 'category', '')}</p>
                </div>
                <p className={styles.subCatagory}>{get(response, 'subcategory', '')}</p>
              </div>
            </div>
            <div className={styles.value}>
              <p className={styles.valuation}>50</p>
              <p className={styles.sign}>₪</p>
            </div>
            <p className={styles.description}>{get(response, 'description', '')}</p>
          </div>
          {!enterOtp && <p className={styles.number}>הכנס מספר נייד לזיהוי</p>}
          {enterOtp && (
            <>
              <p className={styles.code}>{`אנא הזן את הקוד שנשלח למספר: \n ${lastFourDigit} *** - *** `} </p>
              <div className={styles.otpContainer}>
                <OtpInput
                  inputStyle={styles.otp_code}
                  containerStyle={styles.otpContainer}
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  inputType="tel"
                  renderInput={(props) => <input {...props} />}
                ></OtpInput>
              </div>
              <div className={styles.base_timer}>
                <svg className={styles.base_timer__svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <g className={styles.base_timer__circle}>
                    <circle className={styles.base_timer__path_elapsed} cx="50" cy="50" r="45"></circle>
                    <path
                      id="base-timer-path-remaining"
                      stroke-dasharray="283"
                      className={'styles.base_timer__path_remaining'}
                      d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
                  "
                    ></path>
                  </g>
                </svg>
                <span id="base-timer-label" className={styles.base_timer__label}>
                  {getFormattedTime(time)}
                </span>
              </div>
              <p className={styles.newOTP}>
                {'לא קיבלתי '}
                <span className={styles.sendmeNewotp}> {'  תשלחו לי קוד חדש  '}</span>
              </p>{' '}
            </>
          )}
          {!enterOtp && (
            <div style={{ marginBottom: '103px', height: '70px' }}>
              <Input
                value={mobileNumber}
                setValue={handleSetValues}
                className={styles.input}
                label={'מספר נייד'}
                onEnterPress={handleButtonClick}
                error={error}
              ></Input>
            </div>
          )}

          {generalError && <p className={styles.generalErrors}>{generalError}</p>}
          <Button
            disabled={false}
            loading={isLoading}
            className={styles.button}
            onClick={enterOtp ? handleOtpClick : handleButtonClick}
          >
            אישור
          </Button>
        </div>
      </div>
      <Loader isLoading={isLoading1}></Loader>
    </>
  );
};

export default PhoneVerification;
