import { map } from 'lodash';

export const getFormattedDashboard = (data) => ({
  leads: {
    activeCount: data?.total_enabled_leads_count,
    inactiveCount: data?.total_disabled_leads_count,
  },

  payments: {
    total: data?.payments?.total,
    payed: data?.payments?.payed,
  },

  popularLeads: data?.popular_leads,

  totalUsersCount: data?.users?.total_users_count,
  users: data?.users?.graph,
  graphReport: data?.graph?.hourly_report,
  totalClicksCount: data?.graph?.total_clicks_count,
  totalRegisterCount: data?.graph?.total_register_count,
  totalSharesCount: data?.graph?.total_shares_count,
});

export const getFormattedLeaddata = (data) => ({
  payments: {
    total: data?.payments?.total,
    payed: data?.payments?.payed,
  },
  graphReport: data?.graph?.hourly_report,
  totalClicksCount: data?.graph?.total_clicks_count,
  totalRegisterCount: data?.graph?.total_register_count,
  totalSharesCount: data?.graph?.total_shares_count,
});

export const chartInfo = (formattedGraphData) => ({
  series: [
    {
      name: 'User Counts',
      data: formattedGraphData,
    },
  ],
  options: {
    tooltip: {
      enabled: false,
    },

    colors: ['#1F2933'],
    chart: {
      height: 350,
      type: 'line',

      toolbar: {
        show: false,
      },
      // remove the zoom on selection
      selection: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },

    grid: {
      show: false,
      // row: {
      //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //   opacity: 0.5,
      // },
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      show: false,
      categories: [],

      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  },
});

export const activityChartInfo = (data) => {
  //! Here we will replace shareCount as we dont have it now item?.r_count
  // console.log(data, 'kuijnj');
  let shareMax = 0;
  let shareMin = 0;
  let clickMax = 0;
  let clickMin = 0;
  let registerMax = 0;
  let registerMin = 0;

  const sharesData = map(data, (item) => {
    if (item?.s_count > shareMax) shareMax = item?.s_count;
    if (item?.s_count < shareMin) shareMin = item?.s_count;

    let x = item?.time || 0;
    if (x) x = `${parseInt(x)}:00`;
    const y = item?.s_count;
    return { x, y };
  });
  const clicksData = map(data, (item) => {
    if (item?.c_count > clickMax) clickMax = item?.c_count;
    if (item?.c_count < clickMin) clickMin = item?.c_count;
    return { x: `${parseInt(item?.time)}:00`, y: item?.c_count };
  });
  const registerData = map(data, (item) => {
    if (item?.r_count > registerMax) registerMax = item?.r_count;
    if (item?.r_count < registerMin) registerMin = item?.r_count;
    return { x: `${parseInt(item?.time)}:00`, y: item?.r_count };
  });

  const min = Math.min(shareMin, clickMin, registerMin);
  const max = Math.max(shareMax, clickMax, registerMax);

  return {
    series: [
      {
        name: 'Shares',
        data: sharesData,
      },
      {
        name: 'Clicks',
        data: clicksData,
      },
      {
        name: 'Resgister',
        data: registerData,
      },
    ],

    options: {
      legend: {
        show: false,
      },
      colors: ['#7B8795', '#CBD2D9', '#1F2933'],

      chart: {
        foreColor: '#7B8795',

        dataLabels: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },

        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350,
          },
        },

        type: 'line',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },

      grid: {
        show: true,
        borderColor: 'rgba(203, 210, 217,0.4)',
      },
      yaxis: {
        tickAmount: 3,
        min,
        max,

        labels: {
          show: true,
          style: {
            colors: ['#7B8795'],
            fontSize: '12px',
            paddingRight: 15,
            paddingLeft: 15,
            fontFamily: 'Heebo, Arial, sans-serif',
            fontWeight: 400,
            lineHeight: 18,
          },
          offsetX: max >= 100 ? -30 : -10,
          offsetY: 2.5,
        },
      },

      xaxis: {
        tickAmount: 18,
        labels: {
          style: {
            colors: ['green'],
            fontSize: '12px',
            fontFamily: 'Heebo, Arial, sans-serif',
            fontWeight: 400,
            lineHeight: 18,
          },
        },
      },
    },
  };
};
export const getDate = (d) => {
  const dateString = d;
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};
