import React from 'react';
import styles from './Snackbar.module.scss';
import snackBarStore from '../../zustand/snackBarStore';
import Icon from '../icon';
import { ICONS } from '../../constants/icons.constanst';

const Snackbar = () => {
  const { isOpen, message, closeSnackBar } = snackBarStore();
  return (
    isOpen && (
      <div className={styles.Snackbar} dir="ltr">
        <div className={styles.crossIcon} onClick={() => closeSnackBar()}>
          <Icon type={ICONS.DELETE} />
        </div>
        <div className={styles.desc}>
          <p className={styles.information}>{message}</p>
        </div>
      </div>
    )
  );
};

export default Snackbar;
