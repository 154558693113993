/* eslint-disable max-len */
import * as React from 'react';

const RightArrow = (props) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={39} height={39} rx={5.5} stroke="#323E4A" />
    <path
      d="M15.73 25.5215L15.7367 25.5165L15.7433 25.5113L20.8573 21.4495L20.8683 21.4408L20.8788 21.4314L22.081 20.3613L22.4999 19.9883L22.0815 19.6148L20.8794 18.5418L20.8686 18.5322L20.8572 18.5232L15.7437 14.4645C15.7436 14.4645 15.7436 14.4644 15.7435 14.4644C15.5539 14.3134 15.5043 14.1214 15.5477 13.9616L15.5477 13.9616C15.5942 13.7903 15.7621 13.6055 16.0496 13.5306C16.3377 13.4557 16.641 13.5227 16.8385 13.6797L16.8388 13.6799L24.2891 19.5957L24.2892 19.5958C24.4379 19.7138 24.5 19.8591 24.5 19.9878C24.5 20.1165 24.4379 20.2617 24.2892 20.3798C24.2892 20.3798 24.2892 20.3798 24.2892 20.3798L16.8389 26.2927L16.8316 26.2985L16.8246 26.3045C16.6834 26.4253 16.475 26.5031 16.2452 26.4999L16.2449 26.4999C16.015 26.4969 15.8109 26.4137 15.6759 26.2907C15.5435 26.1701 15.4931 26.0289 15.5008 25.9036L15.5008 25.9033C15.5085 25.7764 15.5775 25.6345 15.73 25.5215Z"
      fill="#F6F7FB"
      stroke="#323E4A"
    />
  </svg>
);
export default RightArrow;
