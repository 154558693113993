import React, { useState, useEffect, useRef } from 'react';

import { TextField } from '@mui/material';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import Button from '../button/Button';
import Input from '../input/Input';
import LeadManagementInfo from '../leadManagementInfo/LeadManagementInfo';
import Icon from '../icon';
import UserStatus from '../userStatus/UserStatus';
import { ICONS } from '../../constants/icons.constanst';
import { validateLeadFields } from './sideDrawerContent.helpers.';
import { numberWithCommas, removeCommas } from '../../helpers/generalHelpers';
import { EMPTY_LEAD, Lead } from '../../constants/types.constant';
import { BASE_URL } from '../../axios';

import styles from './sideDrawerContent.module.scss';

interface Props {
  initalFormValues?: Lead;
  isNewLead?: boolean;
  handlePrimaryClick?: (lead: Lead) => void;
  handleSecondaryClick?: () => void;
  clearDataOnCancel?: boolean;
  isVisible?: boolean;
  setShowSideDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideDrawerContent: React.FC<Props> = (props) => {
  const {
    initalFormValues,
    isNewLead,
    handlePrimaryClick,
    handleSecondaryClick,
    clearDataOnCancel = true,
    isVisible,
    setShowSideDrawer,
  } = props;

  const photoInputRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValues] = useState<Lead>(EMPTY_LEAD);

  useEffect(() => {
    if (initalFormValues) {
      setFormValues({
        companyName: initalFormValues.companyName,
        category: initalFormValues.category,
        subCategory: initalFormValues.subCategory,
        valuation: initalFormValues.valuation,
        link: initalFormValues.link,
        description: initalFormValues.description,
        visibleLeadAvatar: initalFormValues.visibleLeadAvatar,
        leadAvatar: initalFormValues.leadAvatar,
        enabled: initalFormValues.enabled,
        budget: initalFormValues.budget,
      });
    }
    setErrors({});
  }, [initalFormValues]);

  useEffect(() => {
    setErrors({});
  }, [isVisible]);

  const addLeadPhoto = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setFormValues({
            ...formValue,
            visibleLeadAvatar: reader.result.toString(),
            leadAvatar: e.target.files[0],
          });
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSetFormValues = (id: string) => (paramValue: string | FormData | boolean) => {
    // if (formValue) {

    // } else {
    // setShowSideDrawer(true);
    // }
    let value = paramValue;
    if (id === 'budget' || id === 'valuation') {
      value = removeCommas(value);
    }

    if (setShowSideDrawer) setShowSideDrawer(false);

    setFormValues({
      ...formValue,
      [id]: value,
    });

    setErrors((prev) => ({
      ...prev,
      [id]: '',
    }));
  };

  const valuation = numberWithCommas(formValue?.valuation);
  const budget = numberWithCommas(formValue?.budget);

  const onPrimaryClick = () => {
    const { canMove, errors } = validateLeadFields(formValue);
    if (canMove) {
      handlePrimaryClick(formValue);
      setFormValues(EMPTY_LEAD);
      if (setShowSideDrawer) setShowSideDrawer(true);
    } else {
      setErrors(errors);
    }
  };

  const onSecondaryClick = () => {
    handleSecondaryClick();
    if (clearDataOnCancel) setFormValues(EMPTY_LEAD);
    if (setShowSideDrawer) setShowSideDrawer(true);
  };

  return (
    <div>
      <div className={styles.logoContainer}>
        <div
          className={styles.icon}
          onClick={() => {
            if (photoInputRef.current) {
              photoInputRef.current.click();
            }
          }}
        >
          {!isEmpty(formValue.visibleLeadAvatar) ? (
            <img
              src={formValue.visibleLeadAvatar}
              alt=""
              style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
            />
          ) : isEmpty(initalFormValues.photo) ? (
            <Icon type={ICONS.ADD_LEAD_PHOTO} />
          ) : (
            <img
              src={`${BASE_URL}/v1/photos/leads/${initalFormValues.photo}`}
              alt="is Loading"
              crossOrigin="anonymous"
              style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
            />
          )}
        </div>
        {!isNewLead && (
          <div>
            <p className={styles.package}>{initalFormValues.companyName || ''}</p>
            <p className={styles.internet}>{initalFormValues.category || ''}</p>
          </div>
        )}
      </div>
      <input type="file" style={{ display: 'none' }} ref={photoInputRef} accept="image/*" onChange={addLeadPhoto} />

      {!isNewLead && (
        <div className={styles.leadManagementInfo}>
          <LeadManagementInfo number={initalFormValues.registerCount} info="כמות לחיצות" />
          <LeadManagementInfo number={initalFormValues.shareCount} info="כמות שיתופים" />
          <LeadManagementInfo number={initalFormValues.clicksCount} info="כמות רשומים" />
        </div>
      )}

      <div className={styles.line}></div>

      <p className={styles.update}>עדכון פרטים</p>
      <form>
        <div className={styles.updateContainer}>
          <div className={styles.updateFields}>
            <Input
              setValue={handleSetFormValues('companyName')}
              placeholder="שם החברה"
              className={styles.textStyle}
              value={formValue?.companyName}
              preFilled
              error={errors['companyName'] || ''}
            />
            <Input
              setValue={handleSetFormValues('category')}
              placeholder="קטגוריה"
              className={styles.textStyle}
              value={formValue.category}
              error={errors['category'] || ''}
            />
          </div>

          <div className={styles.updateFields}>
            <Input
              setValue={handleSetFormValues('subCategory')}
              placeholder="תת קטגוריה"
              className={styles.textStyle}
              value={formValue.subCategory}
              error={errors['subCategory'] || ''}
            />
            <Input
              setValue={handleSetFormValues('valuation')}
              placeholder="שווי"
              className={styles.textStyle}
              value={valuation}
              error={errors['valuation'] || ''}
            />
          </div>

          <div className={styles.updateFields}>
            <Input
              setValue={handleSetFormValues('budget')}
              placeholder="תקציב"
              className={styles.textStyle}
              value={budget}
              error={errors['budget'] || ''}
            />
            <Input
              setValue={handleSetFormValues('link')}
              placeholder="לינק"
              className={styles.textStyle}
              value={formValue.link}
              error={errors['link'] || ''}
            />
          </div>
          <div className={styles.updateFields2}>
            <Input
              placeholder="סטטוס"
              className={styles.textStyle}
              value={formValue.enabled ? ' פעיל' : 'לא פעיל'}
              contentEditable={false}
              endIcon={<UserStatus isTextHidden active={formValue.enabled} onChange={handleSetFormValues('enabled')} />}
            />
          </div>
          <TextField
            label="תיאור"
            InputLabelProps={{
              classes: {
                root: styles.label,
              },
            }}
            variant="outlined"
            color="info"
            multiline={true}
            rows={3}
            className={cx(styles.description, { [styles.text]: errors['description'] })}
            value={formValue.description}
            onChange={(e) => handleSetFormValues('description')(e.target.value)}
            error={errors['description'] || ''}
            sx={{
              input: {
                color: 'red',
              },
              label: { color: 'black', fontSize: '14px' },
            }}
            // helperText="Please enter description"
            InputProps={{
              classes: {
                root: styles.description,
              },
            }}
          />
          {errors['description'] && <div className={styles.error}>{errors['description']}</div>}
          <Button className={styles.updateButton} onClick={onPrimaryClick}>
            <p>{isNewLead ? 'שמור' : 'עדכן'}</p>
          </Button>

          <Button className={styles.deleteButton} startIcon={isNewLead ? '' : ICONS.DELETE} onClick={onSecondaryClick}>
            <p style={{ color: '#000000' }}>{isNewLead ? 'ביטול' : 'מחק ליד'}</p>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SideDrawerContent;
