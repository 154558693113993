import { map } from 'lodash';

export const getAddOrUpdateLeadPayload = (lead = {}) => {
  const { id } = lead;

  const payload = {
    company_name: lead.companyName,
    description: lead.description,
    category: lead.category,
    subcategory: lead.subCategory,
    link: lead.link,
    valuation: lead.valuation,
    budget: lead.budget,
    enabled: lead.enabled,
  };

  if (id) {
    return {
      ...payload,
      id,
    };
  }
  return payload;
};

export const getUpdateLeadPayload = () => {};

export const getFormattedLeads = (leads = []) =>
  map(leads, (lead = {}) => ({
    id: lead.id,
    registerCount: lead.register_count,
    shareCount: lead.shares_count,
    clicksCount: lead.clicks_count,
    companyName: lead.company_name,
    description: lead.description,
    photo: lead.photo,
    category: lead.category,
    subCategory: lead.subcategory,
    valuation: lead.valuation,
    budget: lead.budget,
    link: lead.link,
    enabled: lead.enabled,
    budgetUsed: lead.budget_used,
    createdAt: lead.created_at,
  }));
