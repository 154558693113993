import React from 'react';
import { Button } from '../../components';

import UserWithAvatarItem from '../../components/userWithAvatarItem/UserWithAvatarItem';
import { ICONS } from '../../constants/icons.constanst';

import styles from './paymentTransfer.module.scss';

export const getColumns = ({ setData, setOpenPaymentTransferPopUp, setRefusalMoneytransferPopUp }) => {
  let width = window.innerWidth;
  let tableWidth = (925 * (width - 90)) / 1000;

  const OpenPaymentTransferPopUp = () => {
    setOpenPaymentTransferPopUp(true);
  };

  const openRefusePopUp = () => {
    setRefusalMoneytransferPopUp(true);
  };

  const getRowData = (data) => {
    setData(data);
  };

  return [
    {
      field: 'date',
      headerName: 'תאריך הבקשה',
      headerClassName: styles.header,
      cellClassName: styles.date,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      width: 0.125 * tableWidth,
      sortComparator: (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime(),
    },
    {
      field: 'paymentDate',
      headerName: 'תאריך תשלום',
      headerClassName: styles.header,
      cellClassName: styles.date,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      width: 0.125 * tableWidth,
    },

    {
      field: 'userIcon',
      headerName: 'שם משתמש',
      headerClassName: styles.header,
      type: 'actions',
      align: 'left',
      headerAlign: 'left',
      cellClassName: styles.userIcon,
      width: 0.2 * tableWidth,

      renderCell: (params) => (
        <UserWithAvatarItem
          iconType={ICONS.USERLOGO}
          userName={params.row.username}
          styleLine2={styles.userName}
          activeLeads={params.row.activeLeads}
          userCreatedAt={params.row.userCreatedAt}
        />
      ),
    },

    {
      field: 'phone',
      headerName: 'טלפון',
      headerClassName: styles.header,
      cellClassName: styles.date,
      headerAlign: 'left',
      align: 'left',
      width: 0.13 * tableWidth,
    },

    {
      field: 'sum',
      headerName: 'סכום',
      headerClassName: styles.header,
      cellClassName: styles.sum,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 0.1 * tableWidth,
    },

    {
      field: 'status',
      headerName: 'סטטוס',
      headerClassName: styles.header,
      cellClassName: styles.status,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 0.08 * tableWidth,

      renderCell: (params) => {
        if (params.row.status === 1) {
          return <div> ממתין </div>;
        } else if (params.row.status === 2) {
          return <div style={{ color: 'green' }}> שולם </div>;
        } else {
          return <div style={{ color: 'red' }}> סורב </div>;
        }
      },
    },

    {
      field: 'refuse',
      headerClassName: styles.header,
      headerAlign: 'left',
      align: 'left',
      type: 'actions',
      width: 0.113 * tableWidth,
      renderCell: (params) => {
        if (params.row.status === 1) {
          return (
            <div onClick={() => getRowData(params)}>
              <Button className={styles.btn1} onClick={openRefusePopUp}>
                סירוב לתשלום
              </Button>
            </div>
          );
        }
      },
    },

    {
      field: 'transfer',
      headerClassName: styles.header,
      headerAlign: 'left',
      align: 'left',
      type: 'actions',
      width: 0.124 * tableWidth,
      renderCell: (params) => {
        if (params.row.status === 1) {
          return (
            <div onClick={() => getRowData(params)}>
              <Button className={styles.btn1} onClick={OpenPaymentTransferPopUp}>
                העבר תשלום
              </Button>
            </div>
          );
        }
      },
    },
  ];
};

// export const getRows = (noOfRows = 50) => {
//   const res = [];

//   for (let i = 0; i < 20; i++) {
//     const randomNumber = Math.floor(Math.random() * 3);
//     // let paymentStatus = '';

//     // if (randomNumber === 0) {
//     //   paymentStatus = 'ממתין';
//     // } else if (randomNumber === 1) {
//     //   paymentStatus = 'סורב ';
//     // } else {
//     //   paymentStatus = 'שולם';
//     // }

//     res.push({
//       randomNumber,
//       id: i,
//       date: ' 21/02/2022 ',
//       phone: '052-35466776',
//       sum: '₪3,145',
//       // status: paymentStatus,
//       userName: 'מנחם אברהם',
//       activeLeads: '123 לידים פעילים',
//     });
//     // console.log(res);
//   }

//   return res;
// };
