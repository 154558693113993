import { useState } from 'react';

import PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar as NativeCalendar } from 'react-date-range';
import cx from 'classnames';
import { he } from 'date-fns/locale';

import Icon from '../../components/icon';
import { ICONS } from '../../constants/icons.constanst';

import styles from './dashboard.module.scss';
import { getDate } from '../../helpers/dashboard.helpers';

function isDateSpanWithinOneYear(date1, date2) {
  const diffMs = Math.abs(date2 - date1);

  const diffYears = diffMs / (1000 * 60 * 60 * 24 * 365);

  return diffYears <= 1;
}

const Calendar = (props) => {
  const { hadnleSaveClick, startDate, setStartDate } = props;
  const [openCalendar, setOpenCalendar] = useState(false);

  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState('');

  const handleCalender = () => {
    setOpenCalendar(!openCalendar);
  };

  const handleOnChange = (type, e) => {
    if (type === '1') {
      setError('');
      setEndDate(e);
    } else {
      setStartDate(e);
    }
  };

  // const selectDatesFromSide = (days) => {
  //   const today = new Date();
  //   setStartDate(today);
  //   setEndDate(new Date(today.getTime() - days * 24 * 60 * 60 * 1000));
  // };

  const handleBackgroundClick = () => {
    setOpenCalendar(false);
  };

  const handleSave = async () => {
    if (!isDateSpanWithinOneYear(startDate, endDate)) {
      setError('אנא בחר תאריכים בטווח של שנה');
      return;
    }
    setError('');
    setOpenCalendar(false);
    hadnleSaveClick(startDate, endDate);
  };

  // console.log(
  //   getDate(startDate),
  //   'sataet',
  //   getDate(endDate),
  //   'ninini',
  //   isDateSpanWithinOneYear(startDate, endDate),
  //   'within',
  // );

  return (
    <>
      <div className={styles.infoWrapper}>
        <div className={styles.input} onClick={handleCalender}>
          <div className={styles.icon}>
            <Icon type={ICONS.CALENDAR} />
            <p className={styles.calText}>יום</p>
          </div>
          <div className={styles.dateRange}>
            <p className={styles.dateText}>{getDate(startDate) !== 'NaN/NaN/NaN' ? getDate(startDate) : '-'}</p>-
            <p className={styles.dateText}> {getDate(endDate) !== 'NaN/NaN/NaN' ? getDate(endDate) : '-'}</p>
          </div>
          <div className={cx(styles.icon, { [styles.rotate]: openCalendar })}>
            <Icon type={ICONS.DOWNARROW} />
          </div>
        </div>

        {openCalendar && (
          <>
            <div className={styles.background} onClick={handleBackgroundClick}></div>
            <div className={styles.calendarTopWrapper}>
              {/* <div className={styles.container}> */}
              {/* <div className={styles.selectDays}>
              <p className={styles.days2}>סינון לפי:</p>
              <p
                className={styles.days}
                onClick={() => {
                  setEndDate(new Date());
                  setStartDate(new Date());
                }}
              >
                יום
              </p>
              <p className={styles.days} onClick={() => selectDatesFromSide(6)}>
                שבוע
              </p>
              <p className={styles.days} onClick={() => selectDatesFromSide(29)}>
                חודש
              </p>
              <p className={styles.days}>שנה</p>
            </div> */}

              <div className={styles.wrapper}>
                <div className={styles.one}>
                  <p className={styles.headingText}>מתאריך</p>
                  <NativeCalendar
                    locale={he}
                    date={startDate}
                    onChange={(e) => {
                      if (e.getTime() > new Date().getTime()) return;
                      handleOnChange('2', e);
                    }}
                    className={styles.calendar}
                    style={{
                      width: '445px',
                      height: '270px',
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                    }}
                  />
                </div>
                <div className={styles.two}>
                  <p className={styles.headingText}>עד תאריך</p>
                  <NativeCalendar
                    locale={he}
                    date={endDate}
                    onChange={(e) => {
                      if (e.getTime() > new Date().getTime()) return;
                      if (e.getTime() < startDate.getTime()) return;

                      handleOnChange('1', e);
                    }}
                    className={styles.calendar}
                    style={{
                      width: '445px',
                      height: '270px',
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                    }}
                  />
                </div>
              </div>
              <div className={styles.btnBox}>
                <button className={styles.btn} onClick={handleSave}>
                  שמור
                </button>

                <p className={styles.err}>{error}</p>
              </div>
              {/* </div> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

Calendar.propTypes = {
  onChange: PropTypes.func,
};

export default Calendar;
