import { create } from 'zustand';

interface User {
  isLoading?: boolean;
  isAuthenticated?: boolean;
  error?: string;
  data?: {
    id?: string;
    name?: string;
    email?: string;
    role?: string;
    createdAt?: string;
  };
  setUser?: (newVal: User) => void;
  updateuser?: (newVal: User) => void;
  flushUser?: () => void;
}

const userStore = create<User>((set) => ({
  isLoading: true,
  isAuthenticated: false,
  error: '',
  data: {},
  setUser: (newVal) => {
    set((state) => ({ ...state, ...newVal }));
  },

  updateuser: (newVal) => {
    set((state) => ({ ...state, ...newVal }));
  },

  flushUser: () => {
    set((state) => ({
      ...state,
      isLoading: false,
      isAuthenticated: false,
      error: '',
      data: {},
    }));
  },
}));

export default userStore;
