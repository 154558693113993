import React from 'react';
import cx from 'classnames';
import styles from './box.module.scss';

interface Props {
  title: string;
  amount: string | number;
  title2?: string;
  amount2?: string | number;
  ifTwoLines?: boolean;
}

const Box: React.FC<Props> = (props) => {
  const { title, amount, ifTwoLines, amount2, title2 } = props;
  return (
    <div className={styles.container}>
      <p className={styles.amount}>{amount}</p>
      <p className={styles.title}>{title}</p>
      {ifTwoLines && (
        <>
          <p className={cx(styles.amount, styles.amount2)}>{amount2}</p>
          <p className={cx(styles.title, styles.title2)}>{title2}</p>
        </>
      )}
    </div>
  );
};

export default Box;
