import { createSlice } from '@reduxjs/toolkit';
import { updateUserRow } from '../leads/leads.helpers';

const INITIAL_STATE = {
  isLoading: false,
  userData: [],
};

const userMangementSlice = createSlice({
  name: 'userManagement',
  initialState: INITIAL_STATE,

  reducers: {
    addManagementDataStart: (state) => ({
      ...state,
      isLoading: true,
    }),

    addManagementData: (state, action) => {
      const { payload } = action;
      return {
        isLoading: false,
        userData: payload,
      };
    },

    updateManagementDataStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    updateManagementDataSuccess: (state, payload) => ({
      ...state,
      userData: updateUserRow(state.userData, payload),

      isLoading: false,
    }),
    updateManagementDataFail: (state) => ({
      ...state,
      isLoading: false,
      error: 'retry',
    }),

    deleteManagementDataStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    deleteManagementDataSuccess: (state, action) => {
      const { payload } = action;

      return {
        ...state,
        userData: payload,
        isLoading: false,
      };
    },

    deleteManagementDataFail: (state) => ({
      ...state,
      isLoading: false,
      error: 'retry',
    }),
  },
});

export const {
  addManagementData,
  addManagementDataStart,
  updateManagementDataStart,
  updateManagementDataSuccess,
  deleteManagementDataStart,
  deleteManagementDataSuccess,
  deleteManagementDataFail,
} = userMangementSlice.actions;

const userManagementReducer = userMangementSlice.reducer;

export default userManagementReducer;
