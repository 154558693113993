import React from 'react';
import { BASE_URL } from '../../axios';

import hotPic from '../../assests/logo.png';

import styles from './userName.module.scss';

interface Props {
  name?: string;
  communication?: string;
  leadSource?: string;
  imageSrc?: string;
}

const UserName: React.FC<Props> = (props) => {
  const { name, communication, leadSource, imageSrc } = props;

  return (
    <div className={styles.userName}>
      {imageSrc ? (
        <div className={styles.image}>
          <img
            src={`${BASE_URL}/v1/photos/leads/${imageSrc}`}
            alt="is Loading"
            crossOrigin="anonymous"
            style={{ height: 28, width: 28, borderRadius: '50%', overflow: 'hidden' }}
          />
        </div>
      ) : (
        <img src={hotPic} alt="notFound" style={{ height: 28, width: 28, borderRadius: '50%', overflow: 'hidden' }} />
      )}

      <div className={styles.nameContainer}>
        <div className={styles.nameWrap}>
          <p className={styles.name}>{name}</p>
          <p className={styles.dot}> </p>
          <p className={styles.communication}>{communication}</p>
        </div>

        <p className={styles.leadSource}>{leadSource}</p>
      </div>
    </div>
  );
};

UserName.defaultProps = {
  name: 'הוט',
  communication: 'תקשורת',
  leadSource: 'אינטרנג 5 ג׳יגה',
};

export default UserName;
