import { create } from 'zustand';
import { Payments } from '../constants/types.constant';

interface PAYMENTS {
  payments: Array<Payments>;
  total_balance?: number;
  total_payed?: number;
  isLoading?: boolean;

  setPaymentData: (data: Array<Payments>) => void;
}

const paymentTransferStore = create<PAYMENTS>((set) => ({
  payments: [],

  setPaymentData: (data) => {
    set((state) => ({
      ...state,
      payments: data,
      // total_balance: data?.total_balance,
      // total_payed: data?.total_payed,
    }));
  },
}));

export default paymentTransferStore;
