import { call, put, takeLatest } from 'redux-saga/effects';

import { userMangementServices } from './userManagement.services';
import { addManagementData, deleteManagementDataSuccess, updateManagementDataSuccess } from './userManagement.slice';

export function* addManagementDataRequestStart({ payload }) {
  try {
    const response = yield call(userMangementServices.getUserManagementData);
    // console.log(response, 'adf');

    yield put(addManagementData(response.data.users));
  } catch (err) {
    // console.log(err);
  }
}

export function* updateManagementDataRequestStart({ payload }) {
  try {
    yield call(userMangementServices.updateUserManagementData, payload.updatedData);
    // console.log(response, 'adf');
    yield put(updateManagementDataSuccess(payload.updatedValue));
  } catch (err) {
    // console.log(err);
  }
}

export function* deleteManagementDataRequestStart({ payload }) {
  try {
    // const response = yield call(userMangementServices.deleteUserManagementData, payload);
    // console.log(response, 'adf');

    yield put(deleteManagementDataSuccess(payload.updatedData));
  } catch (err) {
    // console.log(err);
  }
}

const userManagementSagas = [
  takeLatest('userManagement/addManagementDataStart', addManagementDataRequestStart),
  takeLatest('userManagement/updateManagementDataStart', updateManagementDataRequestStart),
  takeLatest('userManagement/deleteManagementDataStart', deleteManagementDataRequestStart),
];

export default userManagementSagas;
