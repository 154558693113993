import React, { memo, useEffect, useLayoutEffect, useState } from 'react';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';

import styles from './userStatus.module.scss';
import { set } from 'lodash';

interface Props {
  id: string;
  active?: boolean;
  disabled?: boolean;
}

const LeadToggle: React.FC<Props> = (props) => {
  const { id, active, disabled } = props;
  const [ischecked, setIsChecked] = useState(false);
  //

  useLayoutEffect(() => {
    const rawData = localStorage.getItem('leadEnabledDisabledData');
    const data = rawData ? JSON.parse(rawData) : {};
    const active = data?.[id] || false;
    setIsChecked(active);
  }, [id]);

  useEffect(() => {
    if (typeof active === 'boolean') setIsChecked(active);
  }, [active]);

  const handleToggle = async () => {
    if (disabled) return;
    setIsChecked(!ischecked);
    const rawData = localStorage.getItem('leadEnabledDisabledData');
    const data = rawData ? JSON.parse(rawData) : {};
    const newData = set(data, id, !ischecked);
    localStorage.setItem('leadEnabledDisabledData', JSON.stringify(newData));
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '.MuiSwitch-thumb': {
        color: '#7B8795',
      },
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        transition: 'transform 500ms',
        '.MuiSwitch-thumb': {
          color: '#009EAD',
        },
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C5CE',
          opacity: 0.52,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#000000',
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 1000,
      }),
    },
  }));

  // onChange={handleClick} inputProps={{ 'aria-label': 'controlled' }} checked={ischecked}

  return (
    <div className={styles.switch}>
      <IOSSwitch sx={{ m: 1 }} checked={ischecked} onChange={handleToggle} />
    </div>
  );
};

LeadToggle.defaultProps = {
  id: '',
};

export default memo(LeadToggle);
