import React from 'react';
import { numberWithCommas } from '../../helpers/generalHelpers';

import styles from './leadManagementInfo.module.scss';

interface Props {
  number?: number;
  info?: string;
}

const LeadManagementInfo: React.FC<Props> = (Props) => {
  const { number, info } = Props;

  return (
    <div className={styles.leadManagementInfo}>
      <p className={styles.number}>{numberWithCommas(number)}</p>
      <p className={styles.info}>{info}</p>
    </div>
  );
};

export default LeadManagementInfo;
