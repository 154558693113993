import React from 'react';
import { get, map } from 'lodash';

import ReactApexChart from 'react-apexcharts';

import useDashboardStore from '../../zustand/dashboard.store';

import styles from './thirdGraph.module.scss';
import { chartInfo } from '../../helpers/dashboard.helpers';
import { numberWithCommas } from '../../helpers/generalHelpers';

const ThirdGraph = () => {
  const dashboardData = useDashboardStore((state) => state.dashboardData);

  const formattedGraphData = map(dashboardData?.users, (item, index) => ({
    x: index,
    y: item.count,
  }));

  const charGraph = chartInfo(formattedGraphData);

  return (
    <div className={styles.thirGraph}>
      <p className={styles.total}>סה״כ משתמשים</p>
      <p className={styles.number}>{numberWithCommas(get(dashboardData, 'totalUsersCount', 0))}</p>
      <div className={styles.chart}>
        <ReactApexChart options={charGraph.options} series={charGraph.series} type="area" height={170} />
      </div>
    </div>
  );
};

export default ThirdGraph;
