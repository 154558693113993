import React from 'react';

import { useNavigate } from 'react-router-dom';
import ReactChart from 'react-apexcharts';
import { get } from 'lodash';

import { ROUTES } from '../../constants/routes.constants';
import { ICONS } from '../../constants/icons.constanst';
import useDashboardStore from '../../zustand/dashboard.store';
import Icon from '../icon';

import styles from './circulerGraph2.module.scss';
import { numberWithCommas } from '../../helpers/generalHelpers';

const CirculerGraph2 = (props) => {
  const navigate = useNavigate();
  const dashboardData = useDashboardStore((state) => state.dashboardData);
  const navigateLead = () => {
    navigate(ROUTES.LEAD_MANAGEMENT);
  };

  const trackWidth =
    (get(dashboardData, 'leads.activeCount', 0) * 100) /
    (get(dashboardData, 'leads.activeCount', 0) + get(dashboardData, 'leads.inactiveCount', 0));

  const chartInfo = {
    series: [trackWidth],
    options: {
      chart: {
        type: 'radialBar',
      },
      stroke: {
        show: false,
      },

      colors: ['#7B8795'],

      plotOptions: {
        radialBar: {
          hollow: {
            startAngle: 100,
            endAngle: 360,
            size: '45%',
            margin: 0,
          },
          dataLabels: {
            show: false,
          },
          track: {
            show: true,
            // startAngle: 0,
            // endAngle: 360,
            background: '#CBD2D9',
            strokeWidth: '40%',
            opacity: 1,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
          },
        },
      },

      labels: [''],
    },
  };

  return (
    <div className={styles.chart}>
      <div className={styles.heading}>
        <p className={styles.payments}>לידים</p>
        <div className={styles.iconContainer} onClick={navigateLead}>
          <p className={styles.leads}>לידים</p>
          <Icon type={ICONS.LEFTARROW} />
        </div>
      </div>

      <div className={styles.graphWrapper}>
        <div className={styles.graphContainer}>
          <ReactChart options={chartInfo.options} series={chartInfo.series} type="radialBar" height={232} width={232} />
        </div>
        <div className={styles.graphInfo}>
          <p>
            {numberWithCommas(
              get(dashboardData, 'leads.activeCount', 0) + get(dashboardData, 'leads.inactiveCount', 0),
            )}
          </p>
        </div>
      </div>

      <div className={styles.list}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.dot}></div>
          <p className={styles.totalPayments}>{numberWithCommas(get(dashboardData, 'leads.activeCount', 0))} פעילים</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.dot} style={{ background: '#CBD2D9' }}></div>
          <p className={styles.totalPayments}>
            {numberWithCommas(get(dashboardData, 'leads.inactiveCount', 0))} לא פעילים{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CirculerGraph2;
