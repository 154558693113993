import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import Icon from '../../components/icon';
import Eye from '../../components/icon/Eye';
import Input from '../../components/input/Input';
import CloseEye from '../../components/icon/CloseEye';

import { ICONS } from '../../constants/icons.constanst';
import { ROUTES } from '../../constants/routes.constants';

import userStore from '../../zustand/user.store';
import { fecthUserInfo, loginAction } from '../../actions/user.actions';

import styles from './login.module.scss';
import { TOKENS } from '../../constants/general.constants';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [toggleHidePassword, setToggleHidePassword] = useState(false);
  const [error, setError] = useState('');
  const [shake, setShake] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // const navigate = useNavigate();
  const { setUser } = userStore((state) => state);

  useEffect(() => {
    const refresh_token = localStorage.getItem(TOKENS.REFRESH_TOKEN);

    if (refresh_token) {
      return;
    }
  }, []);

  const handleError = (error) => {
    setError(error);
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 300);
  };

  const handleLoginClick = async () => {
    setError('');
    const { canMove, error } = validateLogin();
    if (!canMove) {
      handleError(error);
      return;
    }
    setIsLoading(true);
    const isLoginSuccess = await loginAction({ payload: { email, password } });

    if (isLoginSuccess) {
      const userData = await fecthUserInfo();
      setUser({
        isAuthenticated: true,
        data: {
          id: userData.id,
          email: userData.email,
          name: userData.name,
          role: userData.company_role,
          createdAt: userData.created_at,
        },
      });
      navigate(ROUTES.HOMEPAGE);
    } else {
      handleError('האימייל או הסיסמה אינם נכונים');
    }
    setIsLoading(false);
    return;
  };

  const handleHideIconClick = () => {
    setToggleHidePassword(!toggleHidePassword);
  };

  const validateLogin = () => {
    // return true;
    const validEmail =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validPassword = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

    if (email.match(validEmail) && password.match(validPassword)) {
      return { canMove: true };
    } else {
      return { canMove: false, error: 'אימייל ו/או סיסמה שגויים' };
    }
    // return true;
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <h1 className={styles.headText}>
          <Icon type={ICONS.LOGO} />
        </h1>

        <div className={styles.mainBox}>
          <div className={styles.icon}>
            <Icon type={ICONS.MAN} />
          </div>

          <div className={styles.form}>
            <h1 className={styles.header}>כניסה</h1>
            <h2 className={styles.subHead}>באמצעות אימייל וסיסמה</h2>

            <div className={styles.inputBox}>
              <Input
                value={email}
                setValue={setEmail}
                placeholder="אימייל"
                labelColor="#f6f7fb"
                labelColorFocused="#f6f7fb"
              />
              <Input
                value={password}
                type={!toggleHidePassword ? 'password' : 'input'}
                setValue={setPassword}
                placeholder="סיסמא"
                className={styles.input}
                endIcon={toggleHidePassword ? <CloseEye /> : <Eye />}
                handleIconClick={handleHideIconClick}
                onEnterPress={handleLoginClick}
                labelColor="#f6f7fb"
                labelColorFocused="#f6f7fb"
              />
            </div>
            {/* <p className={styles.forgetPassword} onClick={handleForgetPassword}>
              איפוס סיסמה
            </p> */}
            {error && <p className={cx(styles.error, { [styles.shake]: shake })}>{error}</p>}
            <div className={cx(styles.btn, { [styles.btn2]: error })}>
              <Button onClick={handleLoginClick} disabled={email === '' || password === ''} loading={isLoading}>
                המשך
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <Loader isLoading={isLoading} /> */}
    </div>
  );
};

export default Login;
