import * as React from 'react';

const Calendar = (props) => (
  <svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={4.646} y={10.3318} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={8.53613} y={10.3318} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={12.4263} y={10.3318} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={16.3145} y={10.3318} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={4.646} y={13.4917} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={8.53613} y={13.4917} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={12.4263} y={13.4917} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={16.3145} y={13.4917} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={4.646} y={16.6519} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={8.53613} y={16.6519} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={12.4263} y={16.6519} width={2.55249} height={1.82321} fill="#7B8795" />
    <rect x={16.3145} y={16.6519} width={2.55249} height={1.82321} fill="#7B8795" />
    <path
      // eslint-disable-next-line max-len
      d="M19.7791 2.82601H3.55249V3.52214H19.7791V2.82601ZM22.1796 5.22656C22.1796 3.90077 21.1048 2.82601 19.7791 2.82601V3.52214C20.7204 3.52214 21.4835 4.28523 21.4835 5.22656H22.1796ZM22.1796 19.4476V5.22656H21.4835V19.4476H22.1796ZM19.7791 21.8482C21.1048 21.8482 22.1796 20.7734 22.1796 19.4476H21.4835C21.4835 20.3889 20.7204 21.152 19.7791 21.152V21.8482ZM3.55249 21.8482H19.7791V21.152H3.55249V21.8482ZM1.15193 19.4476C1.15193 20.7734 2.2267 21.8482 3.55249 21.8482V21.152C2.61116 21.152 1.84807 20.3889 1.84807 19.4476H1.15193ZM1.15193 5.22655V19.4476H1.84807V5.22655H1.15193ZM3.55249 2.82601C2.2267 2.82601 1.15193 3.90077 1.15193 5.22655H1.84807C1.84807 4.28523 2.61116 3.52214 3.55249 3.52214V2.82601ZM3.55249 2.32601C1.95056 2.32601 0.651934 3.62462 0.651934 5.22655H1.34807C1.34807 4.00909 2.33502 3.02214 3.55249 3.02214V2.32601ZM19.7791 2.32601H3.55249V3.02214H19.7791V2.32601ZM22.6796 5.22656C22.6796 3.62463 21.381 2.32601 19.7791 2.32601V3.02214C20.9965 3.02214 21.9835 4.00909 21.9835 5.22656H22.6796ZM22.6796 19.4476V5.22656H21.9835V19.4476H22.6796ZM19.7791 22.3482C21.381 22.3482 22.6796 21.0495 22.6796 19.4476H21.9835C21.9835 20.6651 20.9965 21.652 19.7791 21.652V22.3482ZM3.55249 22.3482H19.7791V21.652H3.55249V22.3482ZM0.651934 19.4476C0.651934 21.0495 1.95056 22.3482 3.55249 22.3482V21.652C2.33502 21.652 1.34807 20.6651 1.34807 19.4476H0.651934ZM0.651934 5.22655V19.4476H1.34807V5.22655H0.651934Z"
      fill="#7B8795"
    />
    <rect
      x={5.54191}
      y={0.348066}
      width={2.4641}
      height={5.13814}
      rx={1.23205}
      fill="#7B8795"
      stroke="#7B8795"
      strokeWidth={0.696133}
    />
    <rect
      x={15.3261}
      y={0.348066}
      width={2.4641}
      height={5.13814}
      rx={1.23205}
      fill="#7B8795"
      stroke="#7B8795"
      strokeWidth={0.696133}
    />
  </svg>
);
export default Calendar;
