/* eslint-disable max-len */
import * as React from 'react';

const DropDownIconSvg = (props) => (
  <div className="MuiSvgIcon-root" {...props} style={{ width: 24, height: 24 }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62013 8.60694C5.91376 8.33105 6.38984 8.33105 6.68347 8.60694L11.9999 13.6019L17.3163 8.60694C17.61 8.33105 18.086 8.33105 18.3797 8.60694C18.6733 8.88282 18.6733 9.33011 18.3797 9.60599L11.9999 15.6L5.62013 9.60599C5.32649 9.33011 5.32649 8.88282 5.62013 8.60694Z"
        fill="#7D7D7D"
      />
    </svg>
  </div>
);

export default DropDownIconSvg;
