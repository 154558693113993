import React, { useEffect } from 'react';

import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';
import { ROUTES } from './constants/routes.constants.js';
import Login from './pages/login/Login';
import UserManagement from './pages/userManagement/UserManagement';
import Dashboard from './pages/dashboard/Dashboard';
import LeadManagement from './pages/leadManagement/LeadManagement';
import PaymentTransfer from './pages/paymentTransfer/PaymentTransfer';
import PhoneVerification from './pages/phoneVerification/PhoneVerification';
import Report from './pages/reports/Reports';

import Loader from './components/loader/Loader';
import AddNewLead from './components/AddNewLead';

import userStore from './zustand/user.store';
import { TOKENS } from './constants/general.constants';
import { fecthUserInfo, refreshToken } from './actions/user.actions';
import PrivateRoute from './PrivateRoute';
import LoginPreventRoute from './LoginPreventRoute';
import Snackbar from './components/snackbar/Snackbar';

const App = () => {
  const { isLoading, updateuser, setUser, isAuthenticated } = userStore((state) => state);

  useEffect(() => {
    (async () => {
      const refresh_token = localStorage.getItem(TOKENS.REFRESH_TOKEN);
      if (refresh_token) {
        updateuser({ isLoading: true });
        const isSuccess = await refreshToken(refresh_token);
        if (isSuccess) {
          const userData = await fecthUserInfo();
          setUser({
            isAuthenticated: true,
            isLoading: false,
            data: {
              id: userData.id,
              name: userData.name,
              email: userData.email,
              role: userData.company_role,
              createdAt: userData.created_at,
            },
          });
        }
        updateuser({ isLoading: false });
      } else {
        updateuser({ isLoading: false });
      }
    })();
  }, []);

  return isLoading ? (
    <Loader isLoading={true} />
  ) : (
    <>
      <Router>
        <Routes>
          <Route path={ROUTES.PHONE_VERIFICATION} element={<PhoneVerification />} />

          <Route path="/" element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route path={ROUTES.HOMEPAGE} element={<Dashboard />} />
            <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagement />} />
            <Route path={ROUTES.LEAD_MANAGEMENT} element={<LeadManagement />} />
            <Route path={ROUTES.PAYMENT_TRANSFER} element={<PaymentTransfer />} />
            <Route path={ROUTES.REPORTS} element={<Report />} />
          </Route>

          <Route path="/" element={<LoginPreventRoute isAuthenticated={isAuthenticated} />}>
            <Route path={ROUTES.LOGIN} element={<Login />} />
          </Route>
        </Routes>
      </Router>
      <AddNewLead />
      <Snackbar />
    </>
  );
};
export default App;
