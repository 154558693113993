import React from 'react';
import cx from 'classnames';

import Button from '../button/Button';
import Icon from '../icon';
import PopUpWrapper from '../popUpWrapper/PopUpWrapper';

import { ICONS } from '../../constants/icons.constanst';

import styles from './errorPopUp.module.scss';

interface Props {
  icon?: string;
  title?: string;
  titleStyle?: string;
  description?: string;
  visible?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  hasPrimaryButton?: boolean;
  hasSecondaryButton?: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  primaryButtonClassName?: string;
  secondaryButtonClassName?: string;
  handlePrimaryClick?: () => void;
  handleSecondaryClick?: () => void;
  handleTransferPayment?: () => void;
  setOpenPaymentTransferPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}

const ErrorPopUp: React.FC<Props> = (props) => {
  const {
    icon,
    title,
    description,
    visible,
    setVisible,
    titleStyle,
    hasPrimaryButton,
    primaryButtonClassName,
    primaryButtonLabel,
    handlePrimaryClick,
    handleSecondaryClick,
    setOpenPaymentTransferPopUp,
    hasSecondaryButton,
    secondaryButtonClassName,
    secondaryButtonLabel,
    isLoading,
  } = props;

  const handlePrimarybtnClick = () => {
    if (handlePrimaryClick) handlePrimaryClick();
    setVisible(false);
    if (setOpenPaymentTransferPopUp) setOpenPaymentTransferPopUp(true);
  };

  const handleSecondarybtnClick = () => {
    if (handleSecondaryClick) handleSecondaryClick();

    setVisible(false);
  };

  return (
    <PopUpWrapper isVisible={visible} setIsVisible={setVisible}>
      <div>
        <div className={styles.icon}>
          <Icon type={icon} />
        </div>
        <p className={cx(styles.title, titleStyle)}>{title}</p>
        <p className={styles.description}>{description}</p>
        <div className={styles.button}>
          {hasSecondaryButton && (
            <Button
              disabled={isLoading}
              className={cx(styles.cancel, secondaryButtonClassName)}
              onClick={handleSecondarybtnClick}
            >
              <p style={{ color: 'black' }}>{secondaryButtonLabel}</p>
            </Button>
          )}
          {hasPrimaryButton && (
            <Button
              loading={isLoading}
              className={cx(styles.tryAgain, primaryButtonClassName)}
              onClick={handlePrimarybtnClick}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </div>
      </div>
    </PopUpWrapper>
  );
};

ErrorPopUp.defaultProps = {
  icon: ICONS.ERROR,
  title: 'קרתה תקלה...',
  description: 'הליד לא פורסם\n נסה שנית',
};

export default ErrorPopUp;
