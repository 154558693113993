import React, { useEffect, useRef, useState } from 'react';

import axios from '../../axios';

import { Navbar } from '../../components';
import ActivityGraph from '../../components/activityGraph/ActivityGraph';
import CirculerGraph from '../../components/circulerGraph/CirculerGraph';
import CirculerGraph2 from '../../components/circulerGraph2/CirculerGraph2';
import FifthGraph from '../../components/fifthGraph/FifthGraph';
import ThirdGraph from '../../components/thirdGraph/ThirdGraph';
import UpperLayer from '../../components/upperLayer/UpperLayer';
import Loader from '../../components/loader/Loader';
import Calendar from './calendar';

import useDashboardStore from '../../zustand/dashboard.store';
import { getFormattedCurrentTime } from '../../helpers/generalHelpers';

import styles from './dashboard.module.scss';
import { getDate } from '../../helpers/dashboard.helpers';

const Dashboard = () => {
  const { setDashboardData: addDashboardData, setLeadData } = useDashboardStore((state) => state);
  const [startDate, setStartDate] = useState(new Date(2023, 2, 9));
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState('');

  const isDataFetched = useRef(false);

  useEffect(() => {
    (async () => {
      if (isDataFetched.current) return;
      isDataFetched.current = true;
      setIsLoading(true);
      setDate(getFormattedCurrentTime());

      try {
        const response = await axios.get('/v1/admin/dashboard', {
          params: {
            start: startDate.getTime() / 1000,
            end: new Date().getTime() / 1000,
          },
        });

        addDashboardData(response?.data);
      } catch (err) {
        // console.log(err, 'error');
      }
      setIsLoading(false);
    })();
  }, []);

  const dashboardDataFetch = async (start, end) => {
    setIsLoading(true);
    setDate(getFormattedCurrentTime());
    setStartDate(new Date(start));
    setEndDate(new Date(end));

    try {
      const response = await axios.get('/v1/admin/dashboard', {
        params: {
          start: new Date(start).getTime() / 1000,
          end: new Date(end).getTime() / 1000,
        },
      });

      addDashboardData(response?.data);
    } catch (err) {
      // console.log(err, 'error');
    }
    setIsLoading(false);
  };

  const getLeadData = async (leadId) => {
    setIsLoading(true);

    try {
      const response = await axios.get('/v1/admin/dashboard', {
        params: {
          start: new Date(startDate).getTime() / 1000,
          end: new Date(endDate).getTime() / 1000,
          lead: leadId,
        },
      });

      setLeadData(response?.data);
    } catch (err) {
      // console.log(err, 'error');
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.dashboardContainer}>
      <Loader isLoading={isLoading} />
      <div style={{ width: '90px' }}>
        <Navbar />
      </div>

      <div className={styles.leftSide}>
        <div className={styles.dashboard}>
          <UpperLayer heading="דשבורד" asOfDate="נכון לתאריך" date={date} updated={styles.update} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingLeft: '50px' }}>
            <Calendar startDate={startDate} setStartDate={setStartDate} hadnleSaveClick={dashboardDataFetch} />
          </div>
        </div>

        <div className={styles.graphContainer}>
          <div className={styles.firstLayer}>
            <ActivityGraph date={getDate(startDate) !== 'NaN/NaN/NaN' ? getDate(startDate) : '-'} />
            <CirculerGraph />
          </div>

          <div className={styles.secondLayer}>
            <ThirdGraph />
            <CirculerGraph2 />
            <FifthGraph getLeadData={getLeadData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
