import { create } from 'zustand';
import { deleteUserRow, updateUserRow } from '../redux/leads/leads.helpers';

const INITIAL_STATE = {
  isLoading: false,
  userData: [],
};

interface USER {
  userManagement: {
    isLoading?: boolean;
    userData?: object;
  };
  setUserManagement: (data: {}) => void;
  searchedData: (data: object) => void;
  flushUserManagementData: () => void;
  addManagementData: (data: object) => void;
  updateManagementDataSuccess: (data: object) => void;
  deleteUserSuccess: (data: string) => void;
}

const useUserManagementStore = create<USER>((set) => ({
  userManagement: INITIAL_STATE,

  setUserManagement: (data) => {
    set((state) => ({ userManagement: { ...state.userManagement, ...data } }));
  },

  searchedData: (data) => {
    set((state) => ({ userManagement: { ...state.userManagement, userData: data } }));
  },

  flushUserManagementData: () => {
    set(() => ({ userManagement: INITIAL_STATE }));
  },

  // addManagementDataStart: () => {
  //   set((state) => ({
  //     ...state,
  //     isLoading: true,
  //   }));
  // },

  addManagementData: (data) => {
    set((state) => ({
      userManagement: {
        ...state.userManagement,
        isLoading: false,
        userData: data,
      },
    }));
  },

  // updateManagementDataStart: () => {
  //   set((state) => ({
  //     ...state,
  //     isLoading: true,
  //   }));
  // },

  deleteUserSuccess: (data) => {
    set((state) => ({
      userManagement: {
        ...state.userManagement,
        isLoading: false,
        userData: deleteUserRow(state.userManagement.userData, data),
      },
    }));
  },

  updateManagementDataSuccess: (data) => {
    set((state) => ({
      userManagement: {
        ...state.userManagement,
        isLoading: false,
        userData: updateUserRow(state.userManagement.userData, data),
      },
    }));
  },

  // updateManagementDataFail: () => {
  //   set((state) => ({
  //     ...state,
  //     isLoading: false,
  //     error: 'retry',
  //   }));
  // },

  // deleteManagementDataStart: () => {
  //   set((state) => ({
  //     ...state,
  //     isLoading: true,
  //   }));
  // },

  deleteManagementDataSuccess: (action) => {
    const { payload } = action;
    set((state) => ({
      ...state,
      userData: payload,
      isLoading: false,
    }));
  },

  // deleteManagementDataFail: (state) => ({
  //   ...state,
  //   isLoading: false,
  //   error: 'retry',
  // }),
}));

export default useUserManagementStore;
