import React, { memo } from 'react';

import { useNavigate } from 'react-router-dom';
import ReactChart from 'react-apexcharts';
import { get } from 'lodash';

import { ROUTES } from '../../constants/routes.constants';
import { ICONS } from '../../constants/icons.constanst';
import Icon from '../icon';
import useDashboardStore from '../../zustand/dashboard.store';

import styles from './circulerGraph.module.scss';
import { numberWithCommas } from '../../helpers/generalHelpers';

const Chart = (props) => {
  const navigate = useNavigate();
  const dashboardData = useDashboardStore((state) => state.dashboardData);
  const trackWidth = (get(dashboardData, 'payments.payed', 0) / get(dashboardData, 'payments.total', 0)) * 100;

  const navigateLead = () => {
    navigate(ROUTES.PAYMENT_TRANSFER);
  };
  const chartInfo = {
    series: [trackWidth],
    options: {
      chart: {
        type: 'radialBar',
      },
      colors: ['#7B8795'],

      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            background: 'transparent',
            size: '75%',
          },
          dataLabels: {
            show: false,
          },

          track: {
            show: false,
          },
        },
      },

      labels: [''],
    },
  };

  return (
    <div className={styles.chart}>
      <div className={styles.heading}>
        <p className={styles.payments}>תשלומים</p>
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={navigateLead}>
          <p className={styles.leads}>ניהול תשלומים</p>
          <Icon type={ICONS.LEFTARROW} />
        </div>
      </div>
      <div className={styles.graphWrapper}>
        <div className={styles.graphContainer}>
          <ReactChart options={chartInfo.options} series={chartInfo.series} type="radialBar" height={202} />
        </div>
        <div className={styles.graphInfo}>
          <p>₪{numberWithCommas(get(dashboardData, 'payments.payed', 0))}</p>
          <p className={styles.text}>מתוך</p>
          <p>₪{numberWithCommas(get(dashboardData, 'payments.total', 0))}</p>
        </div>
      </div>

      <div className={styles.list}>
        <div className={styles.dot}></div>
        <p className={styles.totalPayments}>סה״כ תשלומים שבוצעו </p>
      </div>
    </div>
  );
};

export default memo(Chart);
