import { createSlice } from '@reduxjs/toolkit';
import { deleteRow, updateUserRow } from './leads.helpers';

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  rowData: {},
  error: '',
};

const leadsSlice = createSlice({
  name: 'leads',
  initialState: INITIAL_STATE,
  reducers: {
    // setData: (state, action) => ({
    //   ...state,
    //   isLoading: false,
    //   data: action.payload,
    // }),

    leadsDataFetchStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    leadsDataFetchSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),

    leadsDataFetchFail: (state, action) => ({
      ...state,
      isLoading: false,
      error: 'retry',
    }),

    updateLeadsStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    updateLeadsDataSucccess: (state, action) => ({
      ...state,
      isLoading: false,
      data: updateUserRow(state.data, action.payload),
    }),

    updateLeadsDataFail: (state, action) => ({
      ...state,
      isLoading: false,
      error: 'update failed',
    }),

    deleteLeadStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    deleteLeadSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      data: deleteRow(state.data, action.payload),
    }),

    deleteLeadsFail: (state, action) => ({
      ...state,
      isLoading: false,
      error: 'update failed',
    }),
  },
});

export const {
  setData,
  leadsDataFetchStart,
  leadsDataFetchSuccess,
  leadsDataFetchFail,
  updateLeadsStart,
  updateLeadsDataSucccess,
  updateLeadsDataFail,
  deleteLeadStart,
  deleteLeadSuccess,
  deleteLeadsFail,
} = leadsSlice.actions;

const leadsReducer = leadsSlice.reducer;

export default leadsReducer;

// Old Redux Approach

// const ACTION_TYPES = {
//   DUMMY_ACTION: 'DUMMY_ACTION',
// };

// const dummyAction = (payload) => ({
//   type: ACTION_TYPES.DUMMY_ACTION,
//   payload,
// });

// const reducer = (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case ACTION_TYPES.DUMMY_ACTION:
//       return {
//         ...state,
//         leads: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// const arr = ['first',"second","third","fourth","fifth"];
// const res = map(arr, (item, index) => {
//   return item + index
// })

// res = ['first0', 'second1']
