/* eslint-disable max-len */
import * as React from 'react';

const PaymentSuccess = (props) => (
  <svg width={187} height={109} viewBox="0 0 187 109" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={95} cy={77} r={32} fill="#7B8795" />
    <path
      d="M85.3989 78.2129L92.5545 85.9602L107.479 71.2402"
      stroke="white"
      strokeWidth={2.56}
      strokeLinecap="round"
    />
    <path
      d="M186.166 55.4717C186.166 55.4717 187.14 45.6823 165.386 35L155.223 48.7506C155.223 48.7506 166.539 53.2151 174.98 63.3291L182.724 60.2933L186.166 55.4717Z"
      fill="#323E4A"
    />
    <path
      d="M178.795 56.6071L170.97 70.4064C170.97 70.4064 172.025 71.624 175.905 69.8869L186.149 55.4707C186.166 55.4707 183.487 59.302 178.795 56.6071Z"
      fill="#52606D"
    />
    <path
      d="M15.5286 63.8795C15.5286 63.8795 19.3256 82.7602 40.1606 79.8498L35.4911 62.3776C35.4911 62.3776 24.4011 73.5386 16.0159 56.7185L14.2699 58.2851L15.5286 63.8795Z"
      fill="#323E4A"
    />
    <path
      d="M15.5286 63.879C13.6056 56.3138 19.4953 52.3263 19.4953 52.3263L15.1245 38.2031C12.5379 45.2624 12.3019 52.9621 14.4254 60.1631L15.5286 63.879Z"
      fill="#52606D"
    />
    <path
      d="M122.677 22.2999C122.677 22.2999 127.568 27.1381 136.202 19.8809C144.837 12.6237 154.266 10.1516 159.563 12.8709L143.901 2.41768C143.901 2.41768 134.913 -0.142648 124.849 7.41472C123.771 8.22696 122.659 8.98624 121.458 9.60425C119.534 10.5754 116.761 11.7231 115.296 11.2111L119.94 20.5871L122.677 22.2999Z"
      fill="#323E4A"
    />
    <path
      d="M120.328 14.2489L105.461 5.34961C105.461 5.34961 104.19 10.3467 107.527 12.9776L122.659 22.3007C119.287 19.811 120.311 14.4961 120.311 14.4961"
      fill="#52606D"
    />
    <path
      d="M136.013 41.1101C134.909 41.7191 134.223 43.0631 134.223 44.9866C134.223 48.7617 136.865 53.3372 140.124 55.2063C141.748 56.1378 143.218 56.2211 144.285 55.6031C144.282 55.6051 144.279 55.6073 144.275 55.6093L147.312 53.8673C148.392 53.2479 149.061 51.913 149.061 50.0133C149.061 46.2382 146.419 41.6627 143.16 39.7936C141.542 38.8651 140.075 38.7792 139.009 39.3906L135.972 41.1326C135.985 41.1248 135.999 41.1177 136.013 41.1101Z"
      fill="#CBD2D9"
    />
    <path
      d="M135.972 41.1326L139.009 39.3906C140.075 38.7792 141.542 38.8651 143.161 39.7936C146.419 41.6628 149.061 46.2382 149.061 50.0133C149.061 51.913 148.392 53.248 147.312 53.8674L144.275 55.6093C145.355 54.99 146.024 53.655 146.024 51.7553C146.024 47.9802 143.383 43.4047 140.124 41.5356C138.505 40.607 137.038 40.5211 135.972 41.1326Z"
      fill="#979797"
    />
    <path
      d="M134.223 44.9869C134.223 41.2118 136.865 39.6667 140.124 41.5358C143.382 43.4049 146.024 47.9804 146.024 51.7555C146.024 55.5306 143.382 57.0757 140.124 55.2066C136.865 53.3374 134.223 48.7619 134.223 44.9869Z"
      fill="#CBD2D9"
    />
    <path
      d="M143.782 46.2268C144.813 48.0109 145.339 49.9296 145.264 51.6294C145.199 53.1095 144.662 54.2477 143.791 54.7521C142.916 55.2589 141.709 55.1636 140.392 54.4837C138.889 53.7075 137.494 52.2978 136.465 50.5145C135.434 48.7304 134.908 46.8117 134.983 45.1119C135.048 43.6318 135.585 42.4936 136.456 41.9893C137.331 41.4824 138.538 41.5777 139.855 42.2576C141.358 43.0339 142.753 44.4435 143.782 46.2268ZM136.62 50.425C137.632 52.179 139.001 53.564 140.474 54.3248C141.735 54.9757 142.881 55.0724 143.701 54.5972C144.518 54.124 145.023 53.0394 145.085 51.6215C145.158 49.9554 144.641 48.0713 143.628 46.3163C142.615 44.5623 141.246 43.1774 139.773 42.4165C138.512 41.7657 137.366 41.6689 136.546 42.1441C135.729 42.6173 135.224 43.7019 135.162 45.1198C135.089 46.786 135.606 48.6701 136.62 50.425Z"
      fill="#979797"
    />
    <path
      d="M144.292 41.0931L141.634 42.6278C141.694 42.6808 141.754 42.7348 141.814 42.7896L144.843 41.0406C144.783 40.9859 144.723 40.9319 144.663 40.8789L144.292 41.0931Z"
      fill="#52606D"
    />
    <path
      d="M147.014 43.664C146.973 43.5992 146.931 43.5348 146.889 43.4707L143.857 45.2209C143.9 45.285 143.941 45.3494 143.983 45.4143L147.014 43.664Z"
      fill="#52606D"
    />
    <path
      d="M141.474 39.1107C141.358 39.0839 141.242 39.0618 141.128 39.0449L138.107 40.789C138.221 40.8062 138.335 40.8289 138.452 40.8558L141.474 39.1107Z"
      fill="#52606D"
    />
    <path
      d="M140.146 41.5485C140.222 41.5922 140.297 41.6374 140.372 41.6839L143.399 39.9361C143.324 39.8897 143.249 39.8443 143.174 39.8008L140.146 41.5485Z"
      fill="#52606D"
    />
    <path
      d="M142.846 43.8668C142.896 43.926 142.946 43.9858 142.995 44.0461L146.026 42.2964C145.976 42.2361 145.927 42.1763 145.876 42.1172L142.846 43.8668Z"
      fill="#52606D"
    />
    <path
      d="M145.362 48.228C145.388 48.3016 145.414 48.3753 145.439 48.4491L148.472 46.6977C148.448 46.6239 148.422 46.5502 148.395 46.4766L145.362 48.228Z"
      fill="#52606D"
    />
    <path
      d="M144.696 46.6747C144.73 46.7434 144.764 46.8124 144.798 46.8815L147.83 45.1306C147.797 45.0615 147.763 44.9925 147.728 44.9238L144.696 46.6747Z"
      fill="#52606D"
    />
    <path
      d="M148.578 52.4483C148.631 52.3363 148.68 52.2193 148.725 52.0977L145.683 53.8542C145.637 53.9764 145.587 54.0938 145.533 54.2062L148.578 52.4483Z"
      fill="#52606D"
    />
    <path
      d="M145.829 49.8947C145.845 49.9746 145.861 50.0544 145.875 50.134L148.91 48.3816C148.896 48.3021 148.88 48.2224 148.864 48.1426L145.829 49.8947Z"
      fill="#52606D"
    />
    <path
      d="M146.021 51.9877L149.058 50.2341C149.06 50.1613 149.061 50.0879 149.061 50.0132C149.061 49.9978 149.061 49.9822 149.061 49.9668L146.024 51.7201C146.024 51.7318 146.024 51.7435 146.024 51.7552C146.024 51.8338 146.023 51.9111 146.021 51.9877Z"
      fill="#52606D"
    />
    <path
      d="M153.349 83.6014C154.413 84.2763 155.922 84.2314 157.608 83.3064C160.918 81.4908 163.659 76.9741 163.731 73.2181C163.766 71.3461 163.132 70.0166 162.077 69.3784C162.08 69.3805 162.084 69.3823 162.087 69.3844L159.099 67.5595C158.037 66.9106 156.545 66.9661 154.879 67.8798C151.569 69.6954 148.828 74.212 148.757 77.968C148.721 79.8339 149.351 81.1611 150.4 81.8017L153.388 83.6266C153.375 83.6185 153.362 83.6098 153.349 83.6014Z"
      fill="#CBD2D9"
    />
    <path
      d="M153.388 83.6267L150.4 81.8018C149.351 81.1612 148.721 79.834 148.757 77.9681C148.828 74.212 151.569 69.6954 154.879 67.8798C156.545 66.9662 158.037 66.9106 159.099 67.5595L162.087 69.3844C161.025 68.7355 159.532 68.791 157.867 69.7046C154.557 71.5202 151.816 76.0369 151.744 79.7929C151.709 81.6589 152.339 82.9861 153.388 83.6267Z"
      fill="#979797"
    />
    <path
      d="M157.608 83.3062C154.298 85.1218 151.673 83.5488 151.745 79.7928C151.816 76.0367 154.557 71.52 157.867 69.7045C161.177 67.8889 163.802 69.4619 163.731 73.2179C163.659 76.974 160.918 81.4906 157.608 83.3062Z"
      fill="#CBD2D9"
    />
    <path
      d="M154.098 74.3286C155.167 72.5674 156.596 71.1834 158.122 70.4314C159.451 69.7766 160.707 69.6999 161.569 70.2211C162.434 70.7447 162.931 71.8488 162.968 73.33C163.01 75.0213 162.445 76.9222 161.377 78.6825C160.308 80.4437 158.879 81.8277 157.353 82.5797C156.024 83.2345 154.767 83.3112 153.906 82.79C153.041 82.2664 152.544 81.1623 152.507 79.6811C152.465 77.9899 153.03 76.089 154.098 74.3286ZM161.224 78.5897C162.275 76.8582 162.83 74.9919 162.789 73.3345C162.754 71.9164 162.287 70.8651 161.476 70.3742C160.668 69.8853 159.474 69.9646 158.201 70.5919C156.705 71.329 155.302 72.689 154.251 74.4214C153.2 76.1529 152.644 78.0192 152.686 79.6767C152.721 81.0947 153.188 82.146 153.999 82.6369C154.807 83.1258 156 83.0465 157.273 82.4192C158.77 81.6821 160.172 80.3221 161.224 78.5897Z"
      fill="#979797"
    />
    <path
      d="M149.352 76.3516L151.976 77.9441C151.993 77.8656 152.012 77.7872 152.031 77.7086L149.041 75.8938C149.021 75.9724 149.003 76.0509 148.986 76.1293L149.352 76.3516Z"
      fill="#52606D"
    />
    <path
      d="M150.295 72.7293C150.258 72.7966 150.222 72.8642 150.186 72.932L153.179 74.7481C153.214 74.6803 153.251 74.6127 153.288 74.5454L150.295 72.7293Z"
      fill="#52606D"
    />
    <path
      d="M148.968 79.7761C149.001 79.8915 149.037 80.0033 149.077 80.1112L152.059 81.921C152.02 81.8135 151.984 81.7018 151.952 81.5869L148.968 79.7761Z"
      fill="#52606D"
    />
    <path
      d="M151.745 79.7669C151.747 79.6797 151.75 79.592 151.755 79.5041L148.767 77.6904C148.762 77.7784 148.758 77.8661 148.757 77.9534L151.745 79.7669Z"
      fill="#52606D"
    />
    <path
      d="M152.478 76.2875C152.506 76.2149 152.534 76.1425 152.564 76.0701L149.572 74.2545C149.543 74.3269 149.514 74.3994 149.487 74.4719L152.478 76.2875Z"
      fill="#52606D"
    />
    <path
      d="M155.092 71.9839C155.144 71.9254 155.196 71.8673 155.249 71.81L152.254 69.9926C152.201 70.0499 152.149 70.108 152.097 70.1665L155.092 71.9839Z"
      fill="#52606D"
    />
    <path
      d="M154.05 73.3142C154.093 73.2509 154.138 73.1881 154.182 73.1255L151.189 71.3087C151.144 71.3713 151.1 71.4341 151.056 71.4975L154.05 73.3142Z"
      fill="#52606D"
    />
    <path
      d="M157.246 67.1326C157.123 67.1398 156.996 67.153 156.868 67.1721L159.871 68.9947C160 68.9759 160.127 68.9633 160.252 68.9566L157.246 67.1326Z"
      fill="#52606D"
    />
    <path
      d="M156.329 70.7706C156.391 70.7181 156.454 70.6663 156.517 70.6155L153.521 68.7972C153.458 68.848 153.396 68.8999 153.333 68.9525L156.329 70.7706Z"
      fill="#52606D"
    />
    <path
      d="M158.072 69.5964L155.074 67.7768C155.009 67.81 154.944 67.8441 154.879 67.88C154.865 67.8874 154.852 67.8953 154.838 67.9028L157.836 69.7221C157.846 69.7164 157.856 69.7105 157.866 69.7049C157.935 69.6671 158.004 69.6313 158.072 69.5964Z"
      fill="#52606D"
    />
    <path
      d="M49.3644 47.9012C50.4643 47.2853 51.142 45.937 51.1299 44.0135C51.1062 40.2385 48.4358 35.6797 45.1654 33.8311C43.5355 32.9098 42.0643 32.8357 41.0013 33.4604C41.0047 33.4584 41.008 33.4562 41.0114 33.4542L37.9854 35.2152C36.9095 35.8413 36.2489 37.1805 36.2609 39.0801C36.2846 42.8551 38.9549 47.4139 42.2253 49.2625C43.85 50.1809 45.3171 50.2576 46.3793 49.6394L49.4053 47.8785C49.3918 47.8863 49.378 47.8935 49.3644 47.9012Z"
      fill="#CBD2D9"
    />
    <path
      d="M49.4051 47.8792L46.3791 49.6402C45.3169 50.2583 43.8498 50.1816 42.2251 49.2633C38.9546 47.4146 36.2843 42.8558 36.2606 39.0808C36.2487 37.1811 36.9092 35.842 37.9852 35.2158L41.0111 33.4549C39.9351 34.081 39.2746 35.4201 39.2865 37.3198C39.3102 41.0948 41.9806 45.6536 45.251 47.5022C46.8757 48.4207 48.3428 48.4973 49.4051 47.8792Z"
      fill="#979797"
    />
    <path
      d="M51.1296 44.0132C51.1533 47.7882 48.5214 49.3499 45.251 47.5012C41.9806 45.6526 39.3102 41.0938 39.2865 37.3188C39.2628 33.5438 41.8947 31.9822 45.1651 33.8308C48.4355 35.6794 51.1059 40.2382 51.1296 44.0132Z"
      fill="#CBD2D9"
    />
    <path
      d="M41.5626 42.8325C40.5213 41.0549 39.9832 39.1395 40.0472 37.4393C40.103 35.9588 40.6327 34.8173 41.5006 34.3074C42.3727 33.7951 43.5803 33.8828 44.9011 34.5544C46.4091 35.3213 47.8128 36.7221 48.8535 38.4989C49.8947 40.2766 50.4329 42.1919 50.3688 43.8921C50.3131 45.3726 49.7833 46.5141 48.9154 47.024C48.0433 47.5363 46.8357 47.4486 45.515 46.777C44.007 46.0101 42.6034 44.6093 41.5626 42.8325ZM48.6991 38.5894C47.6754 36.8418 46.2977 35.4654 44.8199 34.7139C43.5555 34.071 42.4089 33.9814 41.5913 34.4617C40.7771 34.94 40.2795 36.0278 40.226 37.446C40.1633 39.1126 40.6928 40.9934 41.717 42.742C42.7407 44.4896 44.1183 45.866 45.5962 46.6175C46.8606 47.2604 48.0072 47.35 48.8248 46.8697C49.639 46.3914 50.1366 45.3036 50.19 43.8853C50.2528 42.2188 49.7233 40.3379 48.6991 38.5894Z"
      fill="#979797"
    />
    <path
      d="M41.0861 47.9705L43.7346 46.4191C43.674 46.3665 43.6137 46.3128 43.5538 46.2585L40.5356 48.0264C40.5955 48.0807 40.6558 48.1344 40.7165 48.187L41.0861 47.9705Z"
      fill="#52606D"
    />
    <path
      d="M38.3476 45.4183C38.3892 45.4829 38.4314 45.547 38.474 45.6108L41.4944 43.8416C41.4518 43.7778 41.4096 43.7137 41.3681 43.649L38.3476 45.4183Z"
      fill="#52606D"
    />
    <path
      d="M43.9158 49.9359C44.0329 49.9619 44.1485 49.9833 44.2624 49.9994L47.2723 48.2364C47.159 48.2199 47.0439 48.198 46.9275 48.1717L43.9158 49.9359Z"
      fill="#52606D"
    />
    <path
      d="M45.2283 47.4887C45.1525 47.4455 45.0771 47.4007 45.002 47.3546L41.9856 49.1215C42.0608 49.1674 42.1363 49.2123 42.2122 49.2553L45.2283 47.4887Z"
      fill="#52606D"
    />
    <path
      d="M42.5149 45.1879C42.4643 45.1291 42.4141 45.0696 42.3643 45.0096L39.3447 46.7783C39.3945 46.8382 39.4448 46.8977 39.4955 46.9565L42.5149 45.1879Z"
      fill="#52606D"
    />
    <path
      d="M39.9714 40.8443C39.9447 40.7709 39.9184 40.6973 39.8931 40.6237L36.8705 42.3941C36.8959 42.4678 36.9221 42.5413 36.949 42.6148L39.9714 40.8443Z"
      fill="#52606D"
    />
    <path
      d="M40.6467 42.3937C40.6118 42.3252 40.5775 42.2564 40.5437 42.1875L37.5221 43.9574C37.556 44.0263 37.5902 44.0951 37.6252 44.1636L40.6467 42.3937Z"
      fill="#52606D"
    />
    <path
      d="M36.7291 36.643C36.6765 36.7554 36.6281 36.8727 36.5839 36.9947L39.6152 35.2191C39.6601 35.0966 39.7093 34.9789 39.7626 34.8661L36.7291 36.643Z"
      fill="#52606D"
    />
    <path
      d="M39.4939 39.1795C39.4773 39.0998 39.4614 39.0201 39.4468 38.9405L36.4227 40.7119C36.4374 40.7914 36.4534 40.871 36.4701 40.9507L39.4939 39.1795Z"
      fill="#52606D"
    />
    <path
      d="M39.2891 37.0866L36.2628 38.8592C36.2612 38.932 36.2603 39.0055 36.2607 39.0801C36.2608 39.0955 36.2613 39.1111 36.2615 39.1265L39.2873 37.3542C39.2871 37.3425 39.2867 37.3308 39.2867 37.3191C39.2862 37.2405 39.2873 37.1632 39.2891 37.0866Z"
      fill="#52606D"
    />
    <path
      d="M56.0995 2.70654C54.9428 2.20552 53.4597 2.48443 51.9378 3.66054C48.9506 5.96885 46.9454 10.8569 47.459 14.5783C47.715 16.433 48.5483 17.6477 49.6897 18.1141C49.686 18.1126 49.6823 18.1113 49.6787 18.1098L52.914 19.4477C54.0644 19.9234 55.5297 19.6365 57.0329 18.4749C60.02 16.1666 62.0253 11.2786 61.5117 7.55715C61.2565 5.70844 60.4277 4.49542 59.292 4.02574L56.0567 2.68777C56.0711 2.69373 56.0852 2.70035 56.0995 2.70654Z"
      fill="#CBD2D9"
    />
    <path
      d="M56.0567 2.68874L59.292 4.02669C60.4277 4.49637 61.2565 5.70939 61.5117 7.55817C62.0252 11.2797 60.02 16.1676 57.0329 18.476C55.5297 19.6375 54.0643 19.9245 52.914 19.4488L49.6786 18.1108C50.829 18.5866 52.2944 18.2996 53.7976 17.1381C56.7847 14.8298 58.7899 9.94173 58.2763 6.22029C58.0212 4.37149 57.1924 3.15845 56.0567 2.68874Z"
      fill="#979797"
    />
    <path
      d="M51.9377 3.6599C54.9248 1.35159 57.7627 2.49713 58.2762 6.2186C58.7899 9.94004 56.7846 14.8281 53.7975 17.1364C50.8104 19.4447 47.9725 18.2992 47.4589 14.5777C46.9454 10.8562 48.9505 5.9682 51.9377 3.6599Z"
      fill="#CBD2D9"
    />
    <path
      d="M56.8014 11.9824C56.0195 13.8884 54.823 15.4779 53.4323 16.4581C52.2213 17.3117 50.9924 17.5828 50.0607 17.2019C49.1244 16.8192 48.4618 15.8059 48.1948 14.3485C47.8899 12.6844 48.1524 10.7187 48.934 8.81361C49.7159 6.90766 50.9124 5.31817 52.3031 4.33793C53.514 3.48437 54.7429 3.21328 55.6747 3.59416C56.6109 3.97685 57.2736 4.99019 57.5406 6.44759C57.8454 8.11167 57.5829 10.0773 56.8014 11.9824ZM49.0996 8.88154C48.3309 10.7554 48.0721 12.6854 48.3708 14.3162C48.6264 15.7115 49.2506 16.6775 50.1284 17.0363C51.0025 17.3936 52.1691 17.1295 53.3292 16.3119C54.6924 15.351 55.8667 13.7894 56.6358 11.9145C57.4045 10.0407 57.6633 8.11062 57.3646 6.47979C57.1089 5.08454 56.4847 4.11857 55.607 3.75979C54.7329 3.40249 53.5663 3.66653 52.4062 4.4842C51.043 5.44505 49.8687 7.00672 49.0996 8.88154Z"
      fill="#979797"
    />
    <path
      d="M61.1756 9.24606L58.3359 8.08108C58.3308 8.16126 58.3247 8.24167 58.3178 8.32225L61.554 9.64985C61.5608 9.56926 61.5669 9.48878 61.5719 9.40861L61.1756 9.24606Z"
      fill="#52606D"
    />
    <path
      d="M60.807 12.9714C60.8331 12.8992 60.8585 12.8267 60.8834 12.7542L57.6449 11.4256C57.6199 11.4982 57.5945 11.5706 57.5684 11.6429L60.807 12.9714Z"
      fill="#52606D"
    />
    <path
      d="M61.0219 5.80408C60.9716 5.69518 60.9185 5.5903 60.8623 5.48999L57.6351 4.16605C57.6906 4.26612 57.743 4.3709 57.7927 4.47936L61.0219 5.80408Z"
      fill="#52606D"
    />
    <path
      d="M58.2807 6.24443C58.2923 6.33087 58.3024 6.41802 58.3114 6.50566L61.5455 7.8324C61.5364 7.74475 61.5263 7.65756 61.5145 7.57107L58.2807 6.24443Z"
      fill="#52606D"
    />
    <path
      d="M58.097 9.79637C58.0809 9.87235 58.0641 9.9483 58.0466 10.0243L61.2842 11.3525C61.3016 11.2765 61.3184 11.2005 61.3345 11.1245L58.097 9.79637Z"
      fill="#52606D"
    />
    <path
      d="M56.185 14.4537C56.1428 14.5195 56.1002 14.585 56.057 14.6498L59.2978 15.9793C59.341 15.9145 59.3835 15.849 59.4257 15.7832L56.185 14.4537Z"
      fill="#52606D"
    />
    <path
      d="M57.0072 12.9788C56.9739 13.0481 56.94 13.117 56.9057 13.1857L60.1454 14.5148C60.1797 14.4461 60.2136 14.3771 60.2468 14.3078L57.0072 12.9788Z"
      fill="#52606D"
    />
    <path
      d="M54.8108 19.5807C54.932 19.5543 55.0546 19.5216 55.1784 19.4828L51.9282 18.1495C51.8036 18.1881 51.6802 18.2204 51.5581 18.2463L54.8108 19.5807Z"
      fill="#52606D"
    />
    <path
      d="M55.1507 15.8448C55.0973 15.9064 55.0436 15.9673 54.9892 16.0272L58.2317 17.3574C58.286 17.2974 58.3395 17.2364 58.3928 17.1748L55.1507 15.8448Z"
      fill="#52606D"
    />
    <path
      d="M53.6118 17.2748L56.8567 18.6059C56.9155 18.563 56.9745 18.5192 57.0335 18.4736C57.0457 18.4641 57.0578 18.4543 57.07 18.4448L53.8257 17.1139C53.8165 17.1211 53.8074 17.1285 53.7982 17.1356C53.736 17.1837 53.6739 17.2297 53.6118 17.2748Z"
      fill="#52606D"
    />
    <path
      d="M79.2232 21.8889C79.0649 21.8889 78.922 21.7828 78.8612 21.6204C78.0431 19.4258 77.4401 18.7556 75.465 17.8464C75.3188 17.779 75.2231 17.6203 75.2231 17.4444C75.2231 17.2684 75.3188 17.1097 75.465 17.0423C77.4401 16.133 78.043 15.4629 78.8612 13.2685C78.922 13.1058 79.0649 13 79.2232 13C79.3816 13 79.5245 13.106 79.5851 13.2685C80.4031 15.4631 81.0064 16.133 82.9814 17.0423C83.1275 17.1095 83.2231 17.2684 83.2231 17.4444C83.2231 17.6203 83.1277 17.779 82.9814 17.8464C81.0062 18.7556 80.4031 19.4256 79.5851 21.6204C79.5245 21.7828 79.3816 21.8889 79.2232 21.8889Z"
      fill="#CBD2D9"
    />
    <path
      d="M23.2232 13.8889C23.0649 13.8889 22.922 13.7828 22.8612 13.6204C22.0431 11.4258 21.4401 10.7556 19.465 9.84643C19.3188 9.779 19.2231 9.62033 19.2231 9.44444C19.2231 9.26836 19.3188 9.10969 19.465 9.04226C21.4401 8.13304 22.043 7.46293 22.8612 5.26852C22.922 5.10585 23.0649 5 23.2232 5C23.3816 5 23.5245 5.10605 23.5851 5.26852C24.4031 7.46313 25.0064 8.13304 26.9814 9.04226C27.1275 9.10949 27.2231 9.26836 27.2231 9.44444C27.2231 9.62033 27.1277 9.779 26.9814 9.84643C25.0062 10.7556 24.4031 11.4256 23.5851 13.6204C23.5245 13.7828 23.3816 13.8889 23.2232 13.8889Z"
      fill="#CBD2D9"
    />
    <path
      d="M163.223 28.8889C163.065 28.8889 162.922 28.7828 162.861 28.6204C162.043 26.4258 161.44 25.7556 159.465 24.8464C159.319 24.779 159.223 24.6203 159.223 24.4444C159.223 24.2684 159.319 24.1097 159.465 24.0423C161.44 23.133 162.043 22.4629 162.861 20.2685C162.922 20.1058 163.065 20 163.223 20C163.382 20 163.524 20.106 163.585 20.2685C164.403 22.4631 165.006 23.133 166.981 24.0423C167.128 24.1095 167.223 24.2684 167.223 24.4444C167.223 24.6203 167.128 24.779 166.981 24.8464C165.006 25.7556 164.403 26.4256 163.585 28.6204C163.524 28.7828 163.382 28.8889 163.223 28.8889Z"
      fill="#CBD2D9"
    />
    <path
      d="M100.223 30.6667C100.104 30.6667 99.9973 30.5871 99.9517 30.4653C99.3381 28.8193 98.8859 28.3167 97.4046 27.6348C97.2948 27.5842 97.2231 27.4652 97.2231 27.3333C97.2231 27.2013 97.2948 27.0823 97.4046 27.0317C98.8859 26.3498 99.338 25.8472 99.9517 24.2014C99.9973 24.0794 100.104 24 100.223 24C100.342 24 100.449 24.0795 100.495 24.2014C101.108 25.8473 101.561 26.3498 103.042 27.0317C103.151 27.0821 103.223 27.2013 103.223 27.3333C103.223 27.4652 103.152 27.5842 103.042 27.6348C101.56 28.3167 101.108 28.8192 100.495 30.4653C100.449 30.5871 100.342 30.6667 100.223 30.6667Z"
      fill="#CBD2D9"
    />
    <path
      d="M46.2232 67.6667C46.1044 67.6667 45.9973 67.5871 45.9517 67.4653C45.3381 65.8193 44.8859 65.3167 43.4046 64.6348C43.2948 64.5842 43.2231 64.4652 43.2231 64.3333C43.2231 64.2013 43.2948 64.0823 43.4046 64.0317C44.8859 63.3498 45.338 62.8472 45.9517 61.2014C45.9973 61.0794 46.1044 61 46.2232 61C46.342 61 46.4491 61.0795 46.4946 61.2014C47.1081 62.8473 47.5606 63.3498 49.0419 64.0317C49.1514 64.0821 49.2231 64.2013 49.2231 64.3333C49.2231 64.4652 49.1516 64.5842 49.0419 64.6348C47.5604 65.3167 47.1081 65.8192 46.4946 67.4653C46.4491 67.5871 46.342 67.6667 46.2232 67.6667Z"
      fill="#CBD2D9"
    />
  </svg>
);
export default PaymentSuccess;
