import React, { useEffect, useState } from 'react';
import { filter, get, head, isEmpty, map } from 'lodash';

import DataTable from '../../components/dataTable/DataTable';
import Navbar from '../../components/navbar/Navbar';
import UpperLayer from '../../components/upperLayer/UpperLayer';
import Loader from '../../components/loader/Loader';

import useUserManagementStore from '../../zustand/userManagement.store';
import { fetchUserData, fetchUserDataAccordingToSearch } from '../../actions/userMamagement.action';
import { getFormattedCurrentTime } from '../../helpers/generalHelpers';

import styles from './userManagement.module.scss';

const UserManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [date, setDate] = useState();

  const [refetch, setRefetch] = useState(false);
  // const [searchKey, setSearchKey] = useState('');

  const { userData } = useUserManagementStore((state) => state?.userManagement);
  const addManagementData = useUserManagementStore((state) => state?.addManagementData);
  const { searchedData } = useUserManagementStore((state) => state);

  const filteredData = (data) => {
    let filteredData = map(data, (row) => ({
      'Full Name': `${row.firstname} ${row.lastname}`,
      Id: row.identity_id,
      'Phone Number': row.phone,
      Email: row.email,
      Score: row.score,
      Degree: row.degree,
      Withdraw: row.withdraw,
    }));

    return filteredData;
  };

  const exportSelectedData = (idsArray) => {
    if (!isEmpty(idsArray)) {
      const data = map(idsArray, (selectedId) => head(filter(userData, (user) => user.id === selectedId)));

      return filteredData(data);
    } else {
      return filteredData(userData);
    }
  };

  const handleSearchData = async (val) => {
    const response = await fetchUserDataAccordingToSearch(val);

    searchedData(get(response, 'data.users', {}));
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setDate(getFormattedCurrentTime());
      const response = await fetchUserData();
      setResponse(response);
      addManagementData(response?.data?.users);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // * this function will generate 10 users
  // const handleClick = async () => {
  //   for (let i = 1; i <= 20; i++) {
  //     const payload = {
  //       username: `test0803_${i}`,
  //       firstname: `test first ${i}`,
  //       lastname: `test last ${i}`,
  //       identity_id: `123123123`,
  //       email: `test0803${i}@gmail.com`,
  //       password: 'Test@1234',
  //       birthday: '08/03/2000',
  //       phone: `123123123`,
  //     };
  //     const { data } = await axios.post('http://213.8.7.167:8084/v1/user/sign-up', payload);
  //     const { token } = data;
  //     await axios.post('http://213.8.7.167:8084/v1/user/email/verify', {
  //       code: '22222',
  //       token,
  //     });
  //   }
  // };

  return (
    <div className={styles.leadManagementContainer}>
      <div style={{ width: '90px' }}>
        <Navbar />
      </div>

      <div className={styles.upperLayer}>
        <UpperLayer
          heading="ניהול משתמשים"
          asOfDate="נכון לתאריך"
          date={date}
          totalLeads="סה״כ משתמשים"
          newLeads="משתמשים חדשים (יומי)"
          leadNumber={response?.data?.total_users}
          newLeadNumber={response?.data?.total_users_today}
        />
        {/* <button onClick={handleClick}> generate 10 users</button> */}

        <div className={styles.table}>
          <DataTable
            userData={userData}
            handleSearchData={handleSearchData}
            exportSelectedData={exportSelectedData}
            setRefetch={setRefetch}
          />
        </div>
      </div>

      <Loader isLoading={isLoading} />
    </div>
  );
};

export default UserManagement;
