import { ICONS } from '../../constants/icons.constanst';
import Eye from './Eye';
import Man from './Man';
import AddLead from './AddLead';
import Dashboard from './Dashboard';
import Users from './Users';
import Leads from './Leads';
import Payment from './Payment';
import Search from './Search';
import Delete from './Delete';
import Pencil from './Pencil';
import Excel from './Excel';
import UserLogo from './UserLogo';
import RightArrow from './RightArrow';
import successfullyDelted from './successfullyDeleted';
import ErrorBlack from './ErrorBlack';
import LeadSuccess from './LeadSuccess';
import PaymentSuccess from './PaymentSuccess';
import Success from './Success';
import Error from './Error';
import Celebrations from './Celebrations';
import UserLogoLarge from './UserLogoLarge';
import Partner from './Partner';
import CloseEye from './CloseEye';
import LeftArrow from './leftArrow';
import DownArrow from './DownArrow';
import Calendar from './Calendar';
import AddLeadPhotoIcon from './AddLeadPhotoIcon';
import Logo from './Logo';
import Report from './Report';
import DropDown from './DropDown';
import DropArrow from './DropArrow';

export const ICON_NAME_VS_CMP = {
  [ICONS.MAN]: Man,
  [ICONS.EYE]: Eye,
  [ICONS.ADDLEAD]: AddLead,
  [ICONS.DASHBOAD]: Dashboard,
  [ICONS.USERS]: Users,
  [ICONS.LEADS]: Leads,
  [ICONS.PAYMENT]: Payment,
  [ICONS.SEARCH]: Search,
  [ICONS.DELETE]: Delete,
  [ICONS.PENCIL]: Pencil,
  [ICONS.EXCEL]: Excel,
  [ICONS.USERLOGO]: UserLogo,
  [ICONS.RIGHTARROW]: RightArrow,
  [ICONS.SUCCESSFULLYDELETED]: successfullyDelted,
  [ICONS.ERROR]: Error,
  [ICONS.ERRORBLACK]: ErrorBlack,
  [ICONS.LEADSUCCESS]: LeadSuccess,
  [ICONS.PAYMENTSUCCESS]: PaymentSuccess,
  [ICONS.SUCCESS]: Success,
  [ICONS.CELEBRATIONS]: Celebrations,
  [ICONS.USER_LOGO_LARGE]: UserLogoLarge,
  [ICONS.PARTNER]: Partner,
  [ICONS.CLOSEEYE]: CloseEye,
  [ICONS.LEFTARROW]: LeftArrow,
  [ICONS.DOWNARROW]: DownArrow,
  [ICONS.CALENDAR]: Calendar,
  [ICONS.ADD_LEAD_PHOTO]: AddLeadPhotoIcon,
  [ICONS.LOGO]: Logo,
  [ICONS.REPORT]: Report,
  [ICONS.DROPDOWN]: DropDown,
  [ICONS.DROPARROW]: DropArrow,
};
