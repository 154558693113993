import React from 'react';
import { isEmpty } from 'lodash';

import Button from '../button/Button';
import Icon from '../icon';
import PopUpWrapper from '../popUpWrapper/PopUpWrapper';

import { ICONS } from '../../constants/icons.constanst';
import { Lead } from '../../constants/types.constant';
import { BASE_URL } from '../../axios';

import styles from './publishLeadPopUp.module.scss';
import { numberWithCommas } from '../../helpers/generalHelpers';

interface Props {
  isPublishLeadPopUp?: boolean;
  setIsPublishLeadPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  leadData: Lead;
  handleEditLeadClick?: () => void;
  handlePublishLeadClick?: () => void;
  isLoading?: boolean;
}

const PublishLeadPopUp: React.FC<Props> = (props) => {
  const {
    isPublishLeadPopUp,
    setIsPublishLeadPopUp,
    handleEditLeadClick,
    handlePublishLeadClick,
    leadData,
    isLoading,
  } = props;

  return (
    <PopUpWrapper
      className={styles.popUp}
      isVisible={isPublishLeadPopUp}
      setIsVisible={setIsPublishLeadPopUp}
      dismissable={false}
    >
      <div>
        <div className={styles.mainIcon}>
          <Icon type={ICONS.CELEBRATIONS} />
        </div>
        <p className={styles.title}>{'ליד חדש'}</p>

        <div className={styles.leadContainer}>
          <div className={styles.iconContainer}>
            <div className={styles.imageContainer}>
              {!isEmpty(leadData.visibleLeadAvatar) ? (
                <img
                  src={leadData.visibleLeadAvatar}
                  alt=""
                  style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                />
              ) : isEmpty(leadData.photo) ? (
                <Icon type={ICONS.USER_LOGO_LARGE} />
              ) : (
                <img
                  src={`${BASE_URL}/v1/photos/leads/${leadData.photo}`}
                  alt="is Loading"
                  crossOrigin="anonymous"
                  style={{ height: '100%', width: '100%', objectFit: 'contain', borderRadius: '50%' }}
                />
              )}
            </div>
            <div className={styles.container}>
              <div className={styles.hotContainer}>
                <p className={styles.hot}>{leadData.companyName || '  '}</p>
                <p className={styles.line}> </p>
                <p className={styles.communication}>{leadData.category}</p>
              </div>
              <p className={styles.internet}>{leadData.subCategory}</p>
            </div>
          </div>
          <div className={styles.NIS}>
            <p className={styles.number}>{numberWithCommas(leadData.valuation)}</p>
            <p className={styles.NIS1}>{'₪'}</p>
          </div>

          <p className={styles.description}>{leadData?.description || ''}</p>
          <p className={styles.link}>{leadData?.link || ''}</p>
        </div>
        <p className={styles.publishLead}>{'האם לפרסם את הליד?'}</p>
        <div className={styles.button}>
          <Button disabled={isLoading} className={styles.edit} onClick={handleEditLeadClick}>
            <p style={{ color: 'black' }}>{'ערוך'}</p>
          </Button>
          <Button loading={isLoading} className={styles.publish} onClick={handlePublishLeadClick}>
            {'פרסם'}
          </Button>
        </div>
      </div>
    </PopUpWrapper>
  );
};

PublishLeadPopUp.defaultProps = {};

export default PublishLeadPopUp;
