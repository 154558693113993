/* eslint-disable max-len */
import * as React from 'react';

const Pencil = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_817_9353)">
      <path
        d="M8.57122 20.9827L0.856934 23.1427L3.01693 15.4284L17.1426 1.37126C17.3023 1.20798 17.493 1.07824 17.7035 0.989668C17.9139 0.901095 18.14 0.855469 18.3684 0.855469C18.5967 0.855469 18.8228 0.901095 19.0333 0.989668C19.2438 1.07824 19.4344 1.20798 19.5941 1.37126L22.6284 4.42268C22.789 4.58205 22.9166 4.77165 23.0036 4.98055C23.0906 5.18945 23.1354 5.41352 23.1354 5.63983C23.1354 5.86613 23.0906 6.0902 23.0036 6.2991C22.9166 6.508 22.789 6.6976 22.6284 6.85697L8.57122 20.9827Z"
        stroke="#52606D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_817_9353">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Pencil;
