// import { map, isEmpty, size, forEach } from 'lodash';
import { isEmptyErrors, validateEmail, validateEmptyField, validateMobileNumber } from '../../helpers/generalHelpers';

// const validIsEmpty = ({ firstname }) => {
//   if (isEmpty(firstname)) {
//     errors = {
//       ...errors,
//       firstname: 'field can not be empty',
//     };
//   }
// };

export const validateUserFields = (data) => {
  const errors = {
    firstname: validateEmptyField(data.firstname),
    lastname: validateEmptyField(data.lastname),

    username: validateEmptyField(data.username),
    email: validateEmail(data.email),
    phone: validateMobileNumber(data.phone),
    // id: validateMobileNumber(data.id),
  };

  // console.log(errors);
  return {
    errors,
    canMove: isEmptyErrors(errors),
  };

  //   if (isEmpty(values.lastname)) {
  //     errors = {
  //       ...errors,
  //       lastname: 'LastName can not be empty',
  //     };
  //   }

  //   if (size(values.phone) !== 9) {
  //     errors = {
  //       ...errors,
  //       phone: 'phone should be 9 digit',
  //     };
  //   }

  //   const validEmail =
  // eslint-disable-next-line max-len
  //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   if (!values.email.match(validEmail)) {
  //     errors = {
  //       ...errors,
  //       email: 'enter appropriate email',
  //     };
  //   }

  //   if (size(values.identity_id) !== 9) {
  //     errors = {
  //       ...errors,
  //       id: 'id should be 9 digit',
  //     };
  //   }

  //   setErrors(errors);

  //   let isEmptyObject = false;
  //   forEach(errors, (item) => {
  //     console.log(item, 'poiuytrew');
  //     if (size(item) !== 0) {
  //       console.log(size(item), item, 'item size');
  //       return false;
  //     }
  //   });
  //   return true;
};
