import {
  isEmptyErrors,
  validateNumber,
  validateEmptyField,
  validateUrl,
  handlebudget,
} from '../../helpers/generalHelpers';

export const validateLeadFields = (lead) => {
  const { companyName, category, subCategory, valuation, link, description, budget } = lead;

  const errors = {
    companyName: validateEmptyField(companyName),
    category: validateEmptyField(category),
    subCategory: validateEmptyField(subCategory),
    valuation: validateNumber(valuation),
    link: validateUrl(link),
    description: validateEmptyField(description),
    // budget: validateNumber(budget),
    budget: handlebudget(budget, valuation),
  };

  return {
    errors,
    canMove: isEmptyErrors(errors),
  };
};
