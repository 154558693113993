import React, { useState } from 'react';
import { get } from 'lodash';

import UserSuccessfullyDeletedModal from '../userSuccessfullyDeletedModal/UserSuccessfullyDeletedModal';
import Table from '../Table/Table';
import LeftSideDrawerWrapper from '../leftSideDrawerWrapper/LeftSideDrawerWrapper';
import SideDrawerUserForm from '../sideDrawerUserForm/SideDrawerUserForm';
import UserDeleteConfirmationModal from '../UserDeleteConfirmationModal/UserDeleteConfirmationModal';
import Values from '../../types';
import { deleteUser } from '../../actions/userMamagement.action';
import useUserManagementStore from '../../zustand/userManagement.store';
import { getColumns } from './config';

import './dataTable.css';

const DataTable = (props) => {
  const { userData, setSearchKey, handleSearchData, exportSelectedData, setRefetch } = props;

  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<Values>();

  const [width, setWidth] = useState(window.innerWidth);

  const [isDeletePopUpVisible, setIsDeletePopUpVisible] = useState(false);
  const [isDeleteSucceed, setIsDeleteSucceed] = useState(false);
  const [isDeleteFailed, setIsDeleteFailed] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteUserSuccess = useUserManagementStore((state) => state?.deleteUserSuccess);

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const handleRowEditClick = (params) => () => {
    setSelectedUserData(params?.row);
    setOpenSideDrawer(true);
  };

  const handleDeleteItemClick = (params) => () => {
    setSelectedUserData(params?.row);
    setIsDeletePopUpVisible(true);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const isSuccess = await deleteUser(selectedUserData?.id);
    setDeleteLoading(false);
    setIsDeletePopUpVisible(false);
    if (isSuccess) {
      deleteUserSuccess(selectedUserData?.id);
      setIsDeleteFailed(false);
      setIsDeleteSucceed(true);

      setRefetch((prev) => !prev);
    } else {
      setIsDeleteFailed(true);
    }
  };

  const closeSideDrawer = () => {
    setOpenSideDrawer(false);
  };

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Table
          rows={userData}
          column={getColumns({ width, handleDeleteItemClick, handleRowEditClick })}
          keepSearchToLeft={true}
          fileName="users"
          setSearchKey={setSearchKey}
          handleSearchData={handleSearchData}
          exportSelectedData={exportSelectedData}
        />
      </div>

      {/* {openSideDrawer && ( */}
      <LeftSideDrawerWrapper
        isVisible={openSideDrawer}
        setIsVisible={setOpenSideDrawer}
        selectedUserData={selectedUserData}
        heading={`${get(selectedUserData, 'firstname', '')} ${get(selectedUserData, 'lastname', '')}`}
      >
        <SideDrawerUserForm
          data={{ ...selectedUserData }}
          allUserData={userData}
          closeSideDrawer={closeSideDrawer}
          handleDeleteClick={() => {
            setOpenSideDrawer(false);
            setIsDeletePopUpVisible(true);
          }}
        />
      </LeftSideDrawerWrapper>

      {/* Delete User Confimation Modal */}
      <UserDeleteConfirmationModal
        isVisible={isDeletePopUpVisible}
        setIsVisible={setIsDeletePopUpVisible}
        handlePrimaryButtonClick={handleDelete}
        handleSecondaryButtonClick={() => setIsDeletePopUpVisible(false)}
        loading={deleteLoading}
      />
      {/* Delete User Success Modal */}
      <UserSuccessfullyDeletedModal isVisible={isDeleteSucceed} setIsVisible={setIsDeleteSucceed} />
      {/* Delete User Failure Modal */}
      <UserDeleteConfirmationModal
        isVisible={isDeleteFailed}
        setIsVisible={setIsDeleteFailed}
        handlePrimaryButtonClick={handleDelete}
        handleSecondaryButtonClick={() => setIsDeleteFailed(false)}
        loading={deleteLoading}
        content={'הפעולה נכשלה \nנסה שנית'}
        secondaryButtonText="נסה שנית"
        primaryButtonText="סגור"
      />
    </>
  );
};

export default DataTable;
