import React from 'react';
import { ICONS } from '../../constants/icons.constanst';
import Button from '../button/Button';
import Icon from '../icon';
import PopUpWrapper from '../popUpWrapper/PopUpWrapper';
import styles from './userSuccessfullyDeletedModal.module.scss';

interface Props {
  icon?: string;
  content?: string;
  btnText?: string;
  isVisible?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSuccessfullyDeletedModal: React.FC<Props> = (props) => {
  const { icon, content, btnText, isVisible, setIsVisible } = props;

  const handleDone = () => {
    setIsVisible(false);
  };

  return (
    <PopUpWrapper isVisible={isVisible} setIsVisible={setIsVisible}>
      <div className={styles.icon}>
        <Icon type={icon} />
      </div>
      <p className={styles.content}>{content}</p>
      <Button className={styles.button} onClick={handleDone}>
        {btnText}
      </Button>
    </PopUpWrapper>
  );
};

UserSuccessfullyDeletedModal.defaultProps = {
  icon: ICONS.SUCCESSFULLYDELETED,
  content: 'המשתמש נמחק \nבהצלחה',
  btnText: 'סגור',
};

export default UserSuccessfullyDeletedModal;
