/* eslint-disable max-len */
import * as React from 'react';

const DropArrow = (props) => (
  <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.62624 9.04289C4.01676 8.65237 4.64993 8.65237 5.04045 9.04289L13 17.0025L20.9596 9.04289C21.3501 8.65237 21.9833 8.65237 22.3738 9.04289C22.7643 9.43342 22.7643 10.0666 22.3738 10.4571L13.7071 19.1238C13.3166 19.5143 12.6834 19.5143 12.2929 19.1238L3.62624 10.4571C3.23571 10.0666 3.23571 9.43342 3.62624 9.04289Z"
      fill="#949494"
    />
  </svg>
);
export default DropArrow;
