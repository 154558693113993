import React from 'react';
import { Button as NativeButton } from '@mui/material';
import cx from 'classnames';

import { CircularProgress } from '@mui/material';
import Icon from '../icon';

import styles from './button.module.scss';

interface Props {
  className?: string;
  children?: string | React.ReactNode;
  loading?: Boolean;
  disabled?: Boolean;
  onClick?: () => void;
  startIcon?: React.ReactNode;
}

const Button: React.FC<Props> = (props) => {
  const { className, children, loading, onClick, disabled, startIcon, ...rest } = props;
  return (
    <NativeButton
      className={cx(styles.button, className, {
        [styles.loading]: loading,
        [styles.disabled]: disabled,
      })}
      variant="outlined"
      onClick={onClick}
      {...rest}
      startIcon={<Icon type={startIcon} />}
    >
      {!loading ? <div className={styles.child}>{children}</div> : <CircularProgress className={styles.loader} />}
    </NativeButton>
  );
};

export default Button;
