import axios from '../axios';

const loginUser = (payload) => axios.post('/v1/admin/login', payload);

const userInfo = () => axios.get('/v1/admin/user-info');

const refreshToken = (payload) => axios.post('/v1/refresh-token', payload);

const reportFromUser = (from, to) => axios.get(`v1/admin/reports/users?from=${from}&to=${to}`);
const reportofPayment = (from, to) => axios.get(`v1/admin/reports/payments?from=${from}&to=${to}`);
const reportofUsesTxt = (from, to) => axios.get(`v1/admin/reports/users-txt?from=${from}&to=${to}`);
const reportofLeads = (from, to) => axios.get(`v1/admin/reports/leads?from=${from}&to=${to}`);
const reportofselfPayment = (from, to) => axios.get(`v1/admin/reports/log-commands?from=${from}&to=${to}`);

const userServices = {
  loginUser,
  userInfo,
  refreshToken,
  reportFromUser,
  reportofPayment,
  reportofselfPayment,
  reportofUsesTxt,
  reportofLeads,
};

export default userServices;
