import React from 'react';
import Button from '../button/Button';
import PopUpWrapper from '../popUpWrapper/PopUpWrapper';

import styles from './moneyTransferPopUP.module.scss';

interface Props {
  description?: string;
  userName?: string;
  userName1?: string;
  number?: string;
  userName2?: string;
  title?: string;
  date?: string;
  dateContent?: string;
  logo?: string;
  paymentAmount?: string;
  amount?: string;
  transferButton?: string;
  cancleButton?: string;
  openPaymentTransferPopUp?: boolean;
  setOpenPaymentTransferPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPaymentSuccessfullyTransferPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  setMoneyTrabsferErrorPopUp?: React.Dispatch<React.SetStateAction<boolean>>;
  handleTransferPayment?: () => Promise<boolean>;
  isLoading?: boolean;
}

const MoneyTransferPopUP: React.FC<Props> = (Props) => {
  const {
    title,
    description,
    transferButton,
    cancleButton,
    userName,
    userName1,
    number,
    userName2,
    date,
    dateContent,
    logo,
    paymentAmount,
    amount,
    openPaymentTransferPopUp,
    setOpenPaymentTransferPopUp,
    setOpenPaymentSuccessfullyTransferPopUp,
    setMoneyTrabsferErrorPopUp,
    handleTransferPayment,
    isLoading,
  } = Props;

  // const startLoading = () => {};

  const OpenPaymentSuccessPopUp = async () => {
    const success = await handleTransferPayment();
    if (success) {
      setOpenPaymentSuccessfullyTransferPopUp(true);
    } else {
      setMoneyTrabsferErrorPopUp(true);
    }
    setOpenPaymentTransferPopUp(false);
  };

  const closePaymentTransferPopUp = () => {
    setOpenPaymentTransferPopUp(false);
  };

  return (
    <PopUpWrapper
      className={styles.popUpUpdate}
      isVisible={openPaymentTransferPopUp}
      setIsVisible={setOpenPaymentTransferPopUp}
    >
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
        <div className={styles.userPayementInfo}>
          <div className={styles.userNameContainer}>
            <p className={styles.userName}>{userName}</p>
            <div>
              <p style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '18px' }}>{userName1}</p>
              <div style={{ display: 'flex' }}>
                <p className={styles.userName2}>{userName2}</p>
                <p className={styles.userName}>{number}</p>
              </div>
            </div>
          </div>
          <div className={styles.dateContainer}>
            <p style={{ fontSize: '18px' }} className={styles.userName}>
              {date}
            </p>
            <p>{dateContent}</p>
          </div>

          <div className={styles.paymentContainer}>
            <p className={styles.userName}>{paymentAmount}</p>
            <div className={styles.amountContainer}>
              <p style={{ fontSize: '24px' }}>{amount}</p>
              <p className={styles.logo}>{logo}</p>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button className={styles.button1} onClick={closePaymentTransferPopUp}>
            <p style={{ color: '#000000' }}> {transferButton}</p>
          </Button>
          <Button className={styles.button2} loading={isLoading} onClick={OpenPaymentSuccessPopUp}>
            {cancleButton}
          </Button>
        </div>
      </div>
    </PopUpWrapper>
  );
};

MoneyTransferPopUP.defaultProps = {
  title: 'העברת תשלום ',
  description: 'האם לבצע העברת תשלום ל:',
  userName: 'שם משתמש',
  userName1: 'מנחם אברהם',
  number: '4432-**** ',
  userName2: 'חשבון בנק',
  date: 'תאריך העברה',
  dateContent: '01/03/2022',
  paymentAmount: 'סכום לתשלום',
  logo: '₪',
  amount: '3,145',
  transferButton: 'ביטול',
  cancleButton: 'העבר תשלום',
};

export default MoneyTransferPopUP;
