import * as React from 'react';

const Success = (props) => (
  <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={32} cy={32} r={32} fill="#3FE2D2" />
    <path
      d="M22.3989 33.2129L29.5545 40.9602L44.4789 26.2402"
      stroke="white"
      strokeWidth={2.56}
      strokeLinecap="round"
    />
  </svg>
);
export default Success;
