/* eslint-disable max-len */
import * as React from 'react';

const Leads = (props) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24512 5.35714C7.24512 4.46955 7.96465 3.75 8.85226 3.75H28.3295C29.2171 3.75 29.9366 4.46955 29.9366 5.35714C29.9366 6.24474 29.2171 6.96429 28.3295 6.96429H8.85226C7.96465 6.96429 7.24512 6.24474 7.24512 5.35714ZM7.24512 15C7.24512 14.1124 7.96465 13.3929 8.85226 13.3929H28.3295C29.2171 13.3929 29.9366 14.1124 29.9366 15C29.9366 15.8876 29.2171 16.6071 28.3295 16.6071H8.85226C7.96465 16.6071 7.24512 15.8876 7.24512 15ZM8.85226 23.0357C7.96465 23.0357 7.24512 23.7553 7.24512 24.6429C7.24512 25.5304 7.96465 26.25 8.85226 26.25H28.3295C29.2171 26.25 29.9366 25.5304 29.9366 24.6429C29.9366 23.7553 29.2171 23.0357 28.3295 23.0357H8.85226Z"
      fill="#7B8795"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0634766 5.35714C0.0634766 4.46955 0.783018 3.75 1.67062 3.75H2.36651C3.25411 3.75 3.97366 4.46955 3.97366 5.35714C3.97366 6.24474 3.25411 6.96429 2.36651 6.96429H1.67062C0.783018 6.96429 0.0634766 6.24474 0.0634766 5.35714ZM0.0634766 15C0.0634766 14.1124 0.783018 13.3929 1.67062 13.3929H2.36651C3.25411 13.3929 3.97366 14.1124 3.97366 15C3.97366 15.8876 3.25411 16.6071 2.36651 16.6071H1.67062C0.783018 16.6071 0.0634766 15.8876 0.0634766 15ZM1.67062 23.0357C0.783018 23.0357 0.0634766 23.7553 0.0634766 24.6429C0.0634766 25.5304 0.783018 26.25 1.67062 26.25H2.36651C3.25411 26.25 3.97366 25.5304 3.97366 24.6429C3.97366 23.7553 3.25411 23.0357 2.36651 23.0357H1.67062Z"
      fill="#7B8795"
    />
  </svg>
);
export default Leads;
