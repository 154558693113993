import * as React from 'react';

const AddLead = (props) => (
  <svg width={45} height={43} viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={45} height={43} rx={10} fill="#7B8795" />
    <line x1={11} y1={22.5} x2={35} y2={22.5} stroke="#F6F7FB" />
    <line x1={22.5} y1={35} x2={22.5} y2={11} stroke="#F6F7FB" />
  </svg>
);
export default AddLead;
