import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../constants/routes.constants';
import { TOKENS } from '../constants/general.constants';

const withAuth =
  (Component) =>
  ({ ...props }) => {
    const refresh_token = localStorage.getItem(TOKENS.REFRESH_TOKEN);

    const navigate = useNavigate();

    useEffect(() => {
      if (!refresh_token) {
        navigate(ROUTES.LOGIN);
        return;
      }
    }, []);

    // if (isLoading) return <Loader isLoading={isLoading} />;

    if (refresh_token) {
      return <Component {...props} />;
    }
  };
export default withAuth;
