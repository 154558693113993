import { create } from 'zustand';

interface SnackBar {
  isOpen: boolean;
  message: string;
  showSnackBar: (message: string) => void;
  closeSnackBar: () => void;
}

const snackBarStore = create<SnackBar>((set) => ({
  isOpen: false,
  message: '',

  showSnackBar: (message) => {
    set((state) => ({
      ...state,
      isOpen: true,
      message,
    }));
    setTimeout(() => {
      set((state) => ({
        ...state,
        isOpen: false,
        message: '',
      }));
    }, 3000);
  },
  closeSnackBar: () => {
    set((state) => ({
      ...state,
      isOpen: false,
    }));
  },
}));

export default snackBarStore;
