/* eslint-disable max-len */
import * as React from 'react';

const Report = (props) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.7496 2.76261C19.2371 2.25011 18.3496 2.60011 18.3496 3.31261V7.67511C18.3496 9.50011 19.8996 11.0126 21.7871 11.0126C22.9746 11.0251 24.6246 11.0251 26.0371 11.0251C26.7496 11.0251 27.1246 10.1876 26.6246 9.68761C24.8246 7.87511 21.5996 4.61261 19.7496 2.76261Z"
      fill="#292D32"
    />
    <path
      d="M25.625 12.7375H22.0125C19.05 12.7375 16.6375 10.325 16.6375 7.3625V3.75C16.6375 3.0625 16.075 2.5 15.3875 2.5H10.0875C6.2375 2.5 3.125 5 3.125 9.4625V20.5375C3.125 25 6.2375 27.5 10.0875 27.5H19.9125C23.7625 27.5 26.875 25 26.875 20.5375V13.9875C26.875 13.3 26.3125 12.7375 25.625 12.7375ZM14.375 22.1875H9.375C8.8625 22.1875 8.4375 21.7625 8.4375 21.25C8.4375 20.7375 8.8625 20.3125 9.375 20.3125H14.375C14.8875 20.3125 15.3125 20.7375 15.3125 21.25C15.3125 21.7625 14.8875 22.1875 14.375 22.1875ZM16.875 17.1875H9.375C8.8625 17.1875 8.4375 16.7625 8.4375 16.25C8.4375 15.7375 8.8625 15.3125 9.375 15.3125H16.875C17.3875 15.3125 17.8125 15.7375 17.8125 16.25C17.8125 16.7625 17.3875 17.1875 16.875 17.1875Z"
      fill="#292D32"
    />
  </svg>
);
export default Report;
