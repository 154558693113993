import { get } from 'lodash';
import { phoneVerificationServices } from '../services/phoneVerification.services';

export const fetchData = async (linkID) => {
  try {
    const response = await phoneVerificationServices.fetchData(linkID);
    return { data: get(response, 'data', {}) };
  } catch (error) {
    return { error: 'משהו השתבש! לא מצליח להביא נתונים' };
  }
};

export const fetchToken = async (updatedMobileNumber, session_id) => {
  try {
    const response = await phoneVerificationServices.fetchToken(updatedMobileNumber, session_id);

    return { token: get(response, 'data.token', '') };
  } catch (error) {
    return { error: 'מספר הטלפון אינו חוקי' };
  }
};

export const otpValidation = async (token, session_id, otp) => {
  try {
    const response = await phoneVerificationServices.validateOtp(token, session_id, otp);
    return { link: get(response, 'data.link', '') };
  } catch (error) {
    return { error: 'הזן קוד נכון' };
  }
};
