import React, { useState } from 'react';
import cx from 'classnames';

import UserDeleteConfirmationModal from '../UserDeleteConfirmationModal/UserDeleteConfirmationModal';
import userStore from '../../zustand/user.store';

import styles from './upperLayer.module.scss';
import { numberWithCommas } from '../../helpers/generalHelpers';

interface Props {
  setIsSideDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletetPopUpVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  heading?: string;
  totalLeads?: string;
  newLeads?: string;
  leadNumber?: number;
  newLeadNumber?: number;
  updated?: string;
  asOfDate?: string;
  date?: string;
}

const UpperLayer: React.FC<Props> = (props) => {
  const { heading, totalLeads, newLeads, leadNumber, newLeadNumber, updated, asOfDate, date } = props;
  const { data, flushUser } = userStore((state) => state);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleLogout = () => {
    localStorage.clear();
    flushUser();
  };

  return (
    <div className={cx(styles.upperLayer)}>
      <div className={styles.firstRow}>
        <div>
          <p className={cx(styles.leadManagement, updated)}> {heading}</p>
          <div className={cx(styles.dateContainer)}>
            <p className={styles.asOfDate}>{asOfDate} </p>
            <p className={styles.date}>{date}</p>
          </div>
        </div>

        <div className={styles.leftWrapper}>
          <div className={styles.callCenter}>
            <p className={styles.name}> {data.name}</p>
            <p className={styles.call}>{data.role}</p>
          </div>
          <p className={styles.disconnect} onClick={() => setModalOpen(!modalOpen)}>
            <UserDeleteConfirmationModal
              title={'התנתק'}
              content={'האם אתה בטוח \n שברצונך להתנתק?'}
              primaryButtonText={'ביטול'}
              secondaryButtonText={'להתנתק'}
              isVisible={modalOpen}
              handlePrimaryButtonClick={handleLogout}
              handleSecondaryButtonClick={() => setModalOpen(false)}
              setIsVisible={setModalOpen}
            />
            התנתק
          </p>
        </div>
      </div>

      <div className={styles.bottomLayer}>
        {totalLeads && (
          <div>
            <p className={styles.totalLeads1}> {totalLeads}</p>
            <p className={styles.leadNumber}>{numberWithCommas(leadNumber)}</p>
          </div>
        )}

        {newLeads && (
          <div>
            <p className={styles.newLeads}>{newLeads}</p>
            <p className={styles.newLeadNumber}>{numberWithCommas(newLeadNumber)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpperLayer;
