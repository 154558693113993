import { createSlice } from '@reduxjs/toolkit';
import { deleteRow, updateUserRow } from '../leads/leads.helpers';

const INITIAL_STATE = {
  isLoading: false,
  data: [],
};

const paymentTransferSlice = createSlice({
  name: 'paymentTansfer',
  initialState: INITIAL_STATE,
  reducers: {
    // setData: (state, action) => ({
    //   ...state,
    //   data: action.payload,
    //   isLoading: false,
    // }),

    // paymentPageDataFetchStart: (state) => ({
    //   ...state,
    //   isLoading: true,
    // }),

    paymentDataFetchStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    paymentDataFetchSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),

    paymentDataFetchFail: (state, action) => ({
      ...state,
      isLoading: false,
      error: 'retry',
    }),

    updatePaymentTransferDataStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    updatePaymentTransferDataSucccess: (state, action) => ({
      ...state,
      isLoading: false,
      data: updateUserRow(state.data, action.payload),
    }),

    updatePaymentTransferDataFail: (state, action) => ({
      ...state,
      isLoading: false,
      error: 'update failed',
    }),

    deletePaymentTransferDataStart: (state, action) => ({
      ...state,
      isLoading: true,
    }),

    deletePaymentTransferDataSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      data: deleteRow(state.data, action.payload),
    }),

    deletePaymentTransferDataFail: (state, action) => ({
      ...state,
      isLoading: false,
      error: 'update failed',
    }),
  },
});

// eslint-disable-next-line max-len
export const {
  setData,
  paymentPageDataFetchStart,
  paymentDataFetchStart,
  paymentDataFetchSuccess,
  paymentDataFetchFail,
  updatePaymentTransferDataStart,
  updatePaymentTransferDataSucccess,
  updatePaymentTransferDataFail,
  deletePaymentTransferDataStart,
  deletePaymentTransferDataSuccess,
  deletePaymentTransferDataFail,
} = paymentTransferSlice.actions;

const paymentTansferReducer = paymentTransferSlice.reducer;

export default paymentTansferReducer;
