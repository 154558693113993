import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useStore } from 'zustand';

import ErrorPopUp from './errorPopUp/ErrorPopUp';
import LeftSideDrawerWrapper from './leftSideDrawerWrapper/LeftSideDrawerWrapper';
import PublishLeadPopUp from './publishLeadPopUp/PublishLeadPopUp';
import SideDrawerContent from './sideDrawerContent/SideDrawerContent';

import { addOrUpdateLead } from '../actions/leads.action';
import { ICONS } from '../constants/icons.constanst';
import { EMPTY_LEAD, Lead } from '../constants/types.constant';
import newLeadStore from '../zustand/newLead.store';
import leadsStore from '../zustand/leadManagement.store';

const AddNewLead = () => {
  const { isSideDrawerOpen, setIsSideDrawerOpen } = useStore(newLeadStore);
  const { addLead } = useStore(leadsStore);

  const [isPublishLeadPopUp, setIsPublishLeadPopUp] = React.useState(false);
  const [publishLeadSuccessPopUp, setPublishLeadSuccessPopUp] = React.useState(false);
  const [publishLeadFailurePopup, setPublishLeadFailurePopup] = React.useState(false);
  const [formValues, setFormValues] = React.useState<Lead>(EMPTY_LEAD);
  const [isLoading, setIsLoading] = useState(false);
  const [showSideDrawer, setShowSideDrawer] = useState(true);

  const handleSaveClick = (newLeadData: Lead) => {
    setIsSideDrawerOpen(false);
    setIsPublishLeadPopUp(true);
    setFormValues(newLeadData);
  };

  const handleCancelClick = () => {
    setIsSideDrawerOpen(false);
    setFormValues(EMPTY_LEAD);
  };

  const handlePublishLeadClick = async () => {
    setIsLoading(true);
    const data = await addOrUpdateLead(formValues);
    setIsLoading(false);
    setIsPublishLeadPopUp(false);
    if (!isEmpty(data)) {
      addLead(data);
      setPublishLeadFailurePopup(false);
      setPublishLeadSuccessPopUp(true);
    } else {
      setPublishLeadFailurePopup(true);
    }
  };

  const handleEditLeadClick = () => {
    setIsPublishLeadPopUp(false);
    setIsSideDrawerOpen(true);
  };

  return (
    <>
      <LeftSideDrawerWrapper
        isVisible={isSideDrawerOpen}
        setIsVisible={setIsSideDrawerOpen}
        dismissable={showSideDrawer}
        isNewLead
      >
        <SideDrawerContent
          isVisible={isSideDrawerOpen}
          isNewLead
          handlePrimaryClick={handleSaveClick}
          handleSecondaryClick={handleCancelClick}
          initalFormValues={formValues}
          setShowSideDrawer={setShowSideDrawer}
        />
      </LeftSideDrawerWrapper>
      <PublishLeadPopUp
        isPublishLeadPopUp={isPublishLeadPopUp}
        setIsPublishLeadPopUp={setIsPublishLeadPopUp}
        leadData={formValues}
        handleEditLeadClick={handleEditLeadClick}
        handlePublishLeadClick={handlePublishLeadClick}
        isLoading={isLoading}
      />
      <ErrorPopUp
        icon={ICONS.SUCCESS}
        title={'הליד פורסם בהצלחה\n ויופיע ברשימת הלידים באפליקצייה'}
        description={'באפשרותך לערוך את הליד בכל זמן\n בעמוד הלידים'}
        visible={publishLeadSuccessPopUp}
        setVisible={setPublishLeadSuccessPopUp}
        hasPrimaryButton
        primaryButtonLabel={'סגור'}
        handlePrimaryClick={() => {
          setPublishLeadSuccessPopUp(false);
          setFormValues(EMPTY_LEAD);
        }}
      />

      <ErrorPopUp
        icon={ICONS.ERROR}
        title={'קרתה תקלה...'}
        description={'הליד לא פורסם נסה שנית'}
        hasPrimaryButton
        primaryButtonLabel={'נסה שנית'}
        hasSecondaryButton
        secondaryButtonLabel={'ביטול'}
        visible={publishLeadFailurePopup}
        setVisible={setPublishLeadFailurePopup}
        handleSecondaryClick={() => {
          setPublishLeadFailurePopup(false);
          setFormValues(EMPTY_LEAD);
        }}
        handlePrimaryClick={handlePublishLeadClick}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddNewLead;
