import * as React from 'react';

const DownArrow = (props) => (
  <svg width={25} height={14} viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.5}
      d="M23.6879 0.999894L12.3439 12.1606L1 0.999895"
      stroke="#7B8795"
      strokeWidth={1.59439}
      strokeLinecap="round"
    />
  </svg>
);
export default DownArrow;
