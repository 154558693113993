import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore(
  {
    reducer: rootReducer,
    middleware,
  },
  composeWithDevTools(),
);

sagaMiddleware.run(rootSaga);

export default store;
