import Icon from '../icon';
import UserWithAvatarItem from '../userWithAvatarItem/UserWithAvatarItem';

import { ICONS } from '../../constants/icons.constanst';

import styles from './dataTable.module.scss';

export const getColumns = ({ width, handleRowEditClick, handleDeleteItemClick }) => [
  {
    field: 'firstname',
    headerClassName: styles.header,
    cellClassName: styles.columnStyle,
    align: 'left',
    headerAlign: 'left',
    width: width * 0.106,
    renderHeader: () => <span style={{ color: '#7B8795' }}>שם מלא</span>,
    renderCell: (params) => <div>{`${params.row.firstname}  ${params.row.lastname}`}</div>,
  },

  {
    field: 'identity_id',
    width: width * 0.101,
    headerClassName: styles.header,
    cellClassName: styles.columnStyle,
    align: 'left',
    headerAlign: 'left',

    renderHeader: () => <span style={{ color: '#7B8795' }}>ת.ז</span>,
  },

  {
    field: 'phone',
    headerClassName: styles.header,
    cellClassName: styles.columnStyle,
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    width: width * 0.112,
    renderHeader: () => <span style={{ color: '#7B8795' }}>מספר טלפון</span>,
  },
  {
    field: 'email',
    headerClassName: styles.header,
    cellClassName: styles.columnStyle,
    align: 'left',
    headerAlign: 'left',

    width: width * 0.144,
    renderHeader: () => <span style={{ color: '#7B8795' }}>אימייל</span>,
  },
  {
    field: 'uid',
    headerClassName: styles.header,
    align: 'left',
    headerAlign: 'left',
    renderHeader: () => <span style={{ color: '#7B8795' }}>דרגה</span>,
    width: width * 0.146,
    renderCell: (params) => (
      <UserWithAvatarItem
        iconType={ICONS.USERLOGO}
        degree={params?.row?.degree}
        level2={`דרגה ${params?.row?.degree}`}
        activeLeads={`${params?.row?.active_leads_count} לידים פעילים`}
      />
    ),
  },
  {
    field: 'balance',
    headerClassName: styles.header,
    align: 'left',
    headerAlign: 'left',
    width: width * 0.147,
    renderHeader: () => <span style={{ color: '#7B8795' }}>סכום בארנק</span>,
    cellClassName: () => styles.balanceText,
  },
  {
    field: 'edit',
    headerName: '',
    width: width * 0.0404,
    renderCell: (params) => (
      <div className={styles.pencil} onClick={handleRowEditClick(params)}>
        <Icon type={ICONS.PENCIL} />
      </div>
    ),
    sortable: false,
    editable: false,
  },
  {
    field: 'delete',
    headerName: '',
    width: width * 0.046,
    renderCell: (params) => (
      <div className={styles.delete} onClick={handleDeleteItemClick(params)}>
        <Icon type={ICONS.DELETE} height={20} width={20} />
      </div>
    ),
    sortable: false,
    editable: false,
  },
];
