/* eslint-disable max-len */
import * as React from 'react';

const SVGComponent = (props) => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9482 6.2823C16.9483 6.28226 16.9483 6.28219 16.9484 6.28215C17.3628 7.14889 17.5948 8.11948 17.5948 9.14428C17.5948 10.8404 16.9592 12.3881 15.9132 13.5621C15.9132 13.5621 15.9131 13.562 15.9131 13.562C16.959 12.388 17.5946 10.8403 17.5946 9.14428C17.5946 8.11955 17.3626 7.149 16.9482 6.2823ZM17.7675 15.5037C19.322 13.8382 20.2734 11.6023 20.2734 9.14428C20.2734 7.6286 19.9117 6.19738 19.2698 4.93228C19.8143 4.76629 20.3922 4.677 20.9909 4.677C24.2454 4.677 26.8837 7.31531 26.8837 10.5699C26.8837 13.8244 24.2454 16.4627 20.9909 16.4627C19.8011 16.4627 18.6937 16.1101 17.7675 15.5037ZM16.3604 18.372C16.3604 18.372 16.3605 18.372 16.3605 18.372C17.6853 18.832 18.9334 19.4561 20.0803 20.22C20.9466 20.7969 21.5199 21.5597 21.7569 22.4256C21.992 23.2857 21.8705 24.1579 21.5077 24.9143C20.7956 26.3987 19.1525 27.475 17.1653 27.4995H17.1651C19.1523 27.475 20.7954 26.3987 21.5075 24.9143C21.8703 24.1579 21.9918 23.2857 21.7567 22.4256C21.5197 21.5597 20.9463 20.7969 20.0801 20.22C18.9332 19.4561 17.6851 18.832 16.3604 18.372ZM23.0045 27.4995C23.366 27.0662 23.6754 26.5885 23.9227 26.0727C24.5311 24.8048 24.7651 23.2712 24.3406 21.7191C23.9339 20.2316 23.0019 19.0143 21.7796 18.1388C24.1578 18.4549 26.3662 19.3126 28.2749 20.5839C29.0945 21.1298 29.6409 21.8541 29.8668 22.6802C30.0909 23.5002 29.9748 24.3308 29.63 25.0493C28.946 26.4754 27.3609 27.4995 25.4551 27.4995H23.0045Z"
      fill="#7B8795"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.30747 9.14436C4.30747 5.47519 7.28193 2.50073 10.9511 2.50073C14.6203 2.50073 17.5947 5.47519 17.5947 9.14436C17.5947 12.8135 14.6203 15.788 10.9511 15.788C7.28193 15.788 4.30747 12.8135 4.30747 9.14436ZM1.81495 20.2201C4.43023 18.4782 7.57198 17.4631 10.9476 17.4631C14.3232 17.4631 17.4649 18.4782 20.0802 20.2201C20.9465 20.797 21.5199 21.5598 21.7569 22.4257C21.9919 23.2858 21.8704 24.158 21.5076 24.9144C20.7873 26.4159 19.1145 27.5 17.0966 27.5H4.79857C2.7806 27.5 1.10782 26.4159 0.387517 24.9144C0.0247293 24.158 -0.0968065 23.2858 0.138379 22.4257C0.375215 21.5598 0.948684 20.797 1.81495 20.2201Z"
      fill="#7B8795"
    />
  </svg>
);
export default SVGComponent;
