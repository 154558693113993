import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './loader.module.scss';

interface Props {
  isLoading: boolean;
  // setIsLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const Loader: React.FC<Props> = (props) => {
  const { isLoading } = props;
  return (
    isLoading && (
      <div className={styles.loader}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <CircularProgress
            size="8rem"
            thickness={0.8}
            classes={{
              circle: styles.progress,
            }}
          />
        </Box>
      </div>
    )
  );
};

export default Loader;
