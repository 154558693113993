/* eslint-disable max-len */
import * as React from 'react';

const UserLogo = (props) => (
  <svg width={31} height={33} viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx={15.5} cy={17.2816} rx={15.5} ry={15.7181} fill="#3FE2D2" />
    <mask
      id="mask0_817_9306"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={1}
      width={31}
      height={32}
    >
      <ellipse cx={15.5} cy={17.2816} rx={15.5} ry={15.7181} fill="white" />
    </mask>
    <g mask="url(#mask0_817_9306)">
      <path
        d="M14.8863 38.1507V29.4688L13.1161 30.0815V35.9864L11.6258 37.4079C11.2885 37.7296 11.5162 38.2983 11.9823 38.2983H14.7387C14.8202 38.2983 14.8863 38.2322 14.8863 38.1507Z"
        fill="#A573FE"
      />
      <path
        d="M16.5205 38.1507V29.4688L18.2906 30.0815V35.9864L19.7809 37.4079C20.1182 37.7296 19.8905 38.2983 19.4244 38.2983H16.668C16.5865 38.2983 16.5205 38.2322 16.5205 38.1507Z"
        fill="#A573FE"
      />
      <path
        d="M7.60889 10.9883C7.60889 8.22686 9.84746 5.98828 12.6089 5.98828H18.847C21.6084 5.98828 23.847 8.22686 23.847 10.9883V27.1786C23.847 30.357 21.2704 32.9336 18.092 32.9336H13.3639C10.1855 32.9336 7.60889 30.357 7.60889 27.1786V10.9883Z"
        fill="#A573FE"
      />
      <path
        d="M5.90479 0.720786C5.90479 4.86342 9.26305 8.22168 13.4057 8.22168H21.2726C21.2726 4.07905 17.9143 0.720786 13.7717 0.720786L5.90479 0.720786Z"
        fill="#F8DF2A"
      />
      <path
        d="M7.5332 24.9707H23.9123V31.2632C23.9123 33.0544 22.4602 34.5065 20.669 34.5065H10.7765C8.98528 34.5065 7.5332 33.0544 7.5332 31.2632V24.9707Z"
        fill="#FF3B79"
      />
      <path
        d="M12.7622 18.3271C13.3333 18.9426 14.4171 19.358 15.66 19.358C16.903 19.358 17.9868 18.9426 18.5579 18.3271"
        stroke="black"
        strokeWidth={0.674679}
        strokeLinecap="round"
      />
      <path
        d="M7.74395 25.6806C6.83591 28.1905 6.42394 30.5226 6.56934 31.8887"
        stroke="#FF3B79"
        strokeWidth={0.877885}
        strokeLinecap="round"
      />
      <path
        d="M23.6672 25.7363C24.5752 28.2461 24.9872 30.5782 24.8418 31.9443"
        stroke="#FF3B79"
        strokeWidth={0.877885}
        strokeLinecap="round"
      />
      <ellipse cx={12.9569} cy={14.2755} rx={2.39386} ry={2.44838} fill="white" />
      <ellipse cx={12.9899} cy={14.3366} rx={0.944946} ry={0.966467} fill="black" />
      <ellipse cx={18.8787} cy={14.2755} rx={2.39386} ry={2.44838} fill="white" />
      <ellipse cx={18.9117} cy={14.3366} rx={0.944946} ry={0.966467} fill="black" />
      <path
        d="M16.0131 25.3275C15.8672 25.2104 15.8672 24.9884 16.0131 24.8712L17.6155 23.5844C17.807 23.4306 18.0914 23.5669 18.0914 23.8125L18.0914 26.3862C18.0914 26.6319 17.807 26.7682 17.6155 26.6144L16.0131 25.3275Z"
        fill="black"
      />
      <path
        d="M15.9488 25.3275C16.0947 25.2104 16.0947 24.9884 15.9488 24.8712L14.3464 23.5844C14.1549 23.4306 13.8705 23.5669 13.8705 23.8125L13.8705 26.3862C13.8705 26.6319 14.1549 26.7682 14.3464 26.6144L15.9488 25.3275Z"
        fill="black"
      />
    </g>
    <path
      d="M5.1665 0.720786C5.1665 4.86342 8.52477 8.22168 12.6674 8.22168H20.5343C20.5343 4.07905 17.176 0.720786 13.0334 0.720786L5.1665 0.720786Z"
      fill="#F8DF2A"
    />
  </svg>
);
export default UserLogo;
