import { InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import styles from './input.module.scss';

import { styled } from '@mui/material/styles';

const Input = (props) => {
  const {
    value,
    setValue,
    placeholder,
    className,
    startIcon,
    endIcon,
    handleIconClick,
    onEnterPress,
    handleClick,
    labelColor = '#ffffff',
    labelColorFocused = '#ffffff',
    isError,
    error,
    setErrors,
    search,
    ...rest
  } = props;

  const CssTextField = useMemo(
    () =>
      styled(TextField)({
        '& label': {
          backgroundColor: labelColor,
          color: '#7B8795',
        },
        '& label.Mui-focused': {
          color: '#1F2933',
          marginTop: '2px',

          backgroundColor: labelColorFocused,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#CBD2D9',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#CBD2D9',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#1F2933',
            borderWidth: '1px',
          },
        },
        '.MuiFormHelperText-root': {
          fontSize: '12px',
        },
      }),
    [],
  );

  const handleChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (onEnterPress) onEnterPress();
    }
  };

  return (
    <div className={cx(styles.container)}>
      <CssTextField
        onClick={handleClick}
        label={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyPress}
        variant="outlined"
        className={cx(styles.input, className)}
        color="info"
        error={error}
        setER={setErrors}
        InputLabelProps={{
          classes: { root: styles.label, focused: styles.focused },
        }}
        inputProps={{
          style: { fontSize: '1.4rem', lineHeight: '1.4rem' },
        }}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className={styles.icon} onClick={handleIconClick}>
                {endIcon}
              </div>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      {/* {error && <div className={styles.error}>{error}</div>} */}
    </div>
  );
};

export default Input;
