export const ICONS = {
  MAN: 'Man',
  EYE: 'Eye',
  ADDLEAD: 'AddLead',
  DASHBOAD: 'Dashboard',
  USERS: 'Users',
  LEADS: 'Leads',
  PAYMENT: 'Payment',
  SEARCH: 'Search',
  DELETE: 'Delete',
  PENCIL: 'Pencil',
  EXCEL: 'Excel',
  USERLOGO: 'UserLogo',
  RIGHTARROW: 'RightArrow',
  SUCCESSFULLYDELETED: 'successfullyDeleted',
  ERROR: 'Error',
  ERRORBLACK: 'ErrorBlack',
  LEADSUCCESS: 'LeadSuccess',
  PAYMENTSUCCESS: 'PaymentSuccess',
  SUCCESS: 'Success',
  CELEBRATIONS: 'Celebrations',
  USER_LOGO_LARGE: 'UserLogoLarge',
  PARTNER: 'Partner',
  CLOSEEYE: 'CloseEye',
  LEFTARROW: 'LeftArrow',
  DOWNARROW: 'DownArrow',
  CALENDAR: 'Calendar',
  ADD_LEAD_PHOTO: 'Add Lead Photo',
  LOGO: 'Logo',
  REPORT: 'Report',
  DROPDOWN: 'DropDown',
  DROPARROW: 'DropArrow',
};
