/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { filter, forEach, get, head, isEmpty, map } from 'lodash';

import { Navbar, UserDeleteConfirmationModal } from '../../components';
import LeftSideDrawerWrapper from '../../components/leftSideDrawerWrapper/LeftSideDrawerWrapper';
import UpperLayer from '../../components/upperLayer/UpperLayer';
import SideDrawerContent from '../../components/sideDrawerContent/SideDrawerContent';
import Table from '../../components/Table/Table';
import PublishLeadPopUp from '../../components/publishLeadPopUp/PublishLeadPopUp';
import ErrorPopUp from '../../components/errorPopUp/ErrorPopUp';
import UserSuccessfullyDeletedModal from '../../components/userSuccessfullyDeletedModal/UserSuccessfullyDeletedModal';
import Loader from '../../components/loader/Loader';

import leadsStore from '../../zustand/leadManagement.store';
import { getColumns } from './leadMangement.config';
import { ICONS } from '../../constants/icons.constanst';
import {
  addOrUpdateLead,
  deleteLead,
  fetchLeadsData,
  fetchUserDataAccordingToSearch,
  toggleLeadStatus,
} from '../../actions/leads.action';
import { EMPTY_LEAD, Lead } from '../../constants/types.constant';
import { getFormattedCurrentTime } from '../../helpers/generalHelpers';

import styles from './leadManagement.module.scss';

const LeadManagement: React.FC = () => {
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);

  const [editValues, setEditValues] = useState<Lead>(EMPTY_LEAD);
  const [isPublishLeadPopUp, setIsPublishLeadPopUp] = useState(false);
  const [publishLeadSuccessPopUp, setPublishLeadSuccessPopUp] = useState(false);
  const [publishLeadFailurePopup, setPublishLeadFailurePopup] = React.useState(false);
  const [updatedValues, setUpdatedValues] = useState<Lead>(EMPTY_LEAD);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState('');
  const [leadUpading, setLeadUpdaing] = useState(false);
  const [isDeletetPopUpVisible, setIsDeletetPopUpVisible] = useState(false);
  const [isLeadDeleteSuccess, setIsLeadDeleteSuccess] = useState(false);
  const [isLeadDeleteFailure, setIsLeadDeleteFailure] = useState(false);
  const [leadDeleting, setLeadDeleting] = useState(false);

  const {
    leads,
    setLeadData,
    totalLeads,
    totalLeadsToday,
    updateLead,
    // deleteLead: deleteLeadFromState,
    searchedData,
  } = leadsStore((state) => ({
    leads: state.leads,
    setLeadData: state.setLeadData,
    totalLeads: state.totalLeads,
    totalLeadsToday: state.totalLeadsToday,
    updateLead: state.updateLead,
    // deleteLead: state.deleteLead,
    searchedData: state.searchedData,
    toggleStatus: state.toggleStatus,
  }));

  //

  const filteredData = (data) => {
    let filteredData = map(data, (row) => ({
      'Company Name': get(row, 'companyName', ''),
      Category: get(row, 'category', ''),
      'Sub Category': get(row, 'subCategory', ''),
      Valuation: get(row, 'valuation', ''),
      'Register Count': get(row, 'registerCount', ''),
      'Share Count': get(row, 'shareCount', ''),
      'Clicks Count': get(row, 'clicksCount', ''),
      Status: get(row, 'enabled', '') ? 'active' : 'inactive',
    }));

    return filteredData;
  };

  const exportSelectedData = (idsArray: Array<string>) => {
    // for (let i = 0; i <= size(leads); i++) {
    //   for (let j = 0; i <= size(idsArray); j++) {
    //     if (leads.id === idsArray[j]) {
    //     }
    //   }
    // }

    // for (let i = 0; i <= size(idsArray); i++) {
    //   const
    // filter(leads, includes(idsArray, idsArray[i]) {
    //   return leads
    if (!isEmpty(idsArray)) {
      const data = map(idsArray, (selectedId) => head(filter(leads, (lead) => lead.id === selectedId)));

      return filteredData(data);
    } else {
      return filteredData(leads);
    }
  };

  const hanleEditClick = (params) => {
    setEditValues(params.row);
    setIsSideDrawerVisible(true);
  };

  const hanleDeleteClick = (params) => {
    setEditValues(params.row);
    setIsDeletetPopUpVisible(true);
  };

  const fetchLeads = async () => {
    setIsLoading(true);
    setDate(getFormattedCurrentTime());
    const response = await fetchLeadsData();
    // for (let i = 0; i < response?.data?.length; i++) {
    //   response.data[i].enabled = response.data[i].enabled === 'true';
    // }

    let localData = {};
    forEach(response?.data?.leads, (lead) => {
      localData = {
        ...localData,
        [lead.id]: lead.enabled,
      };
    });

    localStorage.setItem('leadEnabledDisabledData', JSON.stringify(localData));

    setLeadData(response?.data);
    setIsLoading(false);
  };

  const handleSearchData = async (value: string) => {
    const response = await fetchUserDataAccordingToSearch(value);

    searchedData(get(response, 'data', {}));
  };

  const handleEditLeadBtnClick = (lead: Lead) => {
    setIsSideDrawerVisible(false);
    setEditValues((prev) => ({
      ...prev,
      ...lead,
    }));
    setUpdatedValues(lead);
    //

    setIsPublishLeadPopUp(true);
  };

  const handleDeleteLeadBtnClick = () => {
    setIsSideDrawerVisible(false);
    setIsDeletetPopUpVisible(true);
  };

  const handlePublishLeadClick = async () => {
    setLeadUpdaing(true);
    const data = await addOrUpdateLead({ ...editValues, ...updatedValues });
    updateLead({ ...data });
    setLeadUpdaing(false);
    setIsPublishLeadPopUp(false);
    if (!isEmpty(data)) {
      setPublishLeadFailurePopup(false);
      setPublishLeadSuccessPopUp(true);
    } else {
      setPublishLeadFailurePopup(true);
    }
  };

  const handleEditLeadClick = () => {
    setIsPublishLeadPopUp(false);
    setIsSideDrawerVisible(true);
  };

  const handleToggleLeadStatus = async (leadId: string, enabled: boolean) => {
    await toggleLeadStatus(leadId, enabled);
    // if (isSuccess) toggleStatus(leadId, enabled);
  };

  const handleDeleteLead = async () => {
    setLeadDeleting(true);
    const isSuccess = await deleteLead(editValues.id);
    if (isSuccess) {
      // deleteLeadFromState(editValues.id);
      // fetchLeads();
      const response = await fetchLeadsData();
      setLeadData(response?.data);
      setIsDeletetPopUpVisible(false);
      setLeadDeleting(false);
      setIsLeadDeleteFailure(false);
      setIsLeadDeleteSuccess(true);
    } else {
      setIsDeletetPopUpVisible(false);
      setLeadDeleting(false);
      setIsLeadDeleteFailure(true);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchLeads();
    })();
  }, []);

  const columns: any = getColumns({ hanleEditClick, hanleDeleteClick, handleToggleLeadStatus });

  // const renderTable = useCallback(
  //   () => (
  //     <Table
  //       rows={leads}
  //       column={columns}
  //       setIsSideDrawerVisible={setIsSideDrawerVisible}
  //       setIsDeletetPopUpVisible={setIsDeletetPopUpVisible}
  //       setEditValues={setEditValues}
  //       keepSearchToLeft={true}
  //       fileName="leads"
  //       handleSearchData={handleSearchData}
  //       exportSelectedData={exportSelectedData}
  //       searchPlaceHodler="חפש לידים"
  //     />
  //   ),
  //   [columns, exportSelectedData, handleSearchData, leads],
  // );

  return (
    <div className={styles.leadManagementContainer}>
      <div style={{ width: '90px' }}>
        <Navbar />
      </div>

      <div className={styles.upperLayer}>
        <UpperLayer
          setIsSideDrawerVisible={setIsSideDrawerVisible}
          setIsDeletetPopUpVisible={setIsDeletetPopUpVisible}
          heading=" ניהול לידים"
          asOfDate="נכון לתאריך"
          date={date}
          totalLeads=" סה״כ לידים"
          newLeads=" לידים חדשים (יומי)"
          leadNumber={totalLeads}
          newLeadNumber={totalLeadsToday}
        />

        <div className={styles.table}>
          <Table
            rows={leads}
            column={columns}
            setIsSideDrawerVisible={setIsSideDrawerVisible}
            setIsDeletetPopUpVisible={setIsDeletetPopUpVisible}
            setEditValues={setEditValues}
            keepSearchToLeft={true}
            fileName="leads"
            handleSearchData={handleSearchData}
            exportSelectedData={exportSelectedData}
            searchPlaceHodler="חפש לידים"
          />
        </div>
      </div>

      <LeftSideDrawerWrapper
        isVisible={isSideDrawerVisible}
        setIsVisible={setIsSideDrawerVisible}
        selectedUserData={editValues}
        heading={editValues?.companyName}
      >
        <SideDrawerContent
          initalFormValues={{ ...editValues }}
          handlePrimaryClick={handleEditLeadBtnClick}
          handleSecondaryClick={handleDeleteLeadBtnClick}
          clearDataOnCancel={false}
        />
      </LeftSideDrawerWrapper>

      {/* Delete Lead Modal */}
      <UserDeleteConfirmationModal
        title="מחיקת ליד"
        isVisible={isDeletetPopUpVisible}
        setIsVisible={setIsDeletetPopUpVisible}
        handlePrimaryButtonClick={handleDeleteLead}
        handleSecondaryButtonClick={() => {
          setIsDeletetPopUpVisible(false);
        }}
        loading={leadDeleting}
        content={'האם אתה בטוח שברצונך למחוק?'}
      />
      {/* Delete Lead Success Modal */}
      <UserSuccessfullyDeletedModal
        isVisible={isLeadDeleteSuccess}
        setIsVisible={setIsLeadDeleteSuccess}
        content="נמחק בהצלחה"
      />
      {/* Delete Lead Failure Modal */}
      <UserDeleteConfirmationModal
        isVisible={isLeadDeleteFailure}
        setIsVisible={setIsLeadDeleteFailure}
        handlePrimaryButtonClick={handleDeleteLead}
        handleSecondaryButtonClick={() => {
          setIsLeadDeleteFailure(false);
        }}
        loading={leadDeleting}
        content={'הפעולה נכשלה \nנסה שנית'}
        secondaryButtonText="נסה שנית"
        primaryButtonText="סגור"
      />

      {/* Publish Lead Modal */}
      <PublishLeadPopUp
        isPublishLeadPopUp={isPublishLeadPopUp}
        setIsPublishLeadPopUp={setIsPublishLeadPopUp}
        leadData={editValues}
        handleEditLeadClick={handleEditLeadClick}
        handlePublishLeadClick={handlePublishLeadClick}
        isLoading={leadUpading}
      />
      {/* Publish Lead Success Modal */}
      <ErrorPopUp
        icon={ICONS.SUCCESS}
        title={'הליד פורסם בהצלחה\n ויופיע ברשימת הלידים באפליקצייה'}
        description={'באפשרותך לערוך את הליד בכל זמן\n בעמוד הלידים'}
        primaryButtonLabel={'סגור'}
        hasPrimaryButton
        visible={publishLeadSuccessPopUp}
        setVisible={setPublishLeadSuccessPopUp}
        handlePrimaryClick={() => {
          setPublishLeadSuccessPopUp(false);
        }}
      />
      {/* Publish Lead Failure Modal */}
      <ErrorPopUp
        icon={ICONS.ERROR}
        title={'קרתה תקלה...'}
        description={'הליד לא פורסם נסה שנית'}
        hasPrimaryButton
        primaryButtonLabel={'נסה שנית'}
        hasSecondaryButton
        secondaryButtonLabel={'ביטול'}
        visible={publishLeadFailurePopup}
        setVisible={setPublishLeadFailurePopup}
        handleSecondaryClick={() => {
          setPublishLeadFailurePopup(false);
        }}
        handlePrimaryClick={handlePublishLeadClick}
        isLoading={leadUpading}
      />

      <Loader isLoading={isLoading} />
    </div>
  );
};

export default LeadManagement;
