import React from 'react';

import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ICONS } from '../../constants/icons.constanst';
import LOGo from '../../assests/logo.png';
import Logo from '../../assests/logo3.png';
import NavbarIcons from '../navbarIcons/NavbarIcons';
import { ROUTES } from '../../constants/routes.constants';
import { useStore } from 'zustand';
import newLeadStore from '../../zustand/newLead.store';

import styles from './Navbar.module.scss';

const Navbar = () => {
  const navigate = useNavigate();

  const handleClick = (id) => () => {
    navigate(id);
  };

  const { setIsSideDrawerOpen } = useStore(newLeadStore);

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <img className={styles.image} src={LOGo} alt="" />
        <img className={styles.image1} src={Logo} alt="" />
      </div>

      <div className={cx(styles.navbarIconsContainer)}>
        <NavbarIcons
          type={ICONS.ADDLEAD}
          name="הוסף ליד"
          // path={ROUTES.HOMEPAGE}
          handleClick={() => {
            setIsSideDrawerOpen(true);
          }}
        />
        <NavbarIcons
          type={ICONS.DASHBOAD}
          name=" דשבורד"
          path={ROUTES.HOMEPAGE}
          handleClick={handleClick(ROUTES.HOMEPAGE)}
        />
        <NavbarIcons
          type={ICONS.USERS}
          name="משתשים"
          path={ROUTES.USER_MANAGEMENT}
          handleClick={handleClick(ROUTES.USER_MANAGEMENT)}
        />
        <NavbarIcons
          type={ICONS.LEADS}
          name="לידים"
          path={ROUTES.LEAD_MANAGEMENT}
          handleClick={handleClick(ROUTES.LEAD_MANAGEMENT)}
        />
        <NavbarIcons
          type={ICONS.PAYMENT}
          name="תשלומים"
          path={ROUTES.PAYMENT_TRANSFER}
          handleClick={handleClick(ROUTES.PAYMENT_TRANSFER)}
        />
        <NavbarIcons
          type={ICONS.REPORT}
          name="דיווחים"
          path={ROUTES.REPORTS}
          handleClick={handleClick(ROUTES.REPORTS)}
        />
      </div>
    </div>
  );
};

export default Navbar;
