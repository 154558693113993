import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isLoading: false,
  isAuthenticated: false,
  loginFail: '',
  email: '',
  error: '',
  data: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,

  reducers: {
    loginStart: (state) => ({
      ...state,
      isLoading: true,
      loginFail: '',
    }),

    loginSuccess: (state, action) => ({
      ...state,
      data: action.payload,
      email: action.payload.email,
      isLoading: false,
      isAuthenticated: true,
      loginFail: '',
    }),

    loginFail: (state) => ({
      ...state,
      isLoading: false,
      loginFail: 'האימייל או הסיסמה אינם נכונים',
    }),

    userInfoStart: (state) => ({
      ...state,
      isLoading: true,
    }),

    userInfoSuccess: (state, action) => ({
      ...state,
      data: action.payload,
      email: action.payload.data.email,
      isLoading: false,
      isAuthenticated: true,
      loginFail: '',
    }),

    userInfoFail: (state) => ({
      ...state,
      isLoading: false,
      error: 'not able to fetch',
    }),
    userFlush: () => INITIAL_STATE,
  },
});

export const { loginStart, loginSuccess, loginFail, userInfoStart, userInfoSuccess, userInfoFail, userFlush } =
  userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
