export const ROUTES = {
  HOMEPAGE: '/',
  LOGIN: 'login',
  USER_MANAGEMENT: '/user-management',
  LEAD_MANAGEMENT: '/lead-management',
  PAYMENT_TRANSFER: '/payment-transfer',
  FORGOT_PASSWORD: '/forgot-Password',
  PHONE_VERIFICATION: '/phone-verification',
  REPORTS: '/reports',
};
