/* eslint-disable max-len */
import * as React from 'react';

const SVGComponent = (props) => (
  <svg width={32} height={27} viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.6667 0H11.3333C10.9797 0 10.6406 0.140476 10.3905 0.390524C10.1405 0.640573 10 0.979711 10 1.33333V3.33333H12.6667V2.66667H19.3333V7.17333H18C18 7.17333 18 7.28 18 7.33333V9.74667H19.3333V14.2533H18V16.8267H19.3333V21.3333H17.44C17.091 21.9379 16.5898 22.4406 15.9863 22.7914C15.3829 23.1423 14.6981 23.3291 14 23.3333H10V25.3333C10 25.687 10.1405 26.0261 10.3905 26.2761C10.6406 26.5262 10.9797 26.6667 11.3333 26.6667H30.6667C31.0203 26.6667 31.3594 26.5262 31.6095 26.2761C31.8595 26.0261 32 25.687 32 25.3333V1.33333C32 0.979711 31.8595 0.640573 31.6095 0.390524C31.3594 0.140476 31.0203 0 30.6667 0ZM29.3333 21.3333H22.6667V16.8267H29.3333V21.3333ZM29.3333 14.2533H22.6667V9.74667H29.3333V14.2533ZM29.3333 7.17333H22.6667V2.66667H29.3333V7.17333Z"
      fill="#7B8795"
    />
    <path
      d="M12.6667 21.333H14C14.5304 21.333 15.0391 21.1223 15.4142 20.7472C15.7893 20.3721 16 19.8634 16 19.333V7.33301C16 6.80257 15.7893 6.29387 15.4142 5.91879C15.0391 5.54372 14.5304 5.33301 14 5.33301H2C1.46957 5.33301 0.960859 5.54372 0.585786 5.91879C0.210714 6.29387 0 6.80257 0 7.33301L0 19.333C0 19.8634 0.210714 20.3721 0.585786 20.7472C0.960859 21.1223 1.46957 21.333 2 21.333H12.6667ZM6.26667 8.66634L8 11.4397L9.73333 8.66634H12.0933L9.17333 13.333L12.0933 17.9997H9.73333L8 15.2263L6.26667 17.9997H3.90667L6.82667 13.333L3.90667 8.66634H6.26667Z"
      fill="#CBD2D9"
    />
  </svg>
);
export default SVGComponent;
