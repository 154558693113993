import { userMangementServices } from '../redux/userManagement/userManagement.services';

export const fetchUserData = async () => {
  try {
    const response = await userMangementServices.getUserManagementData();

    return response;
    // yield put(addManagementData(response.data.users));
  } catch (err) {
    // console.log(err);
  }
};

// axios(`/v1/admin/users?searchkey=${searchKey}`)

export const fetchUserDataAccordingToSearch = async (searchKey) => {
  try {
    const response = await userMangementServices.getUserManagementDataAccordingToSeacrh(searchKey);

    return response;
    // yield put(addManagementData(response.data.users));
  } catch (err) {
    // console.log(err);
  }
};

export const deleteUser = async (payload) => {
  try {
    await userMangementServices.deleteUserManagementData(payload);
    return true;
  } catch (err) {
    // console.log(err, 'errr');
    return false;
  }
};

export const updateUserAction = async (payload) => {
  try {
    const updatedPayload = {
      firstname: payload.firstname,
      lastname: payload.lastname,
      identity_id: payload.identity_id,
      phone: payload.phone,
      email: payload.email,
      birthday: payload.birthday,
      id: payload.id,
    };

    // console.log(updatedPayload, 'opop');

    const response = await userMangementServices.updateUserManagementData(updatedPayload);

    //   yield put(deleteManagementDataSuccess(payload.updatedData));
    return response;
  } catch (err) {
    // console.log(err);
  }
};
