/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import { filter, get, head, isEmpty, map } from 'lodash';

import UpperLayer from '../../components/upperLayer/UpperLayer';
import { Navbar, UserDeleteConfirmationModal } from '../../components';
import Table from '../../components/Table/Table';
import { ICONS } from '../../constants/icons.constanst';
import MoneyTransferPopUP from '../../components/moneyTransferPopUP/MoneyTransferPopUP';
import ErrorPopUp from '../../components/errorPopUp/ErrorPopUp';
import { getColumns } from './paymentTransfer.config';
import Loader from '../../components/loader/Loader';
import withAuth from '../../hoc/withAuth';
import {
  fetchPaymentTransferData,
  paymentSuccess,
  paymentDeclined,
  paymentLimit,
  paymentsChargeReq,
} from '../../actions/paymentTransfer.action';
import { getFormattedCurrentTime } from '../../helpers/generalHelpers';

import paymentTransferStore from '../../zustand/paymentTransfer.store';

import styles from './paymentTransfer.module.scss';
import snackBarStore from '../../zustand/snackBarStore';

const PaymentTransfer: React.FC = () => {
  const [limit, setLimit] = useState(0);
  const [openPaymentTransferPopUp, setOpenPaymentTransferPopUp] = useState(false);
  const [openPaymentSuccessfullyTransferPopUp, setOpenPaymentSuccessfullyTransferPopUp] = useState(false);
  const [moneyTrabsferErrorPopUp, setMoneyTrabsferErrorPopUp] = useState(false);
  const [refusalMoneytransferPopUp, setRefusalMoneytransferPopUp] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [total_balance, setTotal_balance] = useState(0);
  const [total_payed, setTotal_payed] = useState(0);
  const [date, setDate] = useState('');

  const columns = getColumns({ setData, setOpenPaymentTransferPopUp, setRefusalMoneytransferPopUp });

  const { payments, setPaymentData } = paymentTransferStore((state) => state);
  const { showSnackBar } = snackBarStore();

  //
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setDate(getFormattedCurrentTime());

      const { paymentData, paymentLimit, total_balance, total_payed } = await fetchPaymentTransferData();
      setPaymentData(paymentData);
      setLimit(paymentLimit);
      setTotal_balance(total_balance);
      setTotal_payed(total_payed);
      setIsLoading(false);
    })();
  }, [refresh]);

  //

  const handleUpdatePaymentLimit = async (value) => {
    setIsLoading(true);
    await paymentLimit(value);
    setIsLoading(false);
  };

  //

  const handleSearchData = async (val) => {
    const { paymentData, paymentLimit, total_balance, total_payed } = await fetchPaymentTransferData(val);
    setPaymentData(paymentData);
    setLimit(paymentLimit);
    setTotal_balance(total_balance);
    setTotal_payed(total_payed);
  };

  const status = {
    1: 'ממתין',
    2: 'שולם',
    3: 'סורב',
  };

  const filteredData = (data) => {
    let filteredData = map(data, (row) => ({
      'שם פרטי': get(row, 'firstname', ''),
      'שם משפחה': get(row, 'lastname', ''),
      תַאֲרִיך: get(row, 'date', ''),
      טלפון: get(row, 'phone', ''),
      'סכום לתשלום': get(row, 'sum', ''),
      'מצב תשלום': status[get(row, 'status', '')],
    }));

    return filteredData;
  };

  const exportSelectedData = (idsArray: Array<string>) => {
    if (!isEmpty(idsArray)) {
      const data = map(idsArray, (selectedId) => head(filter(payments, (payment) => payment?.id === selectedId)));

      return filteredData(data);
    } else {
      return filteredData(payments);
    }
  };

  const handlePaymentRefusal = async () => {
    setIsLoading1(true);
    const success = await paymentDeclined(get(data, 'id', ''));
    setIsLoading1(false);

    if (success) {
      filter(payments, (item) => {
        if (item.id === get(data, 'id', '')) {
          // eslint-disable-next-line no-param-reassign
          item.status = 3;
        }
      });
      setPaymentData(payments);
    }

    setRefusalMoneytransferPopUp(false);
  };

  const handleTransferPayment = async () => {
    setIsLoading1(true);
    const success = await paymentSuccess(get(data, 'id', ''));
    setIsLoading1(false);

    if (success) {
      filter(payments, (item) => {
        if (item.id === get(data, 'id', '')) {
          // eslint-disable-next-line no-param-reassign
          item.status = 2;
        }
      });
      setPaymentData(payments);
    }

    return success;
  };

  const handleSetPaymentCharge = async (selectedDataIds) => {
    setIsLoading(true);

    const payload = filter(
      selectedDataIds,
      (selectedId) => filter(payments, (item) => item.id === selectedId && item.status === 1).length > 0,
    );

    if (isEmpty(payload)) {
      setIsLoading(false);
      // alert('אנא בחר תשלום');
      return;
    }

    const { data, error } = await paymentsChargeReq(payload);
    if (error) showSnackBar(error);
    else {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      //
      link.href = url;
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      link.setAttribute('download', `publico-masav-${day}-${month}-${year}.001`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }

    setIsLoading(false);
    setRefresh((v) => !v);
  };

  return (
    <div className={styles.paymentTransfer}>
      <div style={{ width: '90px' }}>
        <Navbar />
      </div>

      <div className={styles.mainPage}>
        <UpperLayer
          heading="ניהול תשלומים"
          asOfDate="נכון לתאריך"
          date={date}
          totalLeads="סה״כ שולם"
          newLeads="יתרת תשלום"
          leadNumber={total_payed}
          newLeadNumber={total_balance}
        />

        <Table
          rows={payments}
          column={columns}
          handleSearchData={handleSearchData}
          searchRightSideData={true}
          fileName="payments"
          exportSelectedData={exportSelectedData}
          searchPlaceHodler="חפש תשלום"
          value={limit}
          onChange={handleUpdatePaymentLimit}
          setPaymentCharge={handleSetPaymentCharge}
        />
      </div>

      <MoneyTransferPopUP
        dateContent={get(data, 'row.date', '')}
        amount={get(data, 'row.sum', '')}
        userName1={get(data, 'row.username', '')}
        setOpenPaymentTransferPopUp={setOpenPaymentTransferPopUp}
        openPaymentTransferPopUp={openPaymentTransferPopUp}
        setOpenPaymentSuccessfullyTransferPopUp={setOpenPaymentSuccessfullyTransferPopUp}
        handleTransferPayment={handleTransferPayment}
        setMoneyTrabsferErrorPopUp={setMoneyTrabsferErrorPopUp}
        isLoading={isLoading1}
      />

      <ErrorPopUp
        icon={ICONS.PAYMENTSUCCESS}
        title={'הכסף הועבר בהצלחה!'}
        description={''}
        hasPrimaryButton
        primaryButtonLabel={'סגור'}
        titleStyle={styles.titleStyle}
        primaryButtonClassName={styles.buttonStyle}
        setVisible={setOpenPaymentSuccessfullyTransferPopUp}
        visible={openPaymentSuccessfullyTransferPopUp}
      />

      <ErrorPopUp
        icon={ICONS.ERRORBLACK}
        title={'הפעולה נכשלה'}
        description={'נסה שנית'}
        hasPrimaryButton
        hasSecondaryButton
        secondaryButtonLabel={'העבר תשלום'}
        primaryButtonLabel={'ביטול'}
        visible={moneyTrabsferErrorPopUp}
        setVisible={setMoneyTrabsferErrorPopUp}
        handleTransferPayment={handleTransferPayment}
        setOpenPaymentTransferPopUp={setOpenPaymentTransferPopUp}
      />

      <UserDeleteConfirmationModal
        setIsVisible={setRefusalMoneytransferPopUp}
        isVisible={refusalMoneytransferPopUp}
        title={'סירוב תשלום'}
        content={'האם אתה בטוח לסרב להעביר את\n  התשלום למנחם אברהם?'}
        primaryButtonText={'ביטול'}
        secondaryButtonText={'סירוב תשלום'}
        handleSecondaryButtonClick={() => setRefusalMoneytransferPopUp(false)}
        handlePrimaryButtonClick={handlePaymentRefusal}
        loading={isLoading1}
      />

      <Loader isLoading={isLoading} />
    </div>
  );
};

export default withAuth(PaymentTransfer);
