/* eslint-disable max-len */
import * as React from 'react';

const Celebrations = (props) => (
  <svg width={216} height={147} viewBox="0 0 216 147" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={110.5} cy={103.279} r={43} fill="#3FE2D2" />
    <mask
      id="mask0_1113_35887"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={67}
      y={60}
      width={87}
      height={87}
    >
      <circle cx={110.5} cy={103.279} r={43} fill="white" />
    </mask>
    <g mask="url(#mask0_1113_35887)">
      <path
        d="M108.797 160.47V136.617L103.887 138.294V154.448L99.1062 158.944C98.4084 159.6 98.8729 160.772 99.8309 160.772H108.495C108.662 160.772 108.797 160.637 108.797 160.47Z"
        fill="#A573FE"
      />
      <path
        d="M113.331 160.47V136.617L118.241 138.294V154.448L123.022 158.944C123.72 159.6 123.255 160.772 122.297 160.772H113.633C113.466 160.772 113.331 160.637 113.331 160.47Z"
        fill="#A573FE"
      />
      <path
        d="M88.6079 82.6189C88.6079 76.9646 93.1917 72.3809 98.846 72.3809H123.417C129.072 72.3809 133.656 76.9646 133.656 82.619V125.473C133.656 136.862 124.423 146.095 113.033 146.095H109.23C97.8408 146.095 88.6079 136.862 88.6079 125.473V82.6189Z"
        fill="#A573FE"
      />
      <path
        d="M83.8809 57.972C83.8809 69.305 93.0681 78.4922 104.401 78.4922H126.514V78.4922C126.514 67.1592 117.327 57.972 105.994 57.972H83.8809V57.972Z"
        fill="#F8DF2A"
      />
      <path
        d="M88.3979 124.311H133.837V143.757C133.837 147.424 130.863 150.398 127.196 150.398H95.039C91.3712 150.398 88.3979 147.424 88.3979 143.757V124.311Z"
        fill="#FF3B79"
      />
      <path
        d="M102.904 106.139C104.489 107.822 107.495 108.959 110.943 108.959C114.392 108.959 117.398 107.822 118.983 106.139"
        stroke="black"
        strokeWidth={2.4176}
        strokeLinecap="round"
      />
      <path
        d="M88.9832 126.257C86.4641 133.123 85.3212 139.503 85.7246 143.24"
        stroke="#FF3B79"
        strokeWidth={3.14575}
        strokeLinecap="round"
      />
      <path
        d="M133.156 126.409C135.676 133.275 136.818 139.655 136.415 143.393"
        stroke="#FF3B79"
        strokeWidth={3.14575}
        strokeLinecap="round"
      />
      <ellipse cx={103.446} cy={95.0516} rx={6.64104} ry={6.69804} fill="white" />
      <ellipse cx={103.537} cy={95.2162} rx={2.62146} ry={2.64396} fill="black" />
      <ellipse cx={119.873} cy={95.0516} rx={6.64104} ry={6.69804} fill="white" />
      <ellipse cx={119.965} cy={95.2162} rx={2.62146} ry={2.64396} fill="black" />
      <path
        d="M112.174 125.487C111.644 125.067 111.644 124.263 112.174 123.843L115.99 120.821C116.677 120.277 117.689 120.766 117.689 121.643L117.689 127.687C117.689 128.564 116.677 129.054 115.99 128.509L112.174 125.487Z"
        fill="black"
      />
      <path
        d="M111.496 125.487C112.026 125.067 112.026 124.263 111.496 123.843L107.68 120.821C106.992 120.277 105.98 120.766 105.98 121.643L105.98 127.687C105.98 128.564 106.992 129.054 107.68 128.509L111.496 125.487Z"
        fill="black"
      />
    </g>
    <path
      d="M81.8335 57.972C81.8335 69.305 91.0207 78.4922 102.354 78.4922H124.467V78.4922C124.467 67.1592 115.279 57.972 103.946 57.972H81.8335V57.972Z"
      fill="#F8DF2A"
    />
    <path
      d="M215.943 89.4717C215.943 89.4717 216.917 79.6823 195.163 69L185 82.7506C185 82.7506 196.315 87.2151 204.757 97.3291L212.501 94.2933L215.943 89.4717Z"
      fill="#68FFD2"
    />
    <path
      d="M208.572 90.6071L200.747 104.406C200.747 104.406 201.802 105.624 205.682 103.887L215.926 89.4707C215.943 89.4707 213.264 93.302 208.572 90.6071Z"
      fill="#3FE2D2"
    />
    <path
      d="M15.5286 90.6763C15.5286 90.6763 19.3256 109.557 40.1606 106.647L35.4911 89.1744C35.4911 89.1744 24.4011 100.335 16.0159 83.5153L14.2699 85.082L15.5286 90.6763Z"
      fill="#68FFD2"
    />
    <path
      d="M15.5286 90.6759C13.6056 83.1107 19.4953 79.1232 19.4953 79.1232L15.1245 65C12.5379 72.0592 12.3019 79.759 14.4254 86.9599L15.5286 90.6759Z"
      fill="#3FE2D2"
    />
    <path
      d="M145.454 33.2999C145.454 33.2999 150.345 38.1381 158.979 30.8809C167.614 23.6237 177.043 21.1516 182.34 23.8709L166.678 13.4177C166.678 13.4177 157.69 10.8574 147.625 18.4147C146.548 19.227 145.436 19.9862 144.235 20.6042C142.311 21.5754 139.538 22.7231 138.073 22.2111L142.717 31.5871L145.454 33.2999Z"
      fill="#68FFD2"
    />
    <path
      d="M143.105 25.2489L128.238 16.3496C128.238 16.3496 126.966 21.3467 130.304 23.9776L145.436 33.3007C142.063 30.811 143.088 25.4961 143.088 25.4961"
      fill="#3FE2D2"
    />
    <path
      d="M156.79 61.1101C155.686 61.7191 155 63.0631 155 64.9866C155 68.7617 157.642 73.3372 160.9 75.2063C162.525 76.1378 163.995 76.2211 165.062 75.6031C165.059 75.6051 165.055 75.6073 165.052 75.6093L168.089 73.8673C169.169 73.2479 169.838 71.913 169.838 70.0133C169.838 66.2382 167.196 61.6627 163.937 59.7936C162.318 58.8651 160.852 58.7792 159.786 59.3906L156.749 61.1326C156.762 61.1248 156.776 61.1177 156.79 61.1101Z"
      fill="#FFDE03"
    />
    <path
      d="M156.749 61.1326L159.786 59.3906C160.852 58.7792 162.319 58.8651 163.938 59.7936C167.196 61.6628 169.838 66.2382 169.838 70.0133C169.838 71.913 169.169 73.248 168.089 73.8674L165.052 75.6093C166.132 74.99 166.801 73.655 166.801 71.7553C166.801 67.9802 164.159 63.4047 160.901 61.5356C159.282 60.607 157.815 60.5211 156.749 61.1326Z"
      fill="#FFF3C4"
    />
    <path
      d="M155 64.9869C155 61.2118 157.642 59.6667 160.9 61.5358C164.159 63.4049 166.801 67.9804 166.801 71.7555C166.801 75.5306 164.159 77.0757 160.9 75.2066C157.642 73.3374 155 68.7619 155 64.9869Z"
      fill="#FFDE03"
    />
    <path
      d="M164.559 66.2268C165.589 68.0109 166.116 69.9296 166.041 71.6294C165.976 73.1095 165.439 74.2477 164.568 74.7521C163.692 75.2589 162.485 75.1636 161.169 74.4837C159.666 73.7075 158.271 72.2978 157.241 70.5145C156.211 68.7304 155.685 66.8117 155.76 65.1119C155.825 63.6318 156.362 62.4936 157.233 61.9893C158.108 61.4824 159.315 61.5777 160.632 62.2576C162.135 63.0339 163.53 64.4435 164.559 66.2268ZM157.396 70.425C158.409 72.179 159.778 73.564 161.251 74.3248C162.511 74.9757 163.657 75.0724 164.478 74.5972C165.295 74.124 165.8 73.0394 165.862 71.6215C165.935 69.9554 165.418 68.0713 164.404 66.3163C163.392 64.5623 162.023 63.1774 160.55 62.4165C159.289 61.7657 158.143 61.6689 157.323 62.1441C156.505 62.6173 156.001 63.7019 155.939 65.1198C155.865 66.786 156.383 68.6701 157.396 70.425Z"
      fill="#FFF3C4"
    />
    <path
      d="M165.069 61.0931L162.411 62.6278C162.471 62.6808 162.531 62.7348 162.59 62.7896L165.62 61.0406C165.56 60.9859 165.5 60.9319 165.44 60.8789L165.069 61.0931Z"
      fill="#FFE472"
    />
    <path
      d="M167.791 63.664C167.75 63.5992 167.708 63.5348 167.666 63.4707L164.634 65.2209C164.676 65.285 164.718 65.3494 164.759 65.4143L167.791 63.664Z"
      fill="#FFE472"
    />
    <path
      d="M162.251 59.1107C162.134 59.0839 162.019 59.0618 161.905 59.0449L158.884 60.789C158.997 60.8062 159.112 60.8289 159.229 60.8558L162.251 59.1107Z"
      fill="#FFE472"
    />
    <path
      d="M160.923 61.5485C160.999 61.5922 161.074 61.6374 161.149 61.6839L164.176 59.9361C164.101 59.8897 164.026 59.8443 163.95 59.8008L160.923 61.5485Z"
      fill="#FFE472"
    />
    <path
      d="M163.623 63.8668C163.673 63.926 163.723 63.9858 163.772 64.0461L166.803 62.2964C166.753 62.2361 166.703 62.1763 166.653 62.1172L163.623 63.8668Z"
      fill="#FFE472"
    />
    <path
      d="M166.139 68.228C166.165 68.3016 166.191 68.3753 166.216 68.4491L169.249 66.6977C169.224 66.6239 169.199 66.5502 169.172 66.4766L166.139 68.228Z"
      fill="#FFE472"
    />
    <path
      d="M165.473 66.6747C165.507 66.7434 165.541 66.8124 165.574 66.8815L168.607 65.1306C168.574 65.0615 168.54 64.9925 168.505 64.9238L165.473 66.6747Z"
      fill="#FFE472"
    />
    <path
      d="M169.355 72.4483C169.408 72.3363 169.457 72.2193 169.502 72.0977L166.46 73.8542C166.414 73.9764 166.364 74.0938 166.31 74.2062L169.355 72.4483Z"
      fill="#FFE472"
    />
    <path
      d="M166.606 69.8947C166.622 69.9746 166.637 70.0544 166.652 70.134L169.687 68.3816C169.672 68.3021 169.657 68.2224 169.641 68.1426L166.606 69.8947Z"
      fill="#FFE472"
    />
    <path
      d="M166.797 71.9877L169.835 70.2341C169.837 70.1613 169.838 70.0879 169.838 70.0132C169.838 69.9978 169.838 69.9822 169.838 69.9668L166.801 71.7201C166.801 71.7318 166.801 71.7435 166.801 71.7552C166.801 71.8338 166.8 71.9111 166.797 71.9877Z"
      fill="#FFE472"
    />
    <path
      d="M175.312 88.8536C174.684 87.7606 173.328 87.0979 171.405 87.1313C167.63 87.1968 163.101 89.9175 161.289 93.2082C160.386 94.8483 160.328 96.3202 160.965 97.3762C160.963 97.3728 160.96 97.3695 160.958 97.3662L162.753 100.372C163.391 101.441 164.737 102.087 166.637 102.054C170.411 101.989 174.94 99.2678 176.752 95.9771C177.653 94.3424 177.713 92.8745 177.083 91.8192L175.289 88.8129C175.297 88.8263 175.304 88.8401 175.312 88.8536Z"
      fill="#FFDE03"
    />
    <path
      d="M175.289 88.8124L177.083 91.8187C177.713 92.874 177.653 94.3418 176.752 95.9766C174.94 99.2673 170.411 101.988 166.636 102.054C164.737 102.087 163.391 101.441 162.753 100.372L160.958 97.3657C161.596 98.4347 162.943 99.0803 164.842 99.0474C168.617 98.9818 173.145 96.2611 174.958 92.9704C175.858 91.3357 175.919 89.8678 175.289 88.8124Z"
      fill="#FFF3C4"
    />
    <path
      d="M171.405 87.1323C175.179 87.0667 176.77 89.6812 174.958 92.9719C173.146 96.2626 168.617 98.9833 164.842 99.0489C161.068 99.1144 159.477 96.5 161.289 93.2093C163.101 89.9186 167.63 87.1978 171.405 87.1323Z"
      fill="#FFDE03"
    />
    <path
      d="M170.331 96.7104C168.565 97.7713 166.655 98.3306 164.955 98.2854C163.473 98.2461 162.326 97.729 161.807 96.8668C161.285 96.0005 161.359 94.792 162.016 93.4639C162.766 91.9475 164.151 90.5283 165.916 89.468C167.682 88.4071 169.592 87.8478 171.293 87.893C172.774 87.9323 173.921 88.4494 174.44 89.3116C174.962 90.1779 174.888 91.3864 174.231 92.7145C173.481 94.2309 172.096 95.65 170.331 96.7104ZM166.009 89.6214C164.272 90.6644 162.911 92.0572 162.176 93.5432C161.547 94.8147 161.471 95.9623 161.96 96.7745C162.447 97.5833 163.54 98.0689 164.959 98.1066C166.626 98.1509 168.501 97.6006 170.238 96.557C171.975 95.514 173.336 94.1212 174.071 92.6351C174.7 91.3637 174.776 90.2162 174.287 89.4039C173.8 88.5951 172.707 88.1096 171.288 88.0718C169.621 88.0275 167.746 88.5778 166.009 89.6214Z"
      fill="#FFF3C4"
    />
    <path
      d="M175.473 97.1322L173.893 94.5011C173.841 94.5623 173.788 94.6231 173.734 94.6836L175.535 97.6821C175.589 97.6216 175.642 97.5607 175.694 97.4994L175.473 97.1322Z"
      fill="#FFE472"
    />
    <path
      d="M172.951 99.8978C173.015 99.8555 173.079 99.8126 173.142 99.7693L171.34 96.7687C171.277 96.8119 171.213 96.8548 171.149 96.8971L172.951 99.8978Z"
      fill="#FFE472"
    />
    <path
      d="M177.407 94.2798C177.432 94.1624 177.452 94.0466 177.467 93.9326L175.67 90.9424C175.655 91.0558 175.634 91.1711 175.609 91.2878L177.407 94.2798Z"
      fill="#FFE472"
    />
    <path
      d="M174.945 92.9952C174.903 93.0714 174.859 93.1473 174.814 93.223L176.614 96.2195C176.659 96.1438 176.703 96.0679 176.745 95.9915L174.945 92.9952Z"
      fill="#FFE472"
    />
    <path
      d="M172.675 95.7335C172.617 95.7848 172.558 95.8356 172.499 95.8861L174.301 98.8859C174.36 98.8354 174.419 98.7845 174.477 98.7331L172.675 95.7335Z"
      fill="#FFE472"
    />
    <path
      d="M168.359 98.3253C168.286 98.3529 168.213 98.3799 168.139 98.4061L169.943 101.409C170.017 101.383 170.09 101.356 170.163 101.328L168.359 98.3253Z"
      fill="#FFE472"
    />
    <path
      d="M169.901 97.632C169.833 97.6676 169.764 97.7027 169.696 97.7372L171.499 100.739C171.568 100.704 171.636 100.669 171.704 100.634L169.901 97.632Z"
      fill="#FFE472"
    />
    <path
      d="M164.193 101.613C164.306 101.664 164.424 101.711 164.547 101.754L162.738 98.7427C162.615 98.6991 162.496 98.6512 162.383 98.5991L164.193 101.613Z"
      fill="#FFE472"
    />
    <path
      d="M166.699 98.8214C166.62 98.8389 166.54 98.8556 166.461 98.8712L168.266 101.875C168.345 101.86 168.424 101.843 168.504 101.825L166.699 98.8214Z"
      fill="#FFE472"
    />
    <path
      d="M164.61 99.0488L166.416 102.055C166.489 102.056 166.563 102.056 166.637 102.055C166.653 102.055 166.668 102.054 166.684 102.054L164.878 99.0476C164.866 99.0479 164.854 99.0484 164.843 99.0486C164.764 99.0499 164.687 99.0497 164.61 99.0488Z"
      fill="#FFE472"
    />
    <path
      d="M61.1412 63.9012C62.2412 63.2853 62.9188 61.937 62.9067 60.0135C62.883 56.2385 60.2127 51.6797 56.9423 49.8311C55.3123 48.9098 53.8411 48.8357 52.7781 49.4604C52.7816 49.4584 52.7848 49.4562 52.7882 49.4542L49.7623 51.2152C48.6863 51.8413 48.0258 53.1805 48.0377 55.0801C48.0614 58.8551 50.7318 63.4139 54.0022 65.2625C55.6269 66.1809 57.094 66.2576 58.1562 65.6394L61.1822 63.8785C61.1687 63.8863 61.1548 63.8935 61.1412 63.9012Z"
      fill="#FFDE03"
    />
    <path
      d="M61.1819 63.8792L58.156 65.6402C57.0937 66.2583 55.6266 66.1816 54.0019 65.2633C50.7315 63.4146 48.0612 58.8558 48.0374 55.0808C48.0255 53.1811 48.6861 51.842 49.762 51.2158L52.788 49.4549C51.712 50.081 51.0514 51.4201 51.0634 53.3198C51.0871 57.0948 53.7574 61.6536 57.0278 63.5022C58.6525 64.4207 60.1197 64.4973 61.1819 63.8792Z"
      fill="#FFF3C4"
    />
    <path
      d="M62.9064 60.0132C62.9302 63.7882 60.2982 65.3499 57.0278 63.5012C53.7574 61.6526 51.087 57.0938 51.0633 53.3188C51.0396 49.5438 53.6715 47.9822 56.9419 49.8308C60.2124 51.6794 62.8827 56.2382 62.9064 60.0132Z"
      fill="#FFDE03"
    />
    <path
      d="M53.3395 58.8325C52.2982 57.0549 51.7601 55.1395 51.8241 53.4393C51.8798 51.9588 52.4096 50.8173 53.2775 50.3074C54.1496 49.7951 55.3572 49.8828 56.6779 50.5544C58.1859 51.3213 59.5896 52.7221 60.6303 54.4989C61.6716 56.2766 62.2097 58.1919 62.1457 59.8921C62.09 61.3726 61.5602 62.5141 60.6923 63.024C59.8202 63.5363 58.6126 63.4486 57.2919 62.777C55.7839 62.0101 54.3802 60.6093 53.3395 58.8325ZM60.4759 54.5894C59.4522 52.8418 58.0746 51.4654 56.5968 50.7139C55.3323 50.071 54.1857 49.9814 53.3681 50.4617C52.5539 50.94 52.0563 52.0278 52.0029 53.446C51.9401 55.1126 52.4696 56.9934 53.4939 58.742C54.5176 60.4896 55.8952 61.866 57.373 62.6175C58.6374 63.2604 59.784 63.35 60.6016 62.8697C61.4158 62.3914 61.9134 61.3036 61.9669 59.8853C62.0296 58.2188 61.5001 56.3379 60.4759 54.5894Z"
      fill="#FFF3C4"
    />
    <path
      d="M52.863 63.9705L55.5115 62.4191C55.4508 62.3665 55.3906 62.3128 55.3307 62.2585L52.3124 64.0264C52.3724 64.0807 52.4327 64.1344 52.4934 64.187L52.863 63.9705Z"
      fill="#FFE472"
    />
    <path
      d="M50.1244 61.4183C50.166 61.4829 50.2082 61.547 50.2509 61.6108L53.2712 59.8416C53.2287 59.7778 53.1865 59.7137 53.1449 59.649L50.1244 61.4183Z"
      fill="#FFE472"
    />
    <path
      d="M55.6927 65.9359C55.8098 65.9619 55.9254 65.9833 56.0392 65.9994L59.0491 64.2364C58.9358 64.2199 58.8208 64.198 58.7044 64.1717L55.6927 65.9359Z"
      fill="#FFE472"
    />
    <path
      d="M57.0051 63.4887C56.9294 63.4455 56.8539 63.4007 56.7788 63.3546L53.7625 65.1215C53.8377 65.1674 53.9131 65.2123 53.9891 65.2553L57.0051 63.4887Z"
      fill="#FFE472"
    />
    <path
      d="M54.2917 61.1879C54.2411 61.1291 54.1909 61.0696 54.1411 61.0096L51.1216 62.7783C51.1714 62.8382 51.2216 62.8977 51.2723 62.9565L54.2917 61.1879Z"
      fill="#FFE472"
    />
    <path
      d="M51.7483 56.8443C51.7216 56.7709 51.6953 56.6973 51.67 56.6237L48.6474 58.3941C48.6727 58.4678 48.699 58.5413 48.7258 58.6148L51.7483 56.8443Z"
      fill="#FFE472"
    />
    <path
      d="M52.4236 58.3937C52.3886 58.3252 52.3544 58.2564 52.3206 58.1875L49.299 59.9574C49.3328 60.0263 49.3671 60.0951 49.4021 60.1636L52.4236 58.3937Z"
      fill="#FFE472"
    />
    <path
      d="M48.5059 52.643C48.4533 52.7554 48.405 52.8727 48.3608 52.9947L51.392 51.2191C51.4369 51.0966 51.4861 50.9789 51.5395 50.8661L48.5059 52.643Z"
      fill="#FFE472"
    />
    <path
      d="M51.2707 55.1795C51.2542 55.0998 51.2383 55.0201 51.2236 54.9405L48.1995 56.7119C48.2143 56.7914 48.2303 56.871 48.247 56.9507L51.2707 55.1795Z"
      fill="#FFE472"
    />
    <path
      d="M51.0659 53.0866L48.0396 54.8592C48.038 54.932 48.0371 55.0055 48.0376 55.0801C48.0377 55.0955 48.0382 55.1111 48.0384 55.1265L51.0641 53.3542C51.064 53.3425 51.0636 53.3308 51.0635 53.3191C51.063 53.2405 51.0641 53.1632 51.0659 53.0866Z"
      fill="#FFE472"
    />
    <path
      d="M71.8764 2.70654C70.7196 2.20552 69.2366 2.48443 67.7146 3.66054C64.7275 5.96885 62.7223 10.8569 63.2359 14.5783C63.4918 16.433 64.3252 17.6477 65.4666 18.1141C65.4629 18.1126 65.4591 18.1113 65.4555 18.1098L68.6908 19.4477C69.8412 19.9234 71.3066 19.6365 72.8098 18.4749C75.7969 16.1666 77.8021 11.2786 77.2885 7.55715C77.0334 5.70844 76.2046 4.49542 75.0689 4.02574L71.8335 2.68777C71.848 2.69373 71.8621 2.70035 71.8764 2.70654Z"
      fill="#FFDE03"
    />
    <path
      d="M71.8335 2.68874L75.0689 4.02669C76.2046 4.49637 77.0334 5.70939 77.2885 7.55817C77.8021 11.2797 75.7969 16.1676 72.8097 18.476C71.3066 19.6375 69.8412 19.9245 68.6908 19.4488L65.4555 18.1108C66.6059 18.5866 68.0712 18.2996 69.5744 17.1381C72.5616 14.8298 74.5668 9.94173 74.0532 6.22029C73.7981 4.37149 72.9693 3.15845 71.8335 2.68874Z"
      fill="#FFF3C4"
    />
    <path
      d="M67.7145 3.6599C70.7017 1.35159 73.5395 2.49713 74.0531 6.2186C74.5667 9.94004 72.5615 14.8281 69.5744 17.1364C66.5872 19.4447 63.7494 18.2992 63.2358 14.5777C62.7222 10.8562 64.7274 5.9682 67.7145 3.6599Z"
      fill="#FFDE03"
    />
    <path
      d="M72.5782 11.9824C71.7963 13.8884 70.5998 15.4779 69.2091 16.4581C67.9982 17.3117 66.7693 17.5828 65.8375 17.2019C64.9013 16.8192 64.2386 15.8059 63.9716 14.3485C63.6668 12.6844 63.9293 10.7187 64.7109 8.81361C65.4928 6.90766 66.6892 5.31817 68.0799 4.33793C69.2909 3.48437 70.5198 3.21328 71.4516 3.59416C72.3878 3.97685 73.0504 4.99019 73.3175 6.44759C73.6223 8.11167 73.3598 10.0773 72.5782 11.9824ZM64.8764 8.88154C64.1077 10.7554 63.8489 12.6854 64.1476 14.3162C64.4033 15.7115 65.0274 16.6775 65.9052 17.0363C66.7793 17.3936 67.946 17.1295 69.1061 16.3119C70.4692 15.351 71.6435 13.7894 72.4127 11.9145C73.1813 10.0407 73.4401 8.11062 73.1414 6.47979C72.8858 5.08454 72.2616 4.11857 71.3838 3.75979C70.5097 3.40249 69.3431 3.66653 68.183 4.4842C66.8198 5.44505 65.6455 7.00672 64.8764 8.88154Z"
      fill="#FFF3C4"
    />
    <path
      d="M76.9525 9.24606L74.1127 8.08108C74.1076 8.16126 74.1016 8.24167 74.0946 8.32225L77.3308 9.64985C77.3377 9.56926 77.3438 9.48878 77.3488 9.40861L76.9525 9.24606Z"
      fill="#FFE472"
    />
    <path
      d="M76.5838 12.9714C76.61 12.8992 76.6353 12.8267 76.6602 12.7542L73.4217 11.4256C73.3968 11.4982 73.3714 11.5706 73.3452 11.6429L76.5838 12.9714Z"
      fill="#FFE472"
    />
    <path
      d="M76.7988 5.80408C76.7485 5.69518 76.6953 5.5903 76.6392 5.48999L73.4119 4.16605C73.4675 4.26612 73.5198 4.3709 73.5696 4.47936L76.7988 5.80408Z"
      fill="#FFE472"
    />
    <path
      d="M74.0575 6.24443C74.0691 6.33087 74.0792 6.41802 74.0882 6.50566L77.3223 7.8324C77.3133 7.74475 77.3032 7.65756 77.2914 7.57107L74.0575 6.24443Z"
      fill="#FFE472"
    />
    <path
      d="M73.8739 9.79637C73.8578 9.87235 73.8409 9.9483 73.8234 10.0243L77.061 11.3525C77.0785 11.2765 77.0953 11.2005 77.1113 11.1245L73.8739 9.79637Z"
      fill="#FFE472"
    />
    <path
      d="M71.9618 14.4537C71.9196 14.5195 71.8771 14.585 71.8339 14.6498L75.0747 15.9793C75.1179 15.9145 75.1604 15.849 75.2025 15.7832L71.9618 14.4537Z"
      fill="#FFE472"
    />
    <path
      d="M72.784 12.9788C72.7508 13.0481 72.7168 13.117 72.6825 13.1857L75.9223 14.5148C75.9566 14.4461 75.9905 14.3771 76.0237 14.3078L72.784 12.9788Z"
      fill="#FFE472"
    />
    <path
      d="M70.5876 19.5807C70.7089 19.5543 70.8314 19.5216 70.9552 19.4828L67.7051 18.1495C67.5804 18.1881 67.457 18.2204 67.335 18.2463L70.5876 19.5807Z"
      fill="#FFE472"
    />
    <path
      d="M70.9275 15.8448C70.8742 15.9064 70.8204 15.9673 70.7661 16.0272L74.0086 17.3574C74.0628 17.2974 74.1164 17.2364 74.1696 17.1748L70.9275 15.8448Z"
      fill="#FFE472"
    />
    <path
      d="M69.3887 17.2748L72.6335 18.6059C72.6924 18.563 72.7513 18.5192 72.8104 18.4736C72.8226 18.4641 72.8347 18.4543 72.8468 18.4448L69.6026 17.1139C69.5934 17.1211 69.5843 17.1285 69.575 17.1356C69.5129 17.1837 69.4507 17.2297 69.3887 17.2748Z"
      fill="#FFE472"
    />
    <path
      d="M96.0001 34.8889C95.8417 34.8889 95.6988 34.7828 95.6381 34.6204C94.82 32.4258 94.217 31.7556 92.2419 30.8464C92.0956 30.779 92 30.6203 92 30.4444C92 30.2684 92.0956 30.1097 92.2419 30.0423C94.217 29.133 94.8198 28.4629 95.6381 26.2685C95.6988 26.1058 95.8417 26 96.0001 26C96.1585 26 96.3013 26.106 96.3619 26.2685C97.18 28.4631 97.7832 29.133 99.7583 30.0423C99.9044 30.1095 100 30.2684 100 30.4444C100 30.6203 99.9046 30.779 99.7583 30.8464C97.783 31.7556 97.18 32.4256 96.3619 34.6204C96.3013 34.7828 96.1585 34.8889 96.0001 34.8889Z"
      fill="white"
    />
    <path
      d="M32.0001 34.8889C31.8417 34.8889 31.6988 34.7828 31.6381 34.6204C30.82 32.4258 30.217 31.7556 28.2419 30.8464C28.0956 30.779 28 30.6203 28 30.4444C28 30.2684 28.0956 30.1097 28.2419 30.0423C30.217 29.133 30.8198 28.4629 31.6381 26.2685C31.6988 26.1058 31.8417 26 32.0001 26C32.1585 26 32.3013 26.106 32.3619 26.2685C33.18 28.4631 33.7832 29.133 35.7583 30.0423C35.9044 30.1095 36 30.2684 36 30.4444C36 30.6203 35.9046 30.779 35.7583 30.8464C33.783 31.7556 33.18 32.4256 32.3619 34.6204C32.3013 34.7828 32.1585 34.8889 32.0001 34.8889Z"
      fill="white"
    />
    <path
      d="M185 53.8889C184.842 53.8889 184.699 53.7828 184.638 53.6204C183.82 51.4258 183.217 50.7556 181.242 49.8464C181.096 49.779 181 49.6203 181 49.4444C181 49.2684 181.096 49.1097 181.242 49.0423C183.217 48.133 183.82 47.4629 184.638 45.2685C184.699 45.1058 184.842 45 185 45C185.158 45 185.301 45.106 185.362 45.2685C186.18 47.4631 186.783 48.133 188.758 49.0423C188.904 49.1095 189 49.2684 189 49.4444C189 49.6203 188.905 49.779 188.758 49.8464C186.783 50.7556 186.18 51.4256 185.362 53.6204C185.301 53.7828 185.158 53.8889 185 53.8889Z"
      fill="white"
    />
    <path
      d="M125 44.6667C124.881 44.6667 124.774 44.5871 124.729 44.4653C124.115 42.8193 123.663 42.3167 122.181 41.6348C122.072 41.5842 122 41.4652 122 41.3333C122 41.2013 122.072 41.0823 122.181 41.0317C123.663 40.3498 124.115 39.8472 124.729 38.2014C124.774 38.0794 124.881 38 125 38C125.119 38 125.226 38.0795 125.271 38.2014C125.885 39.8473 126.337 40.3498 127.819 41.0317C127.928 41.0821 128 41.2013 128 41.3333C128 41.4652 127.928 41.5842 127.819 41.6348C126.337 42.3167 125.885 42.8192 125.271 44.4653C125.226 44.5871 125.119 44.6667 125 44.6667Z"
      fill="white"
    />
    <path
      d="M123 8.66667C122.881 8.66667 122.774 8.58713 122.729 8.46527C122.115 6.81932 121.663 6.31674 120.181 5.63482C120.072 5.58425 120 5.46524 120 5.33333C120 5.20127 120.072 5.08227 120.181 5.03169C121.663 4.34978 122.115 3.8472 122.729 2.20139C122.774 2.07939 122.881 2 123 2C123.119 2 123.226 2.07954 123.271 2.20139C123.885 3.84735 124.337 4.34978 125.819 5.03169C125.928 5.08212 126 5.20127 126 5.33333C126 5.46524 125.928 5.58425 125.819 5.63482C124.337 6.31674 123.885 6.81917 123.271 8.46527C123.226 8.58713 123.119 8.66667 123 8.66667Z"
      fill="white"
    />
    <path
      d="M63.0001 88.6667C62.8813 88.6667 62.7741 88.5871 62.7285 88.4653C62.115 86.8193 61.6627 86.3167 60.1814 85.6348C60.0717 85.5842 60 85.4652 60 85.3333C60 85.2013 60.0717 85.0823 60.1814 85.0317C61.6627 84.3498 62.1149 83.8472 62.7285 82.2014C62.7741 82.0794 62.8813 82 63.0001 82C63.1189 82 63.226 82.0795 63.2715 82.2014C63.885 83.8473 64.3374 84.3498 65.8187 85.0317C65.9283 85.0821 66 85.2013 66 85.3333C66 85.4652 65.9284 85.5842 65.8187 85.6348C64.3373 86.3167 63.885 86.8192 63.2715 88.4653C63.226 88.5871 63.1189 88.6667 63.0001 88.6667Z"
      fill="white"
    />
  </svg>
);
export default Celebrations;
