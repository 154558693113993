import { create } from 'zustand';
import { Dashboard } from '../constants/types.constant';
import { getFormattedDashboard, getFormattedLeaddata } from '../helpers/dashboard.helpers';

interface DASH_BOARD {
  dashboardData: Dashboard;
  setDashboardData: (data: Dashboard) => void;
  setLeadData: (data: Dashboard) => void;
}

const useDashboardStore = create<DASH_BOARD>((set) => ({
  dashboardData: {},
  setDashboardData: (data) => {
    set((state) => ({
      ...state,
      dashboardData: getFormattedDashboard(data),
    }));
  },
  setLeadData: (data) => {
    set((state) => ({
      ...state,
      dashboardData: {
        ...state.dashboardData,
        ...getFormattedLeaddata(data),
      },
    }));
  },
}));

export default useDashboardStore;
