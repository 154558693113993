import axios from '../../axios';

const fetchLeadData = () => axios.get('/v1/admin/leads');
const fetchLeadsDataAccordingToSeacrh = (searchKey) => axios.get(`/v1/admin/leads?searchkey=${searchKey}`);

const deleteLead = (id) => axios.delete(`/v1/admin/lead/${id}`);

const addOrUpdateLead = (payload) => axios.put('/v1/admin/lead', payload);

const addLeadPhoto = (id, payload) => axios.put(`/v1/admin/lead/${id}/photo`, payload);

const toggleLeadStatus = (id, payload) => axios.put(`/v1/admin/lead/${id}/enabled`, payload);

export const leadsService = {
  fetchLeadData,
  deleteLead,
  addOrUpdateLead,
  addLeadPhoto,
  toggleLeadStatus,
  fetchLeadsDataAccordingToSeacrh,
};
