/* eslint-disable max-len */
import * as React from 'react';

const successfullyDelted = (props) => (
  <svg width={86} height={105} viewBox="0 0 86 105" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M84.667 72.6159C84.7067 72.4625 84.745 72.308 84.783 72.1533C84.7454 72.308 84.7062 72.4619 84.667 72.6159Z"
      fill="#BFE5FE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2516 28.3034C16.7547 27.8605 26.8511 18.9717 43.0001 18.9717C66.7483 18.9717 86.0002 38.2235 86 61.9718C86 66.7045 85.2109 70.2621 85.0497 70.9884C85.0342 71.0582 85.0246 71.1019 85.0219 71.1179C84.9629 71.3907 84.8007 72.0795 84.7829 72.153C84.7455 72.3077 84.7061 72.4617 84.6669 72.6156C82.0315 82.963 75.6452 91.8117 67.0408 97.6266L22.4862 99.7725C9.09149 92.488 0 78.2917 0 61.9718C0 48.3453 6.33989 36.2013 16.229 28.3229L16.2319 28.3206C16.2319 28.3206 16.2319 28.3206 16.2319 28.3205C16.2326 28.3202 16.2392 28.3144 16.2516 28.3034Z"
      fill="#F0F2F4"
    />
    <path
      d="M82.1612 1C76.9939 3.97033 72.6148 8.13741 69.3919 13.151C66.169 18.1647 64.1962 23.8787 63.6396 29.8128C61.7523 48.6928 80.399 51.7151 84.2202 42.1612C88.3363 31.87 69.8128 21.5806 55.4074 29.8128C41.0019 38.0451 41 56.5667 41 56.5667"
      stroke="#1F2933"
      strokeWidth={1.86672}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3.73 7.47"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.8247 101.112C60.8678 101.092 60.9108 101.073 60.9538 101.053C63.0817 100.073 65.1159 98.9251 67.0392 97.6251L68.9944 53.6467C69.1443 50.2752 66.4514 47.46 63.0766 47.46H44.7459H44.0881H20.1606L22.4863 99.7724C28.5833 103.088 35.5717 104.972 43.0001 104.972C49.3593 104.972 55.3945 103.59 60.8247 101.112Z"
      fill="#CBD2D9"
    />
    <path
      d="M41.928 29.5331L22.4222 49.0389L12.8105 39.4273C10.3722 36.989 10.3722 33.0214 12.8105 30.5831L23.4722 19.9215C25.9104 17.4832 29.878 17.4832 32.3163 19.9215L41.928 29.5331ZM22.4222 41.7845L34.6735 29.5331L28.689 23.5486C28.2507 23.1103 27.5376 23.1103 27.0993 23.5486L16.4377 34.2103C15.9994 34.6486 15.9994 35.3617 16.4377 35.8L22.4222 41.7845Z"
      fill="#CBD2D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.547 48.2067C26.5465 48.2063 26.5457 48.2063 26.5453 48.2067L16.1322 58.6198C15.2209 59.5311 13.7434 59.5311 12.8321 58.6198L7.60072 53.3884C6.68941 52.4771 6.68941 50.9996 7.60072 50.0883L19.1598 38.5292C19.1603 38.5287 19.161 38.5287 19.1615 38.5292C19.162 38.5297 19.1628 38.5297 19.1632 38.5292L42.9728 14.7196C43.8841 13.8083 45.3617 13.8083 46.273 14.7196L51.5043 19.951C52.4156 20.8623 52.4156 22.3398 51.5043 23.2511L26.5487 48.2067C26.5482 48.2072 26.5475 48.2072 26.547 48.2067Z"
      fill="#7B8795"
    />
    <path
      d="M44.7151 101.086C43.0625 101.086 41.7229 99.7459 41.7229 98.0934V61.3799C41.7229 59.7273 43.0626 58.3877 44.7151 58.3877C46.3678 58.3877 47.7074 59.7274 47.7074 61.3799V98.0934C47.7074 99.7459 46.3678 101.086 44.7151 101.086Z"
      fill="#7B8795"
    />
    <path
      d="M32.8035 101.086C31.2211 101.086 29.8995 99.8448 29.8175 98.2466L27.9366 61.5331C27.8521 59.8826 29.1215 58.4762 30.7718 58.3916C32.4258 58.3078 33.8288 59.5767 33.9134 61.2269L35.7943 97.9403C35.8788 99.5908 34.6094 100.997 32.9591 101.082C32.9069 101.085 32.8549 101.086 32.8035 101.086Z"
      fill="#7B8795"
    />
    <path
      d="M56.9177 101.084C56.8659 101.084 56.8143 101.083 56.7621 101.08C55.1116 100.996 53.8423 99.5889 53.9267 97.9386L55.8076 61.2251C55.8922 59.5746 57.2974 58.3054 58.9492 58.3897C60.5997 58.4742 61.8689 59.8809 61.7846 61.5312L59.9036 98.2447C59.8216 99.843 58.4999 101.084 56.9177 101.084Z"
      fill="#7B8795"
    />
  </svg>
);
export default successfullyDelted;
