import React, { memo, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { get } from 'lodash';

import { ROUTES } from '../../constants/routes.constants';
import Icon from '../icon';
import { ICONS } from '../../constants/icons.constanst';
import useDashboardStore from '../../zustand/dashboard.store';
import { activityChartInfo } from '../../helpers/dashboard.helpers';
import { numberWithCommas } from '../../helpers/generalHelpers';

import styles from './activityGraph.module.scss';

const ActivityGraph = (props) => {
  const { date } = props;
  const navigate = useNavigate();
  const dashboardData = useDashboardStore((state) => state.dashboardData);
  const navigateLead = () => {
    navigate(ROUTES.USER_MANAGEMENT);
  };
  //
  const [isGraphLoaded, setIsGraphLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsGraphLoaded(true);
    }, 2000);
  }, []);

  // console.log(dashboardData, 'oiuy');

  // const totalClicksCount = get(dashboardData, 'totalClicksCount', 0);
  // const totalSharesCount = get(dashboardData, 'totalSharesCount', 0);
  // const totalRegisterCount = get(dashboardData, 'totalRegisterCount', 0);

  // const min = Math.min(totalClicksCount, totalSharesCount, totalRegisterCount);
  // const max = Math.max(totalClicksCount, totalSharesCount, totalRegisterCount);
  const activityGraph = useMemo(() => activityChartInfo(get(dashboardData, 'graphReport', {})), [dashboardData]);

  return (
    <div className={styles.activityGraph}>
      <div className={styles.leftLayer}>
        <div className={styles.heading}>
          <p className={styles.general}>פעילות כללית</p>
          <p className={styles.date}>{`יום ג׳, ${date} `}</p>
        </div>

        <div className={styles.graphPoints}>
          <div className={styles.point}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.dot} style={{ background: '#7B8795' }}></div>
              <p className={styles.number}>{numberWithCommas(get(dashboardData, 'totalSharesCount', 0))}</p>
            </div>
            <p className={styles.text}>כמות שיתופים</p>
          </div>

          <div className={styles.point}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.dot} style={{ background: '#CBD2D9' }}></div>
              <p className={styles.number}>{numberWithCommas(get(dashboardData, 'totalClicksCount', 0))}</p>
            </div>
            <p className={styles.text}>כמות לחיצות</p>
          </div>

          <div className={styles.point}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={styles.dot} style={{ background: '#1F2933' }}></div>
              <p className={styles.number}>{numberWithCommas(get(dashboardData, 'totalRegisterCount', 0))}</p>
            </div>
            <p className={styles.text}>כמות רשומים</p>
          </div>
        </div>
      </div>

      <div className={styles.rightLayer}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '30px', cursor: 'pointer' }}
          onClick={navigateLead}
        >
          <p className={styles.leads}>ניהול לידים</p>
          <Icon type={ICONS.LEFTARROW} />
        </div>

        <div className={styles.graph}>
          {isGraphLoaded && (
            <ReactApexChart options={activityGraph.options} series={activityGraph.series} type="line" height={230} />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ActivityGraph);
