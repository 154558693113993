import React from 'react';
import Icon from '../icon';
import cx from 'classnames';
import styles from './NavbarIcons.module.scss';
import { useLocation } from 'react-router-dom';

interface Props {
  type: string;
  name: string;
  path?: string;
  handleClick: () => void;
}

const NavbarIcons: React.FC<Props> = (props) => {
  const { type, name, path, handleClick } = props;
  const location = useLocation();

  return (
    <div
      className={cx(styles.navbarIconsContainer, { [styles.active]: path === location.pathname })}
      onClick={handleClick}
    >
      <Icon type={type} />
      <p className={styles.iconName}>{name}</p>
    </div>
  );
};

export default NavbarIcons;
