/* eslint-disable max-len */
import * as React from 'react';

const AddLeadPhotoIcon = (props) => (
  <svg width={77} height={80} viewBox="0 0 77 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={38.5} cy={38.5} r={38.3218} fill="#CBD2D9" stroke="#F6F7FB" strokeWidth={0.356481} />
    <path
      d="M15.43 50.9843C16.0187 56.487 20.4443 60.9126 25.9441 61.5256C30.0292 61.9809 34.2236 62.372 38.4999 62.372C42.7763 62.372 46.9707 61.9809 51.0556 61.5256C56.5556 60.9126 60.9812 56.487 61.5698 50.9843C62.0044 46.9224 62.3715 42.7521 62.3715 38.5004C62.3715 34.2488 62.0044 30.0785 61.5698 26.0165C60.9812 20.514 56.5556 16.0884 51.0556 15.4753C46.9707 15.02 42.7763 14.6289 38.4999 14.6289C34.2236 14.6289 30.0292 15.02 25.9441 15.4753C20.4443 16.0884 16.0187 20.514 15.43 26.0165C14.9954 30.0785 14.6284 34.2488 14.6284 38.5004C14.6284 42.7521 14.9954 46.9224 15.43 50.9843Z"
      fill="white"
    />
    <path
      d="M15.43 50.9843C16.0187 56.487 20.4443 60.9126 25.9441 61.5256C30.0292 61.9809 34.2236 62.372 38.4999 62.372C42.7763 62.372 46.9707 61.9809 51.0556 61.5256C56.5556 60.9126 60.9812 56.487 61.5698 50.9843C62.0044 46.9224 62.3715 42.7521 62.3715 38.5004C62.3715 34.2488 62.0044 30.0785 61.5698 26.0165C60.9812 20.514 56.5556 16.0884 51.0556 15.4753C46.9707 15.02 42.7763 14.6289 38.4999 14.6289C34.2236 14.6289 30.0292 15.02 25.9441 15.4753C20.4443 16.0884 16.0187 20.514 15.43 26.0165C14.9954 30.0785 14.6284 34.2488 14.6284 38.5004C14.6284 42.7521 14.9954 46.9224 15.43 50.9843Z"
      stroke="#CBD2D9"
      strokeWidth={1.78241}
    />
    <path
      d="M45.184 36.5907C48.8755 36.5907 51.8681 33.5982 51.8681 29.9067C51.8681 26.2152 48.8755 23.2227 45.184 23.2227C41.4925 23.2227 38.5 26.2152 38.5 29.9067C38.5 33.5982 41.4925 36.5907 45.184 36.5907Z"
      fill="#F6F7FB"
      stroke="#CBD2D9"
      strokeWidth={1.78241}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.944 61.525C20.4442 60.912 16.0186 56.4864 15.4299 50.9837C15.0728 47.6462 14.7613 44.2354 14.6616 40.7665C16.2169 40.5248 17.7893 40.4053 19.3644 40.4095C26.0275 40.2589 32.5274 42.4805 37.7043 46.678C42.5056 50.5708 45.8793 55.9284 47.3247 61.9111C44.4292 62.1808 41.4845 62.3714 38.4999 62.3714C34.2234 62.3714 30.0291 61.9803 25.944 61.525Z"
      fill="#F6F7FB"
      stroke="#CBD2D9"
      strokeWidth={1.78241}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.3248 61.9115C48.578 61.7946 49.8219 61.6629 51.0556 61.5254C56.5556 60.9123 60.9812 56.4867 61.5698 50.9841C61.6351 50.3733 61.6993 49.7599 61.7604 49.1442C59.1513 48.4166 56.4529 48.0474 53.7396 48.0489C49.6296 48.04 45.5612 48.8718 41.7847 50.4933V50.7469C44.432 53.9926 46.3327 57.8048 47.3248 61.9115Z"
      fill="#F6F7FB"
      stroke="#CBD2D9"
      strokeWidth={1.78241}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={60} cy={70} r={9.5} fill="#CBD2D9" stroke="#F6F7FB" />
    <path d="M66.7353 70.2426H54.25" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M60.4927 64V76.4853" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default AddLeadPhotoIcon;
