/* eslint-disable max-len */
import * as React from 'react';

const UserLogoLarge = (props) => (
  <svg width={54} height={59} viewBox="0 0 54 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx={27} cy={30.8979} rx={27} ry={28.102} fill="#3FE2D2" />
    <mask
      id="mask0_957_11615"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={2}
      width={54}
      height={57}
    >
      <ellipse cx={27} cy={30.8979} rx={27} ry={28.102} fill="white" />
    </mask>
    <g mask="url(#mask0_957_11615)">
      <path
        d="M25.9305 68.325V52.6865L22.847 53.7821V64.3394L19.5297 67.587C19.1989 67.9107 19.4282 68.4726 19.891 68.4726H25.7829C25.8644 68.4726 25.9305 68.4066 25.9305 68.325Z"
        fill="#A573FE"
      />
      <path
        d="M28.777 68.325V52.6865L31.8605 53.7821V64.3394L35.1778 67.587C35.5086 67.9107 35.2793 68.4726 34.8165 68.4726H28.9246C28.8431 68.4726 28.777 68.4066 28.777 68.325Z"
        fill="#A573FE"
      />
      <path
        d="M13.2539 15.7051C13.2539 12.9437 15.4925 10.7051 18.2539 10.7051H36.5396C39.301 10.7051 41.5396 12.9437 41.5396 15.7051V53.125C41.5396 56.3034 38.963 58.88 35.7846 58.88H19.0089C15.8305 58.88 13.2539 56.3034 13.2539 53.125V15.7051Z"
        fill="#A573FE"
      />
      <path
        d="M10.2856 1.28853C10.2856 8.69505 16.2898 14.6992 23.6963 14.6992L37.0553 14.6992C37.0553 7.2927 31.0512 1.28853 23.6446 1.28853L10.2856 1.28853Z"
        fill="#F8DF2A"
      />
      <path
        d="M13.1221 44.6445H41.6533V58.4501C41.6533 60.2413 40.2013 61.6934 38.41 61.6934H16.3654C14.5741 61.6934 13.1221 60.2413 13.1221 58.4501V44.6445Z"
        fill="#FF3B79"
      />
      <path
        d="M22.2305 32.7676C23.2253 33.868 25.1131 34.6107 27.2783 34.6107C29.4435 34.6107 31.3313 33.868 32.3261 32.7676"
        stroke="black"
        strokeWidth={0.674679}
        strokeLinecap="round"
      />
      <path
        d="M13.489 45.9144C11.9072 50.4018 11.1896 54.5713 11.4429 57.0137"
        stroke="#FF3B79"
        strokeWidth={0.877885}
        strokeLinecap="round"
      />
      <path
        d="M41.2264 46.014C42.8081 50.5014 43.5257 54.6709 43.2725 57.1133"
        stroke="#FF3B79"
        strokeWidth={0.877885}
        strokeLinecap="round"
      />
      <ellipse cx={22.5699} cy={25.5229} rx={4.16995} ry={4.37741} fill="white" />
      <ellipse cx={22.6275} cy={25.6303} rx={1.64603} ry={1.72792} fill="black" />
      <ellipse cx={32.8853} cy={25.5219} rx={4.16995} ry={4.37741} fill="white" />
      <ellipse cx={32.9424} cy={25.6303} rx={1.64603} ry={1.72792} fill="black" />
      <path
        d="M27.6729 45.1002C27.5309 44.9831 27.5309 44.7656 27.6729 44.6486L31.0353 41.8771C31.2261 41.7198 31.514 41.8555 31.514 42.1029L31.514 47.6459C31.514 47.8932 31.2261 48.029 31.0353 47.8717L27.6729 45.1002Z"
        fill="black"
      />
      <path
        d="M28.0029 45.1002C28.1449 44.9831 28.1449 44.7656 28.0029 44.6486L24.6405 41.8771C24.4496 41.7198 24.1618 41.8555 24.1618 42.1029L24.1618 47.6459C24.1618 47.8932 24.4496 48.029 24.6405 47.8717L28.0029 45.1002Z"
        fill="black"
      />
    </g>
    <path
      d="M9 1.28853C9 8.69505 15.0042 14.6992 22.4107 14.6992L35.7697 14.6992C35.7697 7.2927 29.7655 1.28853 22.359 1.28853L9 1.28853Z"
      fill="#F8DF2A"
    />
  </svg>
);
export default UserLogoLarge;
