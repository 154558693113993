import React from 'react';
import cx from 'classnames';

import styles from './popUpWrapper.module.scss';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  className?: string;
  dismissable?: boolean;
}

const PopUpWrapper: React.FC<Props> = ({ className, children, isVisible, setIsVisible, dismissable = true }) => {
  const hidePopUp = () => {
    if (dismissable) setIsVisible(!isVisible);
  };

  return (
    <div className={cx({ [styles.hidePopUp]: !isVisible, [styles.popUpWrapper]: isVisible })}>
      <div className={styles.background} onClick={hidePopUp}></div>
      <div className={cx(styles.popUp, className)}>{children}</div>
    </div>
  );
};

export default PopUpWrapper;
