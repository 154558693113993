import axios from '../../axios';

//ye dekhna
const fetchData = (val) =>
  axios.get('/v1/admin/payments', {
    params: {
      searchkey: val,
    },
  });

const paymentDeclined = (paymentId) =>
  axios.post('/v1/admin/payments/decline', {
    ids: [paymentId],
  });

const paymentSuccess = (paymentId) =>
  axios.post('/v1/admin/payments/sent', {
    ids: [paymentId],
  });

const paymentLimit = (value) =>
  axios.post('/v1/admin/config/limit-payment', {
    limit: value,
  });

const paymentsCharge = (ids) =>
  axios.post('/v1/admin/payments/charge', {
    ids,
  });

export const PaymentrService = { fetchData, paymentDeclined, paymentSuccess, paymentLimit, paymentsCharge };
