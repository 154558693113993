import axios from '../axios';

const fetchData = (linkID) => axios.get(`/v1/link/${linkID}`);

const fetchToken = (updatedMobileNumber, session_id) =>
  axios.post(`/v1/link/${session_id}/code`, {
    phone: updatedMobileNumber,
  });

const validateOtp = (token, session_id, otp) =>
  axios.post(`/v1/link/${session_id}/verify-code`, {
    token: token,
    code: otp,
  });

export const phoneVerificationServices = { fetchData, fetchToken, validateOtp };
