export interface Lead {
  companyName?: string;
  category?: string;
  subCategory?: string;
  valuation?: string;
  link?: string;
  description?: string;
  id?: string;
  registerCount?: number;
  shareCount?: number;
  clicksCount?: number;
  photo?: string;
  budget?: string;
  enabled?: boolean;
  budgetUsed?: number;
  createdAt?: number;
  visibleLeadAvatar?: string; //* this will be converted into base-64 strings and will be displayed
  leadAvatar?: FormData; //* this will be submitted to backend
}

export const EMPTY_LEAD = {
  companyName: '',
  category: '',
  subCategory: '',
  valuation: '',
  link: '',
  description: '',
  id: '',
  registerCount: 0,
  shareCount: 0,
  clicksCount: 0,
  photo: '',
  budget: '',
  enabled: false,
  budgetUsed: 0,
  createdAt: 0,
  visibleLeadAvatar: '',
  leadAvatar: null,
};

export interface Dashboard {
  leads?: {
    activeCount?: number;
    inactiveCount?: number;
  };
  payments?: {
    total?: number;
    payed?: number;
  };
  popularLeads?: Array<Lead>;
  totalClicksCount?: number;
  totalRegisterCount?: number;
  totalUsersCount?: number;
  totalSharesCount?: number;

  users?: Array<{
    week?: string;
    count?: number;
  }>;
}

export interface Payments {
  id: string;
  sum: string;
  status: number;
  date: string;
  firstname: string;
  lastname: string;
  username: string;
  phone: number;
}

export interface Table {
  field?: string;
  headerName?: string;
  headerClassName?: string;
  cellClassName?: string;
  headerAlign?: string;
  align?: string;
  type?: string;
  width?: number;
  renderCell?: undefined;
}
