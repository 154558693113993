import { leadsService } from './leads.service';
import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteLeadSuccess, leadsDataFetchSuccess, updateLeadsDataSucccess } from './leads.slice';

export function* addLeadsDataRequestStart({ payload }) {
  try {
    const response = yield call(leadsService.fetchLeadData);
    // console.log(response, 'response');

    // yield put(setData(response.data));
    yield put(leadsDataFetchSuccess(response.data));
  } catch (err) {
    // console.log(err);
  }
}

export function* updateLeadsDataRequestStart({ payload }) {
  try {
    // const response = yield call(leadsService.fetchLeadData);
    // console.log(response, 'response');

    // yield put(setData(response.data));
    yield put(updateLeadsDataSucccess(payload));
  } catch (err) {
    // console.log(err);
  }
}

export function* deleteLeadsRequestStart({ payload }) {
  try {
    // const response = yield call(leadsService.fetchLeadData);
    // console.log(response, 'response');

    // yield put(setData(response.data));
    yield put(deleteLeadSuccess(payload));
  } catch (err) {
    // console.log(err);
  }
}

const leadsSagas = [
  takeLatest('leads/leadsDataFetchStart', addLeadsDataRequestStart),
  takeLatest('leads/updateLeadsStart', updateLeadsDataRequestStart),
  takeLatest('leads/deleteLeadStart', deleteLeadsRequestStart),
];

export default leadsSagas;
