import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../../axios';
import userServcies from '../user/user.services';
import { loginFail, loginSuccess, userInfoFail, userInfoSuccess } from './user.slice';

export function* loginRequestStart({ payload }) {
  try {
    const response = yield call(userServcies.loginUser, payload);

    if (response.data.status === 1) {
      // localStorage.setItem('token', JSON.stringify(response.data.tokens));
      localStorage.setItem('refresh-token', response.data.tokens.refresh_token);
      localStorage.setItem('access-token', response.data.tokens.access_token);

      axios.defaults.headers['Authorization'] = 'Bearer ' + response.data.tokens.access_token;

      yield put(loginSuccess(payload));
    } else {
      yield put(loginFail());
    }
  } catch (err) {
    // console.log(err);
  }
}

export function* userInfoFetchStart({ payload }) {
  try {
    const response = yield call(userServcies.userInfo);

    if (response.request.status === 200) {
      yield put(userInfoSuccess(response));
    } else {
      yield put(userInfoFail());
    }
  } catch (err) {
    // console.log(err);
  }
}

const userSagas = [
  takeLatest('user/loginStart', loginRequestStart),
  takeLatest('user/userInfoStart', userInfoFetchStart),
];

export default userSagas;
