import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import hotPic from '../../assests/userImg.jpeg';

import styles from './userWithAvatarItem.module.scss';
import { BASE_URL } from '../../axios';

interface Props {
  data?: object;
  iconType?: string;
  activeLeads?: string;
  date?: string;
  styleOuterBox?: string;
  styleLine1?: string;
  startingUp?: string;
  styleLine2?: string;
  level2?: string;
  userName?: string;
  itemBoxUpdate?: string;
  userNameUpdate?: string;
  line?: string;
  imageSrc?: string;
  onClick?: (e) => void;
  id?: string;
  degree?: number;
  userCreatedAt?: number;
}

const getDegreeMaping = (degree: number) => {
  if (degree <= 3) {
    return 'מתחיל';
  }
  if (degree <= 6) {
    return 'מתקדם';
  }
  return 'מומחה';
};

const UserWithAvatarItem: React.FC<Props> = (props) => {
  const {
    activeLeads,
    styleOuterBox,
    styleLine1,
    styleLine2,
    level2,
    userName,
    itemBoxUpdate,
    userNameUpdate,
    imageSrc,
    id,
    degree,
    onClick,
    userCreatedAt,
  } = props;

  const [fallbackImage, setFallbackImage] = useState(hotPic);

  useEffect(() => {
    if (imageSrc) {
      setFallbackImage(`${BASE_URL}/v1/photos/leads/${imageSrc}`);
    } else {
      setFallbackImage(hotPic);
    }
  }, [imageSrc]);

  const handleFallbackImage = () => {
    setFallbackImage(hotPic);
  };

  return (
    <div className={cx(styles.itemBox, itemBoxUpdate)} onClick={onClick} id={id}>
      <div className={styles.img}>
        <img
          src={fallbackImage}
          alt="is Loading"
          crossOrigin="anonymous"
          onError={handleFallbackImage}
          style={{ objectFit: 'contain' }}
        />
      </div>

      <div className={cx(styles.box, styleOuterBox)}>
        {!userName && (
          <p className={cx(styles.line1, styleLine1)}>
            <span className={styles.line1Text1}>{getDegreeMaping(degree)}</span> {level2}
          </p>
        )}

        <p className={cx(userNameUpdate, styles.userName)}> {userName}</p>
        <p className={cx(styles.line2, styleLine2)}>
          {activeLeads}
          {userCreatedAt && <p className={cx({ [styles.date]: userCreatedAt })}>{userCreatedAt}</p>}
        </p>
      </div>
    </div>
  );
};

UserWithAvatarItem.defaultProps = {
  activeLeads: 'תאריך הצטרפות:',
};

export default UserWithAvatarItem;
