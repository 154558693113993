import { create } from 'zustand';
import { filter, map } from 'lodash';

import { Lead } from '../constants/types.constant';
import { getFormattedLeads } from '../helpers/leads.helpers';

interface LEADS {
  leads: Array<Lead>;
  isLoading: boolean;
  totalLeads: number;
  totalLeadsToday: number;
  setLeadData: (data: { leads: Array<Lead>; total_leads: number; total_leads_today: number }) => void;
  searchedData: (data: { leads: Array<Lead> }) => void;
  deleteLead: (id: string) => void;
  addLead: (lead: Lead) => void;
  updateLead: (lead: Lead) => void;
  toggleStatus: (id: string, status: boolean) => void;
}

const leadsStore = create<LEADS>((set) => ({
  leads: [],
  isLoading: false,
  totalLeads: 0,
  totalLeadsToday: 0,

  setLeadData: (data) => {
    set((state) => ({
      ...state,
      leads: getFormattedLeads(data.leads),
      totalLeads: data.total_leads,
      totalLeadsToday: data.total_leads_today,
    }));
  },

  searchedData: (data) => {
    set((state) => ({
      ...state,
      leads: getFormattedLeads(data.leads),
    }));
  },

  deleteLead: (id) => {
    set((state) => {
      const updatedLeadsData = filter(state.leads, (lead) => lead.id !== id);
      return {
        ...state,
        isLoading: false,
        leads: updatedLeadsData,
      };
    });
  },

  addLead: (lead) => {
    set((state) => {
      const updatedLeads = [lead, ...state.leads];
      const updatedTotalLeads = state.totalLeads + 1;
      const updateTotalLeadsToday = state.totalLeadsToday + 1;
      return {
        ...state,
        leads: updatedLeads,
        totalLeads: updatedTotalLeads,
        totalLeadsToday: updateTotalLeadsToday,
      };
    });
  },

  updateLead: (lead) => {
    set((state) => {
      const updatedLeads = map(state.leads, (l) => {
        if (l.id === lead.id) {
          return lead;
        }
        return l;
      });
      return {
        ...state,
        leads: updatedLeads,
      };
    });
  },

  toggleStatus: (id, status) => {
    set((state) => {
      const updatedLeads = map(state.leads, (lead) => {
        if (lead.id === id) {
          return {
            ...lead,
            enabled: status,
          };
        }
        return lead;
      });

      return {
        ...state,
        leads: updatedLeads,
      };
    });
  },

  // updateLead: ({ leadId, keyToBeUpdated, newValue }) => {
  //   set((state) => {
  //     const updatedLeadsData = map(state.leads.leadsData, (lead) => {
  //       if (lead.id === leadId) {
  //         return {
  //           ...lead,
  //           [keyToBeUpdated]: newValue,
  //         };
  //       }
  //       return lead;
  //     });
  //     return {
  //       leads: {
  //         ...state.leads,
  //         isLoading: false,
  //         leadsData: updatedLeadsData,
  //       },
  //     };
  //   });
  // },
}));

export default leadsStore;
