import leadsReducer from './leads/leads.slice';
import userManagementReducer from './userManagement/userManagement.slice';
import paymentTansferReducer from './paymentTransfer/paymentTransfer.slice';
import userReducer from './user/user.slice';

const rootReducer = {
  userManagement: userManagementReducer,
  leads: leadsReducer,
  paymentTransfer: paymentTansferReducer,
  user: userReducer,
};

export default rootReducer;
