/* eslint-disable arrow-body-style */
import { filter, map } from 'lodash';

// * input: arr and data to be deleted
// * outout: updated array
export const deleteRow = (arr, data) => {
  // * Using Filter
  return filter(arr, (item) => item?.id !== data?.id);

  //* using logic
  //   const res = [];
  //   for (let i = 0; i < size(arr); i++) {
  //     if (arr[i]?.id !== data?.id) {
  //       res.push(arr[i]);
  //     }
  //   }
  //   return res;
};

export const deleteUserRow = (arr, id) => {
  const updatedData = filter(arr, (user) => user.id !== id);
  return updatedData;
};

export const updateUserRow = (arr, data) => {
  const updatedData = map(arr, (item) => {
    if (item.id === data.id) {
      return data;
    }
    return item;
  });

  return updatedData;
};
