import React, { useCallback, useEffect, useState } from 'react';

import { DataGrid, GridRowClassNameParams } from '@mui/x-data-grid';
import cx from 'classnames';

import { ROWS_PER_PAGE } from './table.constants';
import Icon from '../icon';
import { ICONS } from '../../constants/icons.constanst';
import Input from '../input/Input';

import styles from './table.module.scss';
import { downloadExcel } from '../../helpers/generalHelpers';
import { Lead } from '../../constants/types.constant';
import { debounce, filter, isEmpty } from 'lodash';
import { numberWithCommas } from '../../helpers/generalHelpers';

interface Props {
  rows: Array<Lead>;
  column: any;
  pageSize?: number;
  onPageSizeChange?: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
  setIsSideDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeletetPopUpVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditValues?: React.Dispatch<React.SetStateAction<{}>>;
  searchRightSideData?: boolean;
  keepSearchToLeft?: boolean;
  fileName?: string;
  handleSearchData?: (val: string) => void;
  setSearchKey?: React.Dispatch<React.SetStateAction<string>>;
  exportSelectedData?: (selectedData: Array<string>) => void;
  searchPlaceHodler?: string;
  value?: number;
  onChange?: (val: number) => void;
  setPaymentCharge?: (selectedData: Array<string>) => void;
  // getRowClassName?:
}

const Table: React.FC<Props> = (props) => {
  const {
    rows,
    column,
    pageSize,
    onPageSizeChange,
    className,
    searchRightSideData,
    keepSearchToLeft,
    fileName = 'data',
    handleSearchData,
    exportSelectedData,
    searchPlaceHodler,
    value,
    onChange,
    setPaymentCharge,
  } = props;

  const [searchKey, setSearchKey] = useState('');
  const [selectedDataId, setSelectedDataId] = useState([]);

  //
  const [paymentLimit, setPaymentLimit] = useState(0);

  const searchWithDebounce = useCallback(
    debounce((val) => handleSearchData(val), 400),
    [],
  );

  const handleSearch = (val: string) => {
    setSearchKey(val);
    searchWithDebounce(val);
  };

  useEffect(() => {
    if (value) {
      setPaymentLimit(value);
    }
  }, [value]);

  let isMasavButtonActive = isEmpty(
    filter(
      selectedDataId,
      (selectedId) => filter(rows, (item) => item.id === selectedId && item.status === 1).length > 0,
    ),
  );

  return (
    <div className={styles.tableContainer}>
      <div className={styles.dataContainer}>
        {searchRightSideData && (
          <div className={styles.maximumPaymentContainer}>
            <p className={styles.maximumPayment}>סכום תשלום מקסימלי לחודש קלנדרי עד:</p>
            <input
              placeholder={'כמות'}
              className={styles.amountBox}
              value={numberWithCommas(paymentLimit)}
              onChange={(e) => {
                let value = e.target.value.replace(/,/g, '');
                // remove anything other than digits
                value = value.replace(/[^\d]/g, '');
                // remove leading zeros
                value = value.replace(/^0+/, '');
                // console.log(value);
                setPaymentLimit(value);
              }}
            />

            <p className={styles.approval} onClick={() => onChange(paymentLimit)}>
              אישור
            </p>

            <button
              className={cx(styles.button, {
                [styles.disabled]: isMasavButtonActive,
              })}
              onClick={() => setPaymentCharge(selectedDataId)}
            >
              מס״ב
            </button>
          </div>
        )}

        <div
          className={cx(styles.searchContainer, {
            [styles.searchForLeadPage]: keepSearchToLeft,
          })}
        >
          <div
            className={styles.iconContainer}
            onClick={() => downloadExcel(exportSelectedData(selectedDataId), fileName)}
          >
            <Icon type={ICONS.EXCEL} />
            <p className={styles.excel}>ייצוא לאקסל</p>
          </div>
          <Input
            className={cx(styles.search, className)}
            placeholder={searchPlaceHodler || 'חפש משתמש'}
            endIcon={<Icon type={ICONS.SEARCH} />}
            labelColor="#ffffff"
            labelColorFocused="#f6f7fb"
            setValue={handleSearch}
            value={searchKey}
          />
        </div>
      </div>

      <div className={styles.table}>
        <DataGrid
          rows={rows}
          columns={column}
          rowsPerPageOptions={ROWS_PER_PAGE}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          checkboxSelection
          onSelectionModelChange={(id) => {
            setSelectedDataId(id);
          }}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '.MuiDataGrid-root': {
              border: 'none',
            },
            '.MuiDataGrid-columnHeaders': {
              backgroundColor: '#f7f8f9',
            },
            '.MuiDataGrid-cellCheckbox ': {
              maxWidth: 'unset !important',
              fontSize: '50px',
            },

            border: 'none',
          }}
          getRowClassName={(
            params: GridRowClassNameParams<{
              randomNumber: number;
            }>,
          ) => {
            if (params.row) {
              if (params?.row?.randomNumber === 0) return styles.rowStyle;
            }
          }}
        />
      </div>
    </div>
  );
};

export default Table;
