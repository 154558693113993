import Icon from '../../components/icon';
import UserName from '../../components/userName/UserName';
import LeadToggle from '../../components/leadToggle/LeadToggle';
import { ICONS } from '../../constants/icons.constanst';

import styles from './leadManagement.module.scss';

export const getColumns = ({ hanleEditClick, hanleDeleteClick, handleToggleLeadStatus }) => {
  let width = window.innerWidth;
  let tableWidth = (925 * (width - 90)) / 1000;

  return [
    {
      field: 'userIcon',
      headerName: 'שם ליד',
      type: 'actions',
      align: 'left',
      headerAlign: 'left',
      headerClassName: styles.header1,
      cellClassName: styles.userIcon,
      width: 0.165 * tableWidth,

      renderCell: (params) => (
        <UserName
          icon={params.row.userIconType}
          name={params.row.companyName}
          communication={params.row.category}
          leadSource={params.row.subCategory}
          imageSrc={params.row.photo}
        />
      ),
    },

    {
      field: 'valuation',
      headerName: 'שווי',
      headerClassName: styles.header,
      cellClassName: styles.content,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 0.085 * tableWidth,
    },

    {
      field: 'registerCount',
      headerName: 'כמות רשומים',
      headerClassName: styles.header,
      cellClassName: styles.content,
      headerAlign: 'left',
      align: 'left',
      width: 0.132 * tableWidth,
    },

    {
      field: 'shareCount',
      headerName: 'כמות שיתופים',
      headerClassName: styles.header,
      cellClassName: styles.content,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 0.132 * tableWidth,
    },

    {
      field: 'clicksCount',
      headerName: 'כמות לחיצות',
      headerClassName: styles.header,
      cellClassName: styles.content,
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      width: 0.148 * tableWidth,
    },

    {
      field: 'enabled',
      headerName: 'סטטוסים',
      headerClassName: styles.header,
      headerAlign: 'left',
      align: 'left',
      type: 'actions',
      width: 0.2 * tableWidth,
      renderCell: (params) => (
        <LeadToggle
          id={params.row.id}
          active={params?.row?.enabled}
          disabled
          // row={params}
          // onChange={(status) => handleToggleLeadStatus(params.id, status)}
        />
      ),
    },

    {
      field: 'edit',
      headerClassName: styles.header,
      headerAlign: 'left',
      align: 'left',
      type: 'actions',
      width: 0.046 * tableWidth,
      renderCell: (params) => (
        <div
          className={styles.pencil}
          onClick={() => {
            hanleEditClick(params);
          }}
        >
          <Icon type={ICONS.PENCIL} />
        </div>
      ),
    },

    {
      field: 'delete',
      headerClassName: styles.header,
      headerAlign: 'left',
      align: 'left',
      type: 'actions',
      width: 0.046 * tableWidth,
      renderCell: (params) => (
        <div
          className={styles.delete}
          onClick={() => {
            hanleDeleteClick(params);
          }}
        >
          <Icon type={ICONS.DELETE} height={20} width={20} />
        </div>
      ),
    },
  ];
};
