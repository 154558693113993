import React from 'react';

import Button from '../button/Button';
import PopUpWrapper from '../popUpWrapper/PopUpWrapper';

import styles from './userDeleteConfirmationModal.module.scss';

interface Props {
  title?: string;
  content?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  isVisible?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  handlePrimaryButtonClick?: () => void;
  handleSecondaryButtonClick?: () => void;
  loading?: boolean;
}

const UserDeleteConfirmationModal: React.FC<Props> = (props) => {
  const {
    title,
    content,
    primaryButtonText,
    secondaryButtonText,
    isVisible,
    setIsVisible,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    loading,
  } = props;

  return (
    <>
      <PopUpWrapper isVisible={isVisible} setIsVisible={setIsVisible}>
        <div className={styles.userDeleteConfirmationModal}>
          <p className={styles.deleteUser}>{title}</p>
          <p className={styles.confirmDeleteUser}>{content}</p>
          <div className={styles.buttons}>
            <Button disabled={loading} className={styles.button1} onClick={handleSecondaryButtonClick}>
              <p style={{ color: '#000000' }}>{primaryButtonText}</p>
            </Button>
            <Button loading={loading} className={styles.button2} onClick={handlePrimaryButtonClick}>
              {secondaryButtonText}
            </Button>
          </div>
        </div>
      </PopUpWrapper>
    </>
  );
};

UserDeleteConfirmationModal.defaultProps = {
  title: 'מחיקת משתמש',
  content: 'האם אתה בטוח שברצונך \nלמחוק משתמש?',
  primaryButtonText: 'ביטול',
  secondaryButtonText: 'מחק',
};

export default UserDeleteConfirmationModal;
